import { getSaleInstance } from './apiService';

const PaymentServices = {
  get: ({ sort }) => {
    return getSaleInstance().get(
      `payment-method?sortBy=${sort ?? 'createdAt:DESC'}`,
    );
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`payment-method/${id}`, data);
  },
};

export default PaymentServices;
