import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { RouteStrings } from 'constants/constants';
import DashboardLayout from 'components/features/Dashboard';
import LandingPage from 'components/features/LandingPage/LandingPage';
import LoginPage from 'components/features/LoginPage';
import ForgetPasswordPage from '../components/features/ForgetPasswordPage';
import PasswordRenewalPage from '../components/features/PasswordRenewalPage';
import TenantPage from '../components/features/TenantPage/TenantPage';
import RegisterPage from '../components/features/RegisterPage';

const routes = [
  {
    path: RouteStrings.home,
    exact: true,
    component: () => <LandingPage />,
  },
  {
    path: RouteStrings.login,
    component: () => <LoginPage />,
  },
  {
    path: RouteStrings.registerForDev,
    component: () => <RegisterPage />,
  },
  {
    path: RouteStrings.forget_password,
    component: () => <ForgetPasswordPage />,
  },
  {
    path: RouteStrings.password_renewal,
    component: () => <PasswordRenewalPage />,
  },
  {
    path: RouteStrings.tenant,
    component: () => <TenantPage />,
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: RouteStrings.setting_general,
        exact: true,
        component: lazy(() => import('components/features/GeneralSettings')),
      },
      {
        path: RouteStrings.setting_configuration,
        exact: true,
        component: lazy(() => import('components/features/ConfigurationSettings')),
      },
      {
        path: RouteStrings.goods_brands,
        exact: true,
        component: lazy(() => import('components/features/BrandList/BrandList')),
      },

      {
        path: RouteStrings.goods_categories,
        exact: true,
        component: lazy(() => import('components/features/CategoryList/CategoryList')),
      },
      {
        path: RouteStrings.goods_properties,
        exact: true,
        component: lazy(() => import('components/features/PropertyList')),
      },
      {
        path: RouteStrings.goods_products,
        exact: true,
        component: lazy(() => import('components/features/ProductList/ProductList')),
      },
      {
        path: RouteStrings.warehouse_lots,
        exact: true,
        component: lazy(() => import('components/features/LotList')),
      },
      {
        path: RouteStrings.customer_classifications,
        exact: true,
        component: lazy(() =>
          import('components/features/CustomerClassificationList/CustomerClassificationList'),
        ),
      },
      {
        path: RouteStrings.store_branches,
        exact: true,
        component: lazy(() => import('components/features/BranchList')),
      },
      {
        path: RouteStrings.customer_accumulation_points,
        exact: true,
        component: lazy(() => import('components/features/AccumulationPointList')),
      },
      {
        path: RouteStrings.store_sub_users,
        exact: true,
        component: lazy(() => import('components/features/SubUserList')),
      },
      {
        path: RouteStrings.sale_promotions,
        exact: true,
        component: lazy(() => import('components/features/PromotionList')),
      },
      {
        path: RouteStrings.setting_payment_methods,
        exact: true,
        component: lazy(() => import('components/features/PaymentList')),
      },
      {
        path: RouteStrings.customer_customers,
        exact: true,
        component: lazy(() => import('components/features/CustomerList')),
      },
      {
        path: RouteStrings.goods_suppliers,
        exact: true,
        component: lazy(() => import('components/features/SupplierList')),
      },
      {
        path: RouteStrings.partner_partners,
        exact: true,
        component: lazy(() => import('components/features/PartnerList')),
      },
      {
        path: RouteStrings.warehouse_warehouses,
        exact: true,
        component: lazy(() => import('components/features/WarehouseList')),
      },
      {
        path: RouteStrings.partner_categories,
        exact: true,
        component: lazy(() => import('components/features/PartnerCategoryList')),
      },
      {
        path: RouteStrings.customer_sources,
        exact: true,
        component: lazy(() => import('components/features/CustomerSourceList')),
      },
    ],
  },
];

export const unAuthRoutes = [
  routes[0],
  {
    component: () => <Redirect to="/" />,
  },
];

export default routes;
