import reduxResources from './reduxResources';
import RouteStrings from './routeConstants';
import configConstants from './configConstants';
import timezones from './timezones.json';

const alertTypes = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

const pageDefault = 0;
const pageSizeDefault = 1000;

const sizeToGetAll = 2147483647;

const mceToolbar =
  // eslint-disable-next-line no-multi-str
  'bold italic forecolor backcolor | superscript subscript | formatselect | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help';

const mcePlugins = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount hr',
];

const accumulationPointTypes = {
  AMOUNT_OF_MONEY_TYPE: 'AMOUNT_OF_MONEY_TYPE',
  PERCENT_TYPE: 'PERCENT_TYPE',
  QUANTITY_TYPE: 'QUANTITY_TYPE',
};

const generalStatus = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

const updateType = {
  NEW: 'NEW',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const themeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
};

const genders = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

const workingStatuses = {
  IS_WORKING: 'IS_WORKING',
  IS_NOT_WORKING: 'IS_NOT_WORKING',
};

const customerType = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTERPRISE: 'ENTERPRISE',
};

const subUserAccountTypes = {
  STAFF: 'STAFF',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  MERCHANT_ADMIN: 'MERCHANT_ADMIN',
  MANAGER_OF_MULTIPLE_BRANCHES: 'MANAGER_OF_MULTIPLE_BRANCHES',
  MANAGER_OF_ALL_BRANCHES: 'MANAGER_OF_ALL_BRANCHES',
  CUSTOMER: 'CUSTOMER',
};

const promotionTypes = {
  COUPON: 'COUPON',
  DISCOUNT: 'DISCOUNT',
};

const promotionConditionTypes = {
  OR: 'OR',
  AND: 'AND',
};

const promotionValueTypes = {
  PRODUCT: 'PRODUCT',
  AMOUNT_OF_MONEY: 'AMOUNT_OF_MONEY',
  PERCENT: 'PERCENT',
};

const promotionMinValueTypes = {
  MONEY: 'MONEY',
  PRODUCT: 'QUANTITY',
};

const accountLoginTypes = {
  MERCHANT_ROOT: 'MERCHANT_ROOT',
};

const propertyDomainTypes = {
  SELECT: 'SELECT',
  INPUT: 'INPUT',
};

const priceUnitType = {
  QUANTITY: 'QUANTITY',
};

const priceCurrency = {
  VND: 'VND',
};

const productStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const partnerTypes = {
  SUPPLIER: 'SUPPLIER',
  CUSTOMER: 'CUSTOMER',
  OTHER: 'OTHER',
};

const priceValueTypes = {
  VALUE: 'VALUE',
  PERCENT: 'PERCENT',
};

const warehouseTypes = {
  SALE: 'SALE',
  WARRANTY: 'WARRANTY',
  ERROR: 'ERROR',
  ASSET_OR_EQUIPMENT: 'ASSET_OR_EQUIPMENT',
  OTHER: 'OTHER',
};

const priceStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const priceTypes = {
  RETAIL: 'RETAIL',
  ORIGINAL: 'ORIGINAL',
  SALE: 'SALE',
};

const mapPriceName = {
  RETAIL: 'retailPrice',
  ORIGINAL: 'originalPrice',
  SALE: 'salePrice',
};

const filterFormTypes = {
  SELECT: 'SELECT',
  INPUT: 'INPUT',
};

const filterValueTypes = {
  ARRAY: 'ARRAY',
  OBJECT: 'OBJECT',
};

const partnerRelationships = {
  NEW: 'NEW',
  EXCHANGED: 'EXCHANGED',
  STRATEGIC_PARTNER: 'STRATEGIC_PARTNER',
  IMPORTANT_STRATEGIC_PARTNER: 'IMPORTANT_STRATEGIC_PARTNER',
  STOP_CONTACT: 'STOP_CONTACT',
};

const partnerCategoryTypes = {
  CUSTOMER: 'CUSTOMER',
  DELIVERY: 'DELIVERY',
  SUPPLIER: 'SUPPLIER',
  WARRANTY: 'WARRANTY',
  MAINTENANCE: 'MAINTENANCE',
  OTHERS: 'OTHERS',
  COOPERATIVE: 'COOPERATIVE',
  FRANCHISE: 'FRANCHISE',
  DISTRIBUTION: 'DISTRIBUTION',
  PRODUCTION: 'PRODUCTION',
  MARKETING: 'MARKETING',
  ADVERTISEMENT: 'ADVERTISEMENT',
  MEDIA: 'MEDIA',
  FINANCE_BANK: 'FINANCE_BANK',
  INVESTMENT: 'INVESTMENT',
  SELLER: 'SELLER',
};

const tinyCloudKey = 'tq31vr38vq4rrr2vp23s48vqcguehqefi85vws7kkwlt4yge';

const defaultRouteInManagePage = '/statistics/sale';

const merchantRootFirstPage = RouteStrings.setting_general;

const useSsl = process.env.REACT_APP_USE_SSL && process.env.REACT_APP_USE_SSL === 'true';

const domainTypes = {
  WARRANTY_LOCKUP: 'WARRANTY_LOCKUP',
  ECOMMERCE_WEBSITE: 'ECOMMERCE_WEBSITE',
};

export {
  alertTypes,
  reduxResources,
  pageDefault,
  pageSizeDefault,
  RouteStrings,
  configConstants,
  mceToolbar,
  mcePlugins,
  sizeToGetAll,
  accumulationPointTypes,
  generalStatus,
  updateType,
  themeTypes,
  genders,
  workingStatuses,
  subUserAccountTypes,
  promotionTypes,
  promotionConditionTypes,
  promotionValueTypes,
  promotionMinValueTypes,
  accountLoginTypes,
  propertyDomainTypes,
  priceUnitType,
  priceCurrency,
  productStatus,
  customerType,
  partnerTypes,
  priceValueTypes,
  warehouseTypes,
  priceStatus,
  mapPriceName,
  priceTypes,
  timezones,
  filterFormTypes,
  filterValueTypes,
  partnerRelationships,
  partnerCategoryTypes,
  tinyCloudKey,
  defaultRouteInManagePage,
  merchantRootFirstPage,
  useSsl,
  domainTypes,
};
