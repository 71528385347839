import { getSaleInstance } from './apiService';

const BrandServices = {
  get: ({ page, size, sort }) => {
    return getSaleInstance().get(
      `brand?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ??
        10}`,
    );
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`brand/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('brand', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(`brand/bulk?ids=${ids.join(',')}`);
  },
};

export default BrandServices;
