import React, { Suspense, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import makeStyles from '@material-ui/styles/makeStyles';
import { darken } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMerchantDetail } from 'hooks/merchantHooks';
import FaviconWrapper from 'components/common/Page/FaviconWrapper';
import { NavBar, TopBar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 200,
    position: 'relative',
  },
  container: {
    display: 'flex',
    width: '100%',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 105,
    width: ({ miniNavBar }) => (miniNavBar ? 72 : 256),
    minWidth: ({ miniNavBar }) => (miniNavBar ? 72 : 256),
    flex: '0 0 auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    position: 'relative',
    backgroundColor: darken(theme.palette.background.default, 0.02),
  },
  containerTopBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: ({ miniNavBar }) => `calc(100% - ${miniNavBar ? 72 : 256}px)`,
    },
  },
}));

const Dashboard = ({ route }) => {
  const [miniNavBar, setMiniNavBar] = useState(false);

  const classes = useStyles({ miniNavBar });
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const merchantDetail = useMerchantDetail();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
    setMiniNavBar(false);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const handleToggleMiniNavBar = () => {
    setMiniNavBar(!miniNavBar);
  };

  const handleEnlargerNavBar = () => {
    setMiniNavBar(false);
  };

  const hasToken = localStorage.getItem('ACCESS_TOKEN');

  if (!hasToken) {
    return <Redirect to="/" />;
  }

  return (
    <FaviconWrapper className={classes.root} favicon={merchantDetail?.favicon}>
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
          miniNavBar={miniNavBar}
          onEnlargerNavBar={handleEnlargerNavBar}
        />
        <div className={classes.containerTopBar}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
            onToggleMiniNavBar={handleToggleMiniNavBar}
            miniNavBar={miniNavBar}
          />
          <main className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </main>
        </div>
      </div>
    </FaviconWrapper>
  );
};

export default Dashboard;
