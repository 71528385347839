/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import * as colors from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { authActions } from 'store/ducks/AuthDuck';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuRounded';
import CloseIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import OpenIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import LanguageSelector from 'components/common/LanguageSelector';
import Spacing from 'components/common/Spacing';
import AccountAvatarPopover from 'components/common/AccountAvatarPopover';

import { defaultRouteInManagePage, RouteStrings, themeTypes } from 'constants/constants';
import logo from 'assets/images/logo.png';
import ThemeSwitcher from 'components/common/ThemeSwitcher';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { mapBreadcrumbs } from '../../../../../utils/uiUtils';
import { merchantSelectors } from '../../../../../store/ducks/MerchantDuck';
import defaultUrl from '../../../../../constants/defaultUrl';
import { useSsl } from '../../../../../constants/constants';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OpenInNew from '@material-ui/icons/OpenInNew';
import DarkIcon from '@material-ui/icons/Brightness7Rounded';

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, 0.08) !important',
  },
  toolbarRoot: {
    paddingLeft: '0 !important',
  },
  logo: {
    height: 32,
    width: 32,
  },
  flexGrow: {
    flexGrow: 1,
  },
  breadcrumbsOl: {
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
  },
}));

const TopBar = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { onOpenNavBarMobile, miniNavBar, onToggleMiniNavBar, className, ...rest } = props;
  const merchantDetail = useSelector(merchantSelectors.getMerchantDetail);

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const handleGoToManagePage = () => {
    if (!merchantDetail) {
      return;
    }
    const { domain, subDomain, id } = merchantDetail;
    const protocol = useSsl ? 'https://' : 'http://';

    if (process.env.NODE_ENV === 'development') {
      window.open(
        `http://localhost:3000/u/${subDomain}${defaultRouteInManagePage}?merchantId=${id}`,
      );
    } else {
      window.open(
        `${protocol}${defaultUrl}/u/${subDomain}${defaultRouteInManagePage}?merchantId=${id}`,
      );
      // window.open(`${protocol}${subDomain}.${defaultUrl}${defaultRouteInManagePage}`);
    }

    // if (domain) {
    //   if (domain.indexOf('http') >= 0) {
    //     window.open(domain + defaultRouteInManagePage);
    //   } else {
    //     window.open(`${protocol}${domain}${defaultRouteInManagePage}`);
    //   }
    // } else if (subDomain) {
    //   if (process.env.NODE_ENV === 'development') {
    //     window.open(`http://localhost:3000/u/${subDomain}${defaultRouteInManagePage}`);
    //   } else {
    //     window.open(`${protocol}${defaultUrl}/u/${subDomain}${defaultRouteInManagePage}`);
    //     // window.open(`${protocol}${subDomain}.${defaultUrl}${defaultRouteInManagePage}`);
    //   }
    // }
  };

  const { pathname } = useLocation();
  const breadcrumbs = mapBreadcrumbs(pathname);

  return (
    <AppBar
      {...rest}
      position="fixed"
      elevation={0}
      color="inherit"
      className={clsx(classes.root, className)}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <Hidden mdDown>
          <Spacing horizontal={1} />
          <IconButton color="inherit" onClick={onToggleMiniNavBar}>
            <MenuIcon />
          </IconButton>
          <Spacing horizontal={1} />
          {breadcrumbs && (
            <Breadcrumbs aria-label="breadcrumb" classes={{ ol: classes.breadcrumbsOl }}>
              {breadcrumbs.map((breadcrumb, index) => {
                if (index === breadcrumbs.length - 1) {
                  return (
                    <Typography color="textPrimary">
                      <strong>{t(breadcrumb)}</strong>
                    </Typography>
                  );
                }
                return <Typography color="textPrimary">{t(breadcrumb)}</Typography>;
              })}
            </Breadcrumbs>
          )}
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        {/* <Hidden mdUp>
          <RouterLink to={RouteStrings.dashboard}>
            <img src={logo} alt="logo" style={{ height: 32, width: 38 }} />
          </RouterLink>
        </Hidden> */}
        <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" width>
          <Spacing horizontal={2} />
          <div style={{ flexGrow: 1 }} />
          <Spacing horizontal={1} />
          <Hidden smUp>
            <IconButton onClick={handleGoToManagePage} color="primary">
              <OpenInNew />
            </IconButton>
          </Hidden>
          <Hidden xsDown>
            <Button color="primary" variant="contained" onClick={handleGoToManagePage}>
              {t('GO_TO_MANAGE_PAGE')}
            </Button>
          </Hidden>
          <Spacing horizontal={1} />
          <Hidden smDown>
            <LanguageSelector
              color={theme.palette.getContrastText(theme.palette.background.paper)}
            />
          </Hidden>
          <Spacing horizontal={1} />
          <ThemeSwitcher />
          <Spacing horizontal={1} />
          <AccountAvatarPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
