import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import BranchServices from 'services/BranchServices';
import { branchActions } from 'store/ducks/BranchDuck';

export function* fetchBranchListSaga({ payload }) {
  yield put(branchActions.setLoading(true));
  try {
    const res = yield call(BranchServices.get, payload);
    yield put(branchActions.setBranchList(res));
  } catch (error) {
    yield put(branchActions.setError(error));
  }
  yield put(branchActions.setLoading(false));
}

export function* fetchBranchDetailSaga({ payload }) {
  try {
    const res = yield call(BranchServices.getOne, payload);
    yield put(branchActions.setBranchDetail(res));
  } catch (error) {
    yield put(branchActions.setError(error));
  }
}

export function* createBranchSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(BranchServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_BRANCH',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(branchActions.fetchBranchList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_BRANCH',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateBranchSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(BranchServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_BRANCH',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(branchActions.fetchBranchList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_BRANCH',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteBranchListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(BranchServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_BRANCH',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(branchActions.fetchBranchList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_BRANCH',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchBranchAsync() {
  yield takeLatest(branchActions.fetchBranchList, fetchBranchListSaga);
  yield takeLatest(branchActions.fetchBranchDetail, fetchBranchDetailSaga);
  yield takeLatest(branchActions.createBranch, createBranchSaga);
  yield takeLatest(branchActions.updateBranch, updateBranchSaga);
  yield takeLatest(branchActions.deleteBranchList, deleteBranchListSaga);
}
