import { getMerchantInstance } from './apiService';

const partnerCategoryType = 'PARTNER';

const PartnerCategoryServices = {
  get: ({ page, size, sort } = {}) => {
    return getMerchantInstance().get(
      `category?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ??
        10}&type=${partnerCategoryType}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getMerchantInstance().get(`category/${id}`);
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`category/${id}`, { ...data, type: partnerCategoryType });
  },
  create: payload => {
    return getMerchantInstance().post('category', { ...payload, type: partnerCategoryType });
  },
  delete: ids => {
    return getMerchantInstance().delete(`category/bulk?ids=${ids.join(',')}`);
  },
};

export default PartnerCategoryServices;
