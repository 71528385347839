import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './en';
import translationVi from './vi';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEn,
  },
  vi: {
    translation: translationVi,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'vi',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

export default i18n;
