import { put, call, takeLatest } from 'redux-saga/effects';
import { resourceFolderActions } from 'store/ducks/ResourceFolderDuck';
import { alertTypes } from 'constants/constants';
import { alertActions } from 'store/ducks/AlertDuck';
import ResourceFolderServices from 'services/ResourceFolderServices';
import actionUtils from 'utils/actionUtils';

export function* fetchResourceFolderListSaga({ payload }) {
  try {
    const res = yield call(ResourceFolderServices.get, payload);
    yield put(resourceFolderActions.setResourceFolderList(res));
  } catch (error) {
    yield put(
      alertActions.show({
        translateId: error.message,
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createResourceFolderSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(ResourceFolderServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_RESOURCE_FOLDER' }));
    yield put(resourceFolderActions.fetchResourceFolderList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'ERRORS.CAN_NOT_CREATE_RESOURCE_FOLDER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateResourceFolderSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(ResourceFolderServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_RESOURCE_FOLDER' }));
    yield put(resourceFolderActions.fetchResourceFolderList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_RESOURCE_FOLDER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteResourceFoldersSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(ResourceFolderServices.deleteList, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_RESOURCE_FOLDER' }));
    yield put(resourceFolderActions.fetchResourceFolderList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_RESOURCE_FOLDER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchResourceFolderAsync() {
  yield takeLatest(resourceFolderActions.fetchResourceFolderList, fetchResourceFolderListSaga);

  yield takeLatest(resourceFolderActions.createResourceFolder, createResourceFolderSaga);

  yield takeLatest(resourceFolderActions.updateResourceFolder, updateResourceFolderSaga);

  yield takeLatest(resourceFolderActions.deleteResourceFolderList, deleteResourceFoldersSaga);
}
