import Moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import PromotionServices from 'services/PromotionServices';
import { promotionActions } from 'store/ducks/PromotionDuck';

export function* fetchPromotionListSaga({ payload }) {
  yield put(promotionActions.setLoading(true));
  try {
    const res = yield call(PromotionServices.get, payload);
    yield put(promotionActions.setPromotionList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(promotionActions.setLoading(false));
}

export function* fetchPromotionDetailSaga({ payload }) {
  try {
    const res = yield call(PromotionServices.getOne, payload);

    const {
      brandsApplied,
      categoriesApplied,
      productsApplied,
      variationsApplied,
      giftBrands,
      giftCategories,
      giftProducts,
      giftVariations,
      startDate,
      endDate,
      ...rest
    } = res;
    yield put(
      promotionActions.setPromotionDetail({
        ...rest,
        startDate: startDate ? new Moment(startDate) : null,
        endDate: endDate ? new Moment(endDate) : null,
        brandsApplied:
          brandsApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        categoriesApplied:
          categoriesApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        productsApplied:
          productsApplied?.map(({ id, name, images }) => ({
            id,
            name,
            images,
          })) ?? [],
        variationsApplied,
        giftBrands:
          giftBrands?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftCategories:
          giftCategories?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftProducts:
          giftProducts?.map(({ id, name, images }) => ({
            id,
            name,
            images,
          })) ?? [],
        giftVariations,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createPromotionSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PromotionServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_PROMOTION',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(promotionActions.fetchPromotionList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_PROMOTION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updatePromotionSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PromotionServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_PROMOTION',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(promotionActions.fetchPromotionList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PROMOTION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deletePromotionListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(PromotionServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_PROMOTION',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(promotionActions.fetchPromotionList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_PROMOTION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPromotionAsync() {
  yield takeLatest(promotionActions.fetchPromotionList, fetchPromotionListSaga);

  yield takeLatest(promotionActions.fetchPromotionDetail, fetchPromotionDetailSaga);

  yield takeLatest(promotionActions.createPromotion, createPromotionSaga);
  yield takeLatest(promotionActions.updatePromotion, updatePromotionSaga);
  yield takeLatest(promotionActions.deletePromotionList, deletePromotionListSaga);
}
