import { getMerchantInstance } from './apiService';
import { partnerCategoryTypes } from '../constants/constants';

const PartnerCustomerServices = {
  get: ({ page, size, sort }) => {
    return getMerchantInstance().get(
      `partner?partnerCategoryType=${partnerCategoryTypes.CUSTOMER}&sortBy=${sort ??
        'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}`,
    );
  },
};

export default PartnerCustomerServices;
