import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  filter: {},
  isLoading: true,
  hasError: false,
  total: 0,
};

const actions = {
  setCategoryList: createAction('category/set-category'),
  setLoading: createAction('category/set-loading'),
  setError: createAction('category/set-error'),
  fetchCategoryList: createAction('category/fetch-category-list'),
  updateCategory: createAction('category/update-category'),
  createCategory: createAction('category/create-category'),
  deleteCategoryList: createAction('category/delete-category-list'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setCategoryList]: (state, action) => {
    const { data, total } = action.payload;

    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.CATEGORY],
    state => state,
  ),
};

// all actions

export {
  reducer as categoryReducer,
  actions as categoryActions,
  selectors as categorySelectors,
};
