import { getMerchantInstance } from './apiService';

const PartnerServices = {
  get: ({ page, size, sort }) => {
    return getMerchantInstance().get(
      `partner?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}`,
    );
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`partner/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('partner', payload);
  },
  delete: ids => {
    return getMerchantInstance().delete(`partner/bulk?ids=${ids.join(',')}`);
  },
};

export default PartnerServices;
