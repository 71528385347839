import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  customerClassifications: null,
  page: 0,
  size: 10,
  filter: {},
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
};

const actions = {
  setCustomerClassifications: createAction(
    'customer-classification/set-customer-classification',
  ),
  setLoading: createAction('customer-classification/set-loading'),
  setError: createAction('customer-classification/set-error'),
  setPageSize: createAction('customer-classification/set-page-size'),
  setPage: createAction('customer-classification/set-page'),
  setSort: createAction('customer-classification/set-sort'),
  fetchCustomerClassificationList: createAction(
    'customer-classification/fetch-customer-classification-list',
  ),
  updateCustomerClassification: createAction(
    'customer-classification/update-customer-classification',
  ),
  createCustomerClassification: createAction(
    'customer-classification/create-customer-classification',
  ),
  deleteCustomerClassifications: createAction(
    'customer-classification/delete-customer-classifications',
  ),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setCustomerClassifications]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.customerClassifications = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.CUSTOMER_CLASSIFICATION],
    state => state,
  ),
};

// all actions

export {
  reducer as customerClassificationReducer,
  actions as customerClassificationActions,
  selectors as customerClassificationSelectors,
};
