// import { mapObjectToFilterString } from 'utils/filterUtils';
import { getMerchantInstance } from './apiService';

export const CustomerSourceServices = {
  get: ({ page, size, sort, filter } = {}) => {
    return getMerchantInstance().get(
      `customer-source?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ??
        10}${filter ?? ''}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getMerchantInstance().get(`customer-source/${id}`);
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`customer-source/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('customer-source', payload);
  },
  delete: ids => {
    return getMerchantInstance().delete(`customer-source/bulk?ids=${ids.join(',')}`);
  },
};
