import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';
import MerchantServices from 'services/MerchantServices';
import { merchantActions } from 'store/ducks/MerchantDuck';
import actionUtils from 'utils/actionUtils';
import { themeActions } from 'store/ducks/ThemeDuck';
import { generateMaterialColor } from 'utils/buildTheme';
import * as colors from '@material-ui/core/colors';

function* updateMerchantSaga({ payload }) {
  const { callback, ...rest } = payload;

  yield put(merchantActions.setLoading(true));
  try {
    yield call(MerchantServices.updateMerchantInfo, rest);
    actionUtils.doCallback(callback);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_MERCHANT_INFO',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(merchantActions.fetchMerchantDetail());
  } catch ({ message }) {
    actionUtils.doCallback(callback);
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(merchantActions.setLoading(false));
}

function* fetchMerchantDetailSaga() {
  yield put(merchantActions.setLoading(true));
  try {
    const res = yield call(MerchantServices.getOne, {});
    yield put(merchantActions.setMerchantDetail(res));
    yield put(
      themeActions.setPrimaryColor(generateMaterialColor(res?.primaryColor ?? colors.blue[500])),
    );
    if (res.secondaryColor) {
      yield put(
        themeActions.setSecondaryColor(
          generateMaterialColor(res.secondaryColor ?? colors.blue[500]),
        ),
      );
    }
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(merchantActions.setLoading(false));
}

function* fetchPublicMerchantDetailSaga({ payload }) {
  yield put(merchantActions.setLoading(true));
  try {
    const res = yield call(MerchantServices.getPublicDetail, payload);
    yield put(merchantActions.setPublicMerchantDetail(res));
    yield put(themeActions.setPrimaryColor(generateMaterialColor(res.primaryColor ?? colors.blue)));
    if (res.secondaryColor) {
      yield put(themeActions.setSecondaryColor(generateMaterialColor(res.secondaryColor)));
    }
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    // yield put(push(RouteStrings.login));
  }
  yield put(merchantActions.setLoading(false));
}

export default function* watchMerchantAsync() {
  yield takeLatest(merchantActions.updateMerchant, updateMerchantSaga);
  yield takeLatest(merchantActions.fetchMerchantDetail, fetchMerchantDetailSaga);
  yield takeLatest(merchantActions.fetchMerchantPublicDetail, fetchPublicMerchantDetailSaga);
}
