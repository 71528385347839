import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import CategoryServices from 'services/CategoryServices';
import { categoryActions } from 'store/ducks/CategoryDuck';

export function* fetchCategoryListSaga() {
  yield put(categoryActions.setLoading(true));
  try {
    const res = yield call(CategoryServices.get, {});
    yield put(categoryActions.setCategoryList(res));
  } catch (error) {
    // console.log(error);
    yield put(categoryActions.setError(error));
  }
  yield put(categoryActions.setLoading(false));
}

export function* createCategorySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CategoryServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_CATEGORY' }));
    yield put(categoryActions.fetchCategoryList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_CREATE_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateCategorySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CategoryServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_CATEGORY' }));
    yield put(categoryActions.fetchCategoryList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteCategoryListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(CategoryServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_CATEGORY' }));
    yield put(categoryActions.fetchCategoryList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchCategoryAsync() {
  yield takeLatest(categoryActions.fetchCategoryList, fetchCategoryListSaga);
  yield takeLatest(categoryActions.createCategory, createCategorySaga);
  yield takeLatest(categoryActions.updateCategory, updateCategorySaga);
  yield takeLatest(categoryActions.deleteCategoryList, deleteCategoryListSaga);
}
