import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  WarehouseInfo: null,
  filter: null,
};

const actions = {
  setWarehouseList: createAction('warehouse/set-warehouse'),
  setLoading: createAction('warehouse/set-loading'),
  setError: createAction('warehouse/set-error'),
  setSort: createAction('warehouse/set-sort'),
  setPageSize: createAction('warehouse/set-page-size'),
  setFilter: createAction('warehouse/set-filter'),
  setPage: createAction('warehouse/set-page'),
  fetchWarehouseList: createAction('warehouse/fetch-warehouse-list'),
  updateWarehouse: createAction('warehouse/update-warehouse'),
  createWarehouse: createAction('warehouse/create-warehouse'),
  deleteWarehouseList: createAction('warehouse/delete-warehouse-list'),
  fetchWarehouseInfo: createAction('warehouse/fetch-warehouse-info'),
  fetchWarehouseDetail: createAction('warehouse/fetch-warehouse-detail'),
  setWarehouseInfo: createAction('warehouse/set-warehouse-info'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setWarehouseList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setWarehouseInfo]: (state, action) => {
    state.WarehouseInfo = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.WAREHOUSE],
    state => state,
  ),
  getWarehouseInfo: createSelector(
    state => state[reduxResources.WAREHOUSE],
    state => state.WarehouseInfo,
  ),
};

// all actions

export {
  reducer as warehouseReducer,
  actions as warehouseActions,
  selectors as warehouseSelectors,
};
