import { getSaleInstance } from './apiService';

const VariationServices = {
  get: ({ page, size, sort }) => {
    return getSaleInstance().get(
      `variation?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}`,
    );
  },
};

export default VariationServices;
