import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import AccumulationPointServices from 'services/AccumulationPointServices';
import { accumulationPointActions } from 'store/ducks/AccumulationPointDuck';

export function* fetchAccumulationPointListSaga({ payload }) {
  yield put(accumulationPointActions.setLoading(true));
  try {
    const res = yield call(AccumulationPointServices.get, payload);
    yield put(accumulationPointActions.setAccumulationPointList(res));
  } catch (error) {
    yield put(accumulationPointActions.setError(error));
  }
  yield put(accumulationPointActions.setLoading(false));
}

export function* createAccumulationPointSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(AccumulationPointServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_ACCUMULATION_POINT' }));
    yield put(accumulationPointActions.fetchAccumulationPointList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_CREATE_ACCUMULATION_POINT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateAccumulationPointSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(AccumulationPointServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_ACCUMULATION_POINT' }));
    yield put(accumulationPointActions.fetchAccumulationPointList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_ACCUMULATION_POINT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteAccumulationPointListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(AccumulationPointServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_ACCUMULATION_POINT' }));
    yield put(accumulationPointActions.fetchAccumulationPointList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_ACCUMULATION_POINT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchAccumulationPointAsync() {
  yield takeLatest(
    accumulationPointActions.fetchAccumulationPointList,
    fetchAccumulationPointListSaga,
  );
  yield takeLatest(accumulationPointActions.createAccumulationPoint, createAccumulationPointSaga);
  yield takeLatest(accumulationPointActions.updateAccumulationPoint, updateAccumulationPointSaga);
  yield takeLatest(
    accumulationPointActions.deleteAccumulationPointList,
    deleteAccumulationPointListSaga,
  );
}
