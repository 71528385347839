/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import * as colors from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import { getAllChildrenRoute } from '../../../../../utils/uiUtils';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  subItem: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    '&:before': {
      content: '- ',
    },
  },
  subSubItem: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.grey[200],
    // padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    padding: '12px 26px',
    borderRadius: '0 !important',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  buttonLeaf: {
    color: colors.grey[200],
    padding: '12px 26px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: '0 !important',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    background: 'rgba(0,0,0,0.3) !important',
    '& $icon': {
      color: theme.palette.secondary.main,
    },
    '& .item-prefix': {
      background: `${theme.palette.secondary.main} !important`,
    },
  },
}));

const SubItemPrefix = ({ style = {} }) => {
  return (
    <span
      style={{
        width: 5,
        height: 1,
        marginRight: 15,
        background: '#fff',
        ...style,
      }}
      className="item-prefix"
    />
  );
};

const SubSubItemPrefix = ({ style = {} }) => {
  return (
    <span
      style={{
        width: 4,
        height: 4,
        borderRadius: '100%',
        marginRight: 16,
        background: '#fff',
        ...style,
      }}
      className="item-prefix"
    />
  );
};

const NavigationListItem = props => {
  const {
    title,
    href,
    depth = 0,
    children,
    childrenRoute,
    icon: Icon,
    className,
    open: openProp = false,
    label: Label,
    miniNavBar,
    onEnlargerNavBar,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    if (miniNavBar) {
      onEnlargerNavBar();
      setOpen(true);
    } else {
      setOpen(open => !open);
    }
  };

  const withToolTip = component => {
    if (miniNavBar) {
      return <Tooltip title={t(title)}>{component}</Tooltip>;
    }
    return component;
  };

  let itemClassName = null;
  let itemPrefix = null;
  if (depth === 0) {
    itemClassName = classes.item;
  } else if (depth === 1) {
    itemClassName = classes.subItem;
    itemPrefix = <SubItemPrefix />;
  } else if (depth === 2) {
    itemClassName = classes.subSubItem;
    itemPrefix = <SubSubItemPrefix />;
  }

  const style = {
    paddingLeft: 26 + 15 * depth,
  };

  if (children) {
    const allChildrenRoute = getAllChildrenRoute(childrenRoute, []);
    return (
      <ListItem {...rest} className={clsx(itemClassName, className)} disableGutters>
        {withToolTip(
          <Button
            className={clsx(classes.button, {
              [classes.active]: pathname === href || allChildrenRoute.indexOf(pathname) >= 0,
            })}
            onClick={handleToggle}
            style={style}
          >
            {itemPrefix}
            {Icon && <Icon className={classes.icon} />}
            {!miniNavBar && t(title)}
            {!miniNavBar &&
              (open ? (
                <ExpandLessIcon className={classes.expandIcon} color="inherit" />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
              ))}
          </Button>,
        )}

        <Collapse in={open && !miniNavBar}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, itemClassName, className)} disableGutters>
      {withToolTip(
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`, {
            [classes.active]: pathname === href,
          })}
          component={CustomRouterLink}
          exact
          style={style}
          to={href}
        >
          {itemPrefix}
          {Icon && <Icon className={classes.icon} />}
          {!miniNavBar && t(title)}
          {!miniNavBar && Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>,
      )}
    </ListItem>
  );
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default NavigationListItem;
