import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  total: 0,
};

const actions = {
  setResourceFolderList: createAction('resource-folder/set-resource-folder'),
  fetchResourceFolderList: createAction('resource-folder/fetch-resource-folder-list'),
  deleteResourceFolderList: createAction('resource-folder/delete-partner-list'),
  createResourceFolder: createAction('resource-folder/create-resource-folder'),
  updateResourceFolder: createAction('resource-folder/update-resource-folder'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setResourceFolderList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.RESOURCE_FOLDER],
    state => state,
  ),
};

// all actions

export {
  reducer as resourceFolderReducer,
  actions as resourceFolderActions,
  selectors as resourceFolderSelectors,
};
