/* eslint-disable no-undef */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const FaviconWrapper = ({ favicon, children, ...rest }) => {
  return (
    <div {...rest}>
      <Helmet>
        {favicon && (
          <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
        )}
      </Helmet>
      {children}
    </div>
  );
};

FaviconWrapper.propTypes = {
  children: PropTypes.node,
  favicon: PropTypes.string,
};

export default FaviconWrapper;
