import { createMuiTheme, darken, getContrastRatio, lighten } from '@material-ui/core/styles';
import { dark, light } from '@material-ui/core/styles/createPalette';

const contrastThreshold = 3;

export const generateMaterialColor = color => {
  return {
    200: lighten(color, 0.4),
    400: lighten(color, 0.25),
    500: color,
    600: darken(color, 0.15),
  };
};

function getContrastText(background) {
  return getContrastRatio(background, dark.text.primary) >= contrastThreshold
    ? dark.text.primary
    : light.text.primary;
}

const buildPalette = (themeType, rootPrimaryColor, rootSecondaryColor) => {
  const primaryColor = themeType === 'dark' ? rootPrimaryColor[200] : rootPrimaryColor[500];
  let secondaryColor;
  if (rootSecondaryColor) {
    secondaryColor = themeType === 'dark' ? rootSecondaryColor[200] : rootSecondaryColor[500];
  } else {
    secondaryColor = themeType === 'dark' ? rootPrimaryColor[400] : rootPrimaryColor[600];
  }
  return {
    type: themeType,
    primary: {
      main: primaryColor, // '#24d39b' : '#1c45a4',
      contrastText: getContrastText(primaryColor),
    },
    secondary: {
      main: secondaryColor, // '#24d39b',
      contrastText: getContrastText(secondaryColor),
    },
    action: {
      hoverLighten: 0.8,
      activatedOpacity: 0.12,
    },
  };
};

export const buildTheme = ({ themeType, primaryColor, secondaryColor }) => {
  const tableBackgroundColor = themeType === 'dark' ? '#303030' : '#fff';
  return createMuiTheme({
    palette: buildPalette(themeType, primaryColor, secondaryColor),
    typography: {
      fontFamily: 'Nunito',
      h6: {
        fontSize: '1.1rem',
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
    transitions: {
      duration: {
        shortest: 50,
        shorter: 100,
        short: 150,
        standard: 200,
        complex: 305,
        enteringScreen: 155,
        leavingScreen: 185,
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          borderRadius: 0,
        },
      },
      MuiDrawer: {
        paper: {
          borderRadius: 0,
        },
      },
      MuiPaper: {
        root: {
          boxShadow: '0 8px 20px -13px rgba(0,0,0,0.3) !important',
          borderRadius: 8,
        },
        elevation0: {
          boxShadow: 'none !important',
        },
        elevation1: {
          boxShadow: '0 8px 20px -13px rgba(0,0,0,0.3) !important',
        },
      },
      MuiTableCell: {
        footer: {
          borderBottom: 'none',
        },
      },
      MUIDataTable: {
        paper: {
          // boxShadow: '0 8px 20px -13px rgba(0,0,0,0.25) !important',
          borderRadius: 8,
          minHeight: '100%',
          maxHeight: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        },
        responsiveScroll: {
          maxHeight: '100% !important',
          flexGrow: 1,
        },
        responsiveBase: {
          flexGrow: 1,
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor: tableBackgroundColor,
          borderRadius: 8,
          boxShadow: 'none !important',
          flex: 'unset',
        },
      },
      // MuiToolbar: {
      //   gutters: {
      //     paddingLeft: 16,
      //     paddingRight: 16,
      //   },
      // },
      MuiButton: {
        root: {
          borderRadius: 999,
          borderWidth: '1px !important',
          fontWeight: 700,
          // animationName: 'b',
          animationDuration: '0.5s',
          minWidth: 'unset',
          textTransform: 'none',
        },
        contained: {
          padding: '6px 16px',
          boxShadow: 'none',
        },
        outlined: {
          padding: '5px 14px',
          // backgroundColor: surfaceColorByTheme,
        },
        containedPrimary: {
          // color: theme.palette.primary.contrastText,
        },
        containedSecondary: {
          // color: theme.palette.secondary.contrastText,
        },
        outlinedPrimary: theme => ({
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, theme.palette.action.hoverLighten),
          },
        }),
        text: {
          boxShadow: 'none',
        },
      },
      MuiSwitch: {
        root: {
          marginRight: '5px',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginRight: 0,
        },
      },
    },
  });
};
