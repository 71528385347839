import React, { useMemo } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import Moment from 'moment';
//
import { themeSelectors } from 'store/ducks/ThemeDuck';
import { renderRoutes } from 'react-router-config';
import { buildTheme } from 'utils/buildTheme';
import AlertStack from 'components/common/AlertStack/AlertStack';
import CustomSnackbarProvider from 'components/common/CustomSnackbarProvider';
import ErrorBoundary from 'components/helpers/ErrorBoundary';
import routes from 'router/routes';

const App = () => {
  const { type: themeType, primaryColor, secondaryColor } = useSelector(themeSelectors.getTheme);

  const theme = useMemo(
    () =>
      buildTheme({
        themeType,
        primaryColor,
        secondaryColor,
      }),
    [themeType, primaryColor],
  );

  return (
    <LocalizationProvider dateLibInstance={Moment} dateAdapter={MomentUtils}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <CustomSnackbarProvider>
            <AlertStack />
            <CssBaseline />
            {renderRoutes(routes)}
          </CustomSnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
