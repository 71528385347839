import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  page: 0,
  size: 10,
  filter: {},
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
};

const actions = {
  setPrices: createAction('price/set-prices'),
  setLoading: createAction('price/set-loading'),
  setError: createAction('price/set-error'),
  setPageSize: createAction('price/set-page-size'),
  setPage: createAction('price/set-page'),
  setSort: createAction('price/set-sort'),
  setFilter: createAction('price/set-filter'),
  resetState: createAction('price/reset-state'),
  fetchPriceList: createAction('price/fetch-list'),
  updatePrices: createAction('price/update-list'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPrices]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.data = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.resetState]: () => {
    console.log('reset state');
    return { ...initialState };
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PRICE],
    state => state,
  ),
};

// all actions

export { reducer as priceReducer, actions as priceActions, selectors as priceSelectors };
