import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  variationDetail: null,
};

const actions = {
  setVariationList: createAction('variation/set-variation'),
  setLoading: createAction('variation/set-loading'),
  setError: createAction('variation/set-error'),
  setSort: createAction('variation/set-sort'),
  setPageSize: createAction('variation/set-page-size'),
  setPage: createAction('variation/set-page'),
  fetchVariationList: createAction('variation/fetch-variation-list'),
  fetchVariationDetail: createAction('variation/fetch-variation-detail'),
  setVariationDetail: createAction('variation/set-variation-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setVariationList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setVariationDetail]: (state, action) => {
    state.variationDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.VARIATION],
    state => state,
  ),
  getVariationDetail: createSelector(
    state => state[reduxResources.VARIATION],
    state => state.variationDetail,
  ),
};

// all actions

export {
  reducer as variationReducer,
  actions as variationActions,
  selectors as variationSelectors,
};
