const GATEWAY_BASE_URL =
  process.env.REACT_APP_GATEWAY_BASE_URL && process.env.REACT_APP_GATEWAY_BASE_URL !== 'false'
    ? process.env.REACT_APP_GATEWAY_BASE_URL
    : null;

export default {
  SALE_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/sale`
    : process.env.REACT_APP_SALE_SERVICE_URL,
  MERCHANT_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/merchant`
    : process.env.REACT_APP_MERCHANT_SERVICE_URL,
  COMMON_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/common`
    : process.env.REACT_APP_COMMON_SERVICE_URL,
  ACCOUNT_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/account`
    : process.env.REACT_APP_ACCOUNT_SERVICE_URL,
  WAREHOUSE_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/warehouse`
    : process.env.REACT_APP_WAREHOUSE_SERVICE_URL,
  APP_SERVICE_URL: GATEWAY_BASE_URL
    ? `${GATEWAY_BASE_URL}/app`
    : process.env.REACT_APP_APP_SERVICE_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};
