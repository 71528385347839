import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import { WarehouseServices, WarehouseDetailService } from 'services/WarehouseServices';
import { warehouseActions } from 'store/ducks/WarehouseDuck';
import { warehouseDetailActions } from 'store/ducks/WarehouseDetailDuck';

export function* fetchWarehouseListSaga({ payload }) {
  try {
    const res = yield call(WarehouseServices.get, payload);
    yield put(warehouseActions.setWarehouseList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    yield put(warehouseActions.setLoading(false));
  }
}

export function* fetchWarehouseInfoSaga({ payload }) {
  try {
    const res = yield call(WarehouseServices.getOne, payload);

    const { ...rest } = res;
    yield put(
      warehouseActions.setWarehouseInfo({
        ...rest,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* fetchWarehouseDetailListSaga({ payload }) {
  try {
    const res = yield call(WarehouseDetailService.get, payload);

    yield put(warehouseDetailActions.setWarehouseDetailList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createWarehouseSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(WarehouseServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_WAREHOUSE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(warehouseActions.fetchWarehouseList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_WAREHOUSE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateWarehouseSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(WarehouseServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_WAREHOUSE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(warehouseActions.fetchWarehouseList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_WAREHOUSE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteWarehouseListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(WarehouseServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_WAREHOUSE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(warehouseActions.fetchWarehouseList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_WAREHOUSE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchWarehouseAsync() {
  yield takeLatest(warehouseActions.fetchWarehouseList, fetchWarehouseListSaga);

  yield takeLatest(warehouseActions.fetchWarehouseInfo, fetchWarehouseInfoSaga);
  yield takeLatest(warehouseDetailActions.fetchWarehouseDetailList, fetchWarehouseDetailListSaga);
  yield takeLatest(warehouseActions.createWarehouse, createWarehouseSaga);
  yield takeLatest(warehouseActions.updateWarehouse, updateWarehouseSaga);
  yield takeLatest(warehouseActions.deleteWarehouseList, deleteWarehouseListSaga);
}
