import { getSaleInstance } from './apiService';

const AccumulationPointServices = {
  get: ({ page, size, sort }) => {
    return getSaleInstance().get(
      `accumulation-point?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}`,
    );
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`accumulation-point/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('accumulation-point', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(
      `accumulation-point/bulk?ids=${ids.join(',')}`,
    );
  },
};

export default AccumulationPointServices;
