import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import viIcon from 'assets/images/vi-icon.png';
import enIcon from 'assets/images/en-icon.png';

const flags = {
  vi: viIcon,
  en: enIcon,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    // marginLeft: theme.spacing(4),
    minWidth: 72,
    color: props => props.textColor,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectContainer: {
    color: props => props.textColor,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px 10px 10px',
    borderRadius: '4px !important',
  },
  icon: {
    color: props => props.textColor,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
}));

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    border: 'none',
    fontSize: 16,
  },
}))(InputBase);

const LanguageSelector = ({ color = '#fff' }) => {
  const classes = useStyles({ textColor: color });

  const { i18n, t } = useTranslation();

  const currentLanguage = i18n.language;
  const options = ['vi', 'en'];

  const handleChange = event => {
    i18n.changeLanguage(event.target.value);
  };

  const currentOption = options.find(l => l === currentLanguage || currentLanguage.startsWith(l));

  return (
    <FormControl className={classes.formControl} style={{ color: color ?? '#fff' }}>
      <Select
        labelId="language-selector"
        id="id-language-selector"
        value={currentOption}
        onChange={handleChange}
        input={<CustomInput />}
        IconComponent={ArrowIcon}
        classes={{ root: classes.selectContainer, icon: classes.icon }}
      >
        {options.map(language => (
          <MenuItem value={language} key={language} className={classes.menuContainer}>
            <img src={flags[language]} alt={language} style={{ marginRight: 10, width: 24 }} />
            <span style={{ fontSize: 14 }}> {t(language.toUpperCase())}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
