import { mapObjectToFilterString } from 'utils/filterUtils';
import { getSaleInstance } from './apiService';

const LotServices = {
  get: ({ page, size, sort, filter } = {}) => {
    const filterString = mapObjectToFilterString(filter);
    return getSaleInstance().get(
      `lot?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}${filterString ??
        ''}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getSaleInstance().get(`lot/${id}`);
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`lot/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('lot', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(`lot/bulk?ids=${ids.join(',')}`);
  },
};

export default LotServices;
