import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reduxResources } from 'constants/constants';
import { alertReducer } from './ducks/AlertDuck';
import { brandReducer } from './ducks/BrandDuck';
import { productReducer } from './ducks/ProductDuck';
import { categoryReducer } from './ducks/CategoryDuck';
import { customerClassificationReducer } from './ducks/CustomerClassificationDuck';
import { branchReducer } from './ducks/BranchDuck';
import { accumulationPointReducer } from './ducks/AccumulationPointDuck';
import { locationReducer } from './ducks/LocationDuck';
import { themeReducer } from './ducks/ThemeDuck';
import { subUserReducer } from './ducks/SubUserDuck';
import { promotionReducer } from './ducks/PromotionDuck';
import { paymentReducer } from './ducks/PaymentDuck';
import { authReducer } from './ducks/AuthDuck';
import { propertyReducer } from './ducks/PropertyDuck';
import { variationReducer } from './ducks/VariationDuck';
import { customerReducer } from './ducks/CustomerDuck';
import { supplierReducer } from './ducks/SupplierDuck';
import { partnerReducer } from './ducks/PartnerDuck';
import { merchantReducer } from './ducks/MerchantDuck';
import { resourceFolderReducer } from './ducks/ResourceFolderDuck';
import { lotReducer } from './ducks/LotDuck';
import { priceReducer } from './ducks/PriceDuck';
import { warehouseReducer } from './ducks/WarehouseDuck';
import { warehouseDetailReducer } from './ducks/WarehouseDetailDuck';
import { partnerCategoryReducer } from './ducks/PartnerCategoryDuck';
import { partnerCustomerReducer } from './ducks/PartnerCustomerDuck';
import { customerSourceReducer } from './ducks/CustomerSourceDuck';
import { customerAddressReducer } from './ducks/CustomerAddressDuck';

const staticReducer = {
  [reduxResources.AUTH]: authReducer,
  [reduxResources.ALERT]: alertReducer,
  [reduxResources.BRAND]: brandReducer,
  [reduxResources.PRODUCT]: productReducer,
  [reduxResources.BRANCH]: branchReducer,
  [reduxResources.CATEGORY]: categoryReducer,
  [reduxResources.CUSTOMER_CLASSIFICATION]: customerClassificationReducer,
  [reduxResources.ACCUMULATION_POINT]: accumulationPointReducer,
  [reduxResources.LOCATION]: locationReducer,
  [reduxResources.THEME]: themeReducer,
  [reduxResources.SUB_USER]: subUserReducer,
  [reduxResources.PROMOTION]: promotionReducer,
  [reduxResources.PROPERTY]: propertyReducer,
  [reduxResources.PAYMENT]: paymentReducer,
  [reduxResources.VARIATION]: variationReducer,
  [reduxResources.CUSTOMER]: customerReducer,
  [reduxResources.SUPPLIER]: supplierReducer,
  [reduxResources.PARTNER]: partnerReducer,
  [reduxResources.MERCHANT]: merchantReducer,
  [reduxResources.RESOURCE_FOLDER]: resourceFolderReducer,
  [reduxResources.LOT]: lotReducer,
  [reduxResources.PRICE]: priceReducer,
  [reduxResources.WAREHOUSE]: warehouseReducer,
  [reduxResources.WAREHOUSE_DETAIL]: warehouseDetailReducer,
  [reduxResources.PARTNER_CATEGORY]: partnerCategoryReducer,
  [reduxResources.PARTNER_CUSTOMER]: partnerCustomerReducer,
  [reduxResources.CUSTOMER_SOURCE]: customerSourceReducer,
  [reduxResources.CUSTOMER_ADDRESS]: customerAddressReducer,
};

const createReducer = (history, asyncReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    ...staticReducer,
    ...asyncReducers,
  });

export default createReducer;
