import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';
import CustomerServices from 'services/CustomerServices';
import { customerActions } from 'store/ducks/CustomerDuck';
import actionUtils from '../../utils/actionUtils';

export function* fetchCustomerListSaga({ payload }) {
  try {
    const res = yield call(CustomerServices.get, payload);
    yield put(customerActions.setCustomerList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    yield put(customerActions.setLoading(false));
  }
}

export function* fetchCustomerInfoSaga({ payload }) {
  try {
    const res = yield call(CustomerServices.getOne, payload);
    const { ...rest } = res;
    yield put(
      customerActions.setCustomerInfo({
        ...rest,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createCustomerSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_CUSTOMER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerActions.fetchCustomerList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.COULD_NOT_CREATE_CUSTOMER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateCustomerSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_CUSTOMER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerActions.fetchCustomerList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_UPDATE_CUSTOMER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteCustomerListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(CustomerServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_CUSTOMER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerActions.fetchCustomerList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_DELETE_CUSTOMER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchCustomerAsync() {
  yield takeLatest(customerActions.fetchCustomerList, fetchCustomerListSaga);
  yield takeLatest(customerActions.fetchCustomerInfo, fetchCustomerInfoSaga);
  yield takeLatest(customerActions.createCustomer, createCustomerSaga);
  yield takeLatest(customerActions.updateCustomer, updateCustomerSaga);
  yield takeLatest(customerActions.deleteCustomerList, deleteCustomerListSaga);
}
