import { getCommonInstance } from './apiService';

const MerchantServices = {
  updateMerchantInfo: payload => {
    return getCommonInstance().put('merchant', payload);
  },
  getOne: () => {
    return getCommonInstance().get('merchant/detail');
  },
  getPublicDetail: ({ domain, subDomain }) => {
    return getCommonInstance({ usePublicApi: true }).get(
      `merchant/public-detail?${domain ? `domain=${domain}` : ''}&${
        subDomain ? `subDomain=${subDomain}` : ''
      }`,
    );
  },
};

export default MerchantServices;
