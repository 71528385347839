import { put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { customerClassificationActions } from 'store/ducks/CustomerClassificationDuck';
import CustomerClassificationServices from 'services/CustomerClassificationServices';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';

export function* fetchCustomerClassificationListSaga({ payload }) {
  yield put(customerClassificationActions.setLoading(true));
  try {
    const res = yield call(CustomerClassificationServices.get, payload);
    yield put(customerClassificationActions.setCustomerClassifications(res));
  } catch (error) {
    yield put(customerClassificationActions.setError(error));
  }
  yield put(customerClassificationActions.setLoading(false));
}

export function* createCustomerClassificationSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerClassificationServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_CUSTOMER_CLASSIFICATION' }));
    yield put(
      customerClassificationActions.fetchCustomerClassificationList({
        page: 0,
      }),
    );
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'ERRORS.CAN_NOT_CREATE_CUSTOMER_CLASSIFICATION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateCustomerClassificationSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerClassificationServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_CUSTOMER_CLASSIFICATION' }));
    yield put(
      customerClassificationActions.fetchCustomerClassificationList({
        page: 0,
      }),
    );
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'ERRORS.CAN_NOT_UPDATE_CUSTOMER_CLASSIFICATION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteCustomerClassificationsSaga({ payload }) {
  const { cb, data } = payload;
  console.log(data);
  try {
    yield call(CustomerClassificationServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_CUSTOMER_CLASSIFICATION' }));
    yield put(
      customerClassificationActions.fetchCustomerClassificationList({
        page: 0,
      }),
    );
  } catch (error) {
    console.log(error);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'ERRORS.CAN_NOT_DELETE_CUSTOMER_CLASSIFICATION',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchCustomerClassificationAsync() {
  yield takeLatest(
    customerClassificationActions.fetchCustomerClassificationList,
    fetchCustomerClassificationListSaga,
  );
  yield takeEvery(
    customerClassificationActions.createCustomerClassification,
    createCustomerClassificationSaga,
  );
  yield takeEvery(
    customerClassificationActions.updateCustomerClassification,
    updateCustomerClassificationSaga,
  );
  yield takeEvery(
    customerClassificationActions.deleteCustomerClassifications,
    deleteCustomerClassificationsSaga,
  );
}
