import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';
import VariationServices from 'services/VariationServices';
import { variationActions } from 'store/ducks/VariationDuck';

export function* fetchVariationListSaga({ payload }) {
  try {
    const res = yield call(VariationServices.get, payload);
    yield put(variationActions.setVariationList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    yield put(variationActions.setLoading(false));
  }
}

export function* fetchVariationDetailSaga({ payload }) {
  try {
    const res = yield call(VariationServices.getOne, payload);
    yield put(
      variationActions.setVariationDetail({
        ...res,
        branches: res?.branches?.map(({ branch, ...rest }) => ({
          ...rest,
          id: branch.id,
          name: branch.name,
        })),
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchVariationAsync() {
  yield takeLatest(variationActions.fetchVariationList, fetchVariationListSaga);
  yield takeLatest(
    variationActions.fetchVariationDetail,
    fetchVariationDetailSaga,
  );
}
