import React from 'react';
import { useTranslation } from 'react-i18next';

import fbLogo from 'assets/images/footer-social-fb.png';
import instLogo from 'assets/images/footer-social-ins.png';
import twitterLogo from 'assets/images/footer-social-twit.png';
import linkedinLogo from 'assets/images/footer-social-lin.png';
import ytLogo from 'assets/images/footer-social-yout.png';

// import bgMiddle from 'assets/images/footer-backgroud-moutain2.png';
import bgBottom from 'assets/images/footer-background-mountain.png';
import bgCharacter from 'assets/images/footer-background-character.gif';
import Spacing from 'components/common/Spacing/Spacing';

const LandingFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="landing-footer">
      <div className="landing-footer__container">
        <Spacing vertical={3} />
        <h1>{t('CONTACT_US')}</h1>
        <Spacing />
        {/*<h3>createappnow.com@gmail.com</h3>*/}
        <Spacing />
        <a href="tel:0789648399">(+84) 789 64 83 99</a>
        <Spacing />
        <div className="landing-footer__socials">
          <a href="https://www.facebook.com/CAN-Create-App-Now-117231769661378">
            <img src={fbLogo} alt="" className="landing-footer__social-icon" />
          </a>
          <img src={instLogo} alt="" className="landing-footer__social-icon" />
          <img src={twitterLogo} alt="" className="landing-footer__social-icon" />
          <img src={linkedinLogo} alt="" className="landing-footer__social-icon" />
          <img src={ytLogo} alt="" className="landing-footer__social-icon" />
        </div>
        <Spacing />
        <h3>Copyright © 2020, ECOMSIA GROUP.</h3>
      </div>
      <div
        className="landing-footer__background"
        style={{
          backgroundImage: `url(${bgBottom})`,
        }}
      >
        <div
          className="landing-footer__background__character"
          style={{
            backgroundImage: `url(${bgCharacter})`,
          }}
        />
      </div>
    </div>
  );
};

export default LandingFooter;
