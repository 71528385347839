import { getMerchantInstance } from './apiService';

const CustomerAddressServices = {
  update: ({ id, data }) => {
    return getMerchantInstance().put(`address/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('customer-address', payload);
  },
  delete: ({ customerId, addressId }) => {
    return getMerchantInstance().delete(
      `customer-address?customerId=${customerId}&addressId=${addressId}`,
    );
  },
  updateDefaultAddress: ({ data }) => {
    return getMerchantInstance().put(`customer-address/set-default`, data);
  },
};

export default CustomerAddressServices;
