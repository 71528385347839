import { createAction, createSelector, createReducer } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
  total: 0,
  merchantDetail: null,
  publicMerchantDetail: null,
};

const actions = {
  updateMerchant: createAction('merchant/update'),
  fetchMerchantDetail: createAction('merchant/fetch-detail'),
  setMerchantDetail: createAction('merchant/set-detail'),
  fetchMerchantPublicDetail: createAction('merchant/fetch-public-detail'),
  setPublicMerchantDetail: createAction('merchant/set-public-detail'),
  setLoading: createAction('merchant/set-loading'),
};

const reducer = createReducer(initialState, {
  [actions.setMerchantDetail]: (state, action) => {
    state.merchantDetail = action.payload;
  },
  [actions.setPublicMerchantDetail]: (state, action) => {
    state.publicMerchantDetail = action.payload;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.MERCHANT],
    state => state,
  ),
  getMerchantDetail: createSelector(
    state => state[reduxResources.MERCHANT],
    state => state.merchantDetail,
  ),
  getPublicMerchantDetail: createSelector(
    state => state[reduxResources.MERCHANT],
    state => state.publicMerchantDetail,
  ),
};

export { reducer as merchantReducer, actions as merchantActions, selectors as merchantSelectors };
