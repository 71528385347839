import { configConstants, subUserAccountTypes } from 'constants/constants';
import { api, getMerchantInstance } from './apiService';

const SubUserServices = {
  get: ({ page, size, sort } = {}) => {
    return getMerchantInstance().get(
      `sub-user?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getMerchantInstance().get(`sub-user/${id}`);
  },
  requestUpdatePassword: ({ id, email }) => {
    return api
      .getInstance({
        baseURL: configConstants.COMMON_SERVICE_URL,
        headers: {
          'X-merchantId': 1,
        },
      })
      .put(`account/${id}/password-reset`, {
        email,
        type: subUserAccountTypes.STAFF,
      });
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`sub-user/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('sub-user', payload);
  },
  delete: ids => {
    return getMerchantInstance().delete(`sub-user/bulk?ids=${ids.join(',')}`);
  },
};

export default SubUserServices;
