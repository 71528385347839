export default {
  // Landing page
  LOGIN: 'Đăng nhập',
  REGISTER: 'Đăng ký',
  FREE_REGISTER: 'Đăng ký miễn phí',
  BUILD_APP: 'Build app',
  PUBLISH_APP: 'Publish app',
  BUILD_PROGRESS: 'Tiến độ build',
  VI: 'Tiếng Việt',
  EN: 'English',
  LANDING_PAGE_FEATURES: 'Tính năng',
  PASSWORD: 'Mật khẩu',
  'PASSWORD.NOT_EMPTY': 'Mật khẩu không thể trống',
  'EMAIL.NOT_EMPTY': 'Email không thể trống',
  DONT_HAVE_AN_ACCOUNT: 'Chưa có tài khoản?',
  CONTACT_US: 'Liên hệ với chúng tôi',
  //
  CREATE_BRAND: 'Tạo thương hiệu',
  EDIT_BRAND: 'Sửa thương hiệu',
  BRAND_LIST: 'Danh sách thương hiệu',
  DESCRIPTION: 'Mô tả',
  BRAND_NAME: 'Tên thương hiệu',
  BRAND_LOGO: 'Logo',
  BRAND_COVER: 'Ảnh cover',
  CANCEL: 'Huỷ',
  CREATE: 'Tạo',
  THIS_FIELD_IS_REQUIRED: 'Trường này không thể trống',
  MUST_BE_A_NUMBER: 'Vui lòng nhập số',
  ERROR_UPLOAD_IMAGE: 'Không thể tải lên ảnh',
  DELETE_BRAND: 'Xoá thương hiệu',
  DELETE: 'Xoá',
  NO_BRAND_FOUND: 'Chưa có thương hiệu nào được tạo',
  OK: 'Đồng ý',
  //
  APP: 'Ứng dụng',
  MERCHANT: 'Cửa hàng',
  CUSTOMER_LIST: 'Khách hàng',
  ORDERS: 'Đơn hàng',
  MERCHANT_INFORMATION: 'Thông tin cửa hàng',
  BRAND: 'Thương hiệu',
  BRANDS: 'Thương hiệu',
  // PRODUCT: 'Sản phẩm',
  PRODUCTS: 'Sản phẩm',
  CATEGORY: 'Danh mục',
  NEWS: 'Tin tức',
  PROMOTION: 'Khuyến mãi',
  BANNER: 'Banner',
  SLIDER: 'Slider',
  ACC_POINT: 'Tích điểm',
  BRANCHES: 'Cửa hàng',
  STAFF: 'Nhân viên',
  APP_INFORMATION: 'Thông tin ứng dụng',
  DESIGN: 'Thiết kế',
  CONFIGURATION: 'Cấu hình',
  HOME: 'Trang chủ',
  MESSAGES: 'Tin nhắn',
  CART: 'Giỏ hàng',
  ADDRESS_BOOK: 'Sổ địa chỉ',
  PROMOTIONS: 'Khuyến mãi',
  CATEGORIES: 'Danh mục',
  CONTACT: 'Liên hệ',
  RATINGS: 'Đánh giá',
  TRY_APP: 'Dùng thử ứng dụng',
  ACCOUNT: 'Tài khoản',
  'ERRORS.CAN_NOT_CREATE_BRAND': 'Không thể tạo thương hiệu',
  'ERRORS.CAN_NOT_UPDATE_BRAND': 'Không thể chỉnh sửa thương hiệu',
  'ERRORS.CAN_NOT_DELETE_BRAND': 'Không thể xoá thương hiệu',
  'ERRORS.CAN_NOT_UPDATE_CATEGORY': 'Không thể chỉnh sửa danh mục',
  'ERRORS.CAN_NOT_DELETE_CATEGORY': 'Không thể xoá danh mục',
  UPDATED_BRAND: 'Đã chỉnh sửa thương hiệu!',
  CREATED_BRAND: 'Đã tạo thương hiệu thành công!',
  DELETED_BRAND: 'Đã xoá thương hiệu thành công!',
  CREATED_AT: 'Đã tạo lúc',
  UPDATED_AT: 'Cập nhật lúc',
  NO_CATEGORY_FOUND: 'Chưa có danh mục được tạo',
  CREATE_CATEGORY: 'Tạo danh mục mới',
  EDIT_CATEGORY: 'Chỉnh sửa danh mục',
  DELETE_CATEGORY: 'Xoá danh mục',
  CATEGORY_LOGO: 'Hình ảnh',
  CATEGORY_NAME: 'Tên danh mục',
  STATUS: 'Trạng thái',
  'STATUS.ACTIVE': 'Kích hoạt',
  'STATUS.INACTIVE': 'Dừng hoạt động',
  ACTIVE: 'Kích hoạt',
  INACTIVE: 'Dừng hoạt động',
  CATEGORY_PARENT: 'Danh mục cha',
  NULL: 'Không có',
  UPDATED_CATEGORY: 'Đã chỉnh sửa danh mục',
  VARIATION_NAME: 'Tên',
  VARIATION_SKU: 'SKU',
  VARIATION_ORIGINAL_PRICE: 'Giá nhập',
  VARIATION_RETAIL_PRICE: 'Giá bán',
  VARIATION_SALE_PRICE: 'Giá khuyến mãi',
  VARIATION_QUANTITY: 'Số lượng',
  VARIATION_IMAGES: 'Hình ảnh',
  CREATE_PRODUCT: 'Tạo sản phẩm',
  PRODUCT_COMMON_INFO: 'Thông tin chung',
  HAS_VARIATIONS: 'Sản phẩm có biến thể',
  PRODUCT_NAME: 'Tên sản phẩm',
  SELECT_BRAND: 'Chọn thương hiệu',
  SELECT_CATEGORY: 'Chọn danh mục',
  ADD_ATTRIBUTE: 'Thêm thuộc tính',
  ATTRIBUTE_NAME: 'Tên thuộc tính',
  ADD_VALUE: 'Giá trị',
  PRODUCT_IMAGE: 'Hình ảnh',

  // CUSTOMER CLASSIFICATION
  CREATE_CUSTOMER_CLASSIFICATION: 'Tạo phân loại khách hàng',
  EDIT_CUSTOMER_CLASSIFICATION: 'Sửa phân loại khách hàng',
  CUSTOMER_CLASSIFICATION_LIST: 'Danh sách phân loại khách hàng',
  CUSTOMER_CLASSIFICATION_NAME: 'Tên phân loại khách hàng',
  CUSTOMER_CLASSIFICATION_LOGO: 'Logo',
  CUSTOMER_CLASSIFICATION_COVER: 'Ảnh cover',
  DELETE_CUSTOMER_CLASSIFICATION: 'Xoá phân loại khách hàng',
  NO_CUSTOMER_CLASSIFICATION_FOUND: 'Chưa có phân loại khách hàng nào được tạo',
  CUSTOMER_CLASSIFICATION: 'Phân loại khách hàng',
  // CUSTOMER_CLASSIFICATIONS: 'Phân loại khách hàng',
  'ERRORS.CAN_NOT_CREATE_CUSTOMER_CLASSIFICATION': 'Không thể tạo phân loại khách hàng',
  'ERRORS.CAN_NOT_UPDATE_CUSTOMER_CLASSIFICATION': 'Không thể chỉnh sửa phân loại khách hàng',
  'ERRORS.CAN_NOT_DELETE_CUSTOMER_CLASSIFICATION': 'Không thể xoá phân loại khách hàng',
  UPDATED_CUSTOMER_CLASSIFICATION: 'Đã chỉnh sửa phân loại khách hàng!',
  CREATED_CUSTOMER_CLASSIFICATION: 'Đã tạo phân loại khách hàng thành công!',
  DELETED_CUSTOMER_CLASSIFICATION: 'Đã xoá phân loại khách hàng thành công!',
  CUSTOMER_CLASSIFICATION_MINIMUM_POINT: 'Điểm tối thiểu',
  LOGIN_SUCCESSFUL: 'Đăng nhập thành công',
  CREATED_CATEGORY: 'Đã tạo danh mục thành công',
  DELETED_CATEGORY: 'Đã xoá danh mục thành công',
  NEXT_PAGE: 'Trang tiếp theo',
  PREVIOUS_PAGE: 'Trang trước',
  OF: 'của',
  ROWS_PER_PAGE: 'Số hàng trên 1 trang',
  SEARCH: 'Tìm kiếm',
  PRINT: 'In',
  VIEW_COLUMNS: 'Xem cột',
  FILTER_TABLE: 'Lọc bảng',
  ALL: 'Tất cả',
  FILTERS: 'Bộ lọc',
  RESET: 'Đặt lại',
  SHOW_COLUMNS: 'Hiển thị cột',
  SHOW_HIDE_TABLE_COLUMNS: 'Ẩn hiện cột',
  ROWS_TO_DELETE: 'hàng',
  DELETE_SELECTED_ROWS: 'Xoá những hàng đã chọn',
  CATEGORY_LIST: 'Danh sách danh mục',
  BRANCH_LIST: 'Danh sách cửa hàng',
  LOGOUT: 'Đăng xuất',
  AMOUNT_OF_MONEY_TYPE: 'Theo giá trị đơn hàng',
  'AMOUNT_OF_MONEY_TYPE.DESCRIPTION':
    // eslint-disable-next-line max-len
    'Tích điểm theo giá trị đơn hàng. Ví dụ: Giá trị nhập là 20.000 thì số điểm tích được của khách hàng là (Giá trị đơn hàng / 20000)',
  PERCENT_TYPE: 'Theo phần trăm',
  'PERCENT_TYPE.DESCRIPTION':
    // eslint-disable-next-line max-len
    'Tính điểm theo phần trăm giá trị đơn hàng. Ví dụ: Giá trị nhập là 50 thì số điểm tích được của khách hàng là (50 % x Giá trị đơn hàng)',
  QUANTITY_TYPE: 'Theo số lượng sản phẩm',
  'QUANTITY_TYPE.DESCRIPTION':
    // eslint-disable-next-line max-len
    'Tích điểm theo số lượng sản phẩm có trong đơn hàng. Ví dụ: Giá trị nhập là 3 thì số điểm tích được của khách hàng là (Số lượng sản phẩm trong đơn hàng / 3)',
  ACCUMULATION_POINT_VALUE: 'Giá trị',
  ACCUMULATION_STATUS: 'Trạng thái',
  ACCUMULATION_TYPE: 'Loại tích điểm',
  'ACCUMULATION_STATUS.ACTIVE': 'Kích hoạt',
  'ACCUMULATION_STATUS.INACTIVE': 'Dừng hoạt động',
  THIS_FIELD_MUST_BE_GREATER_THAN: 'Trường này phải lớn hơn',
  EDIT_ACCUMULATION_POINT: 'Chỉnh sửa tích điểm',
  SAVE_ACCUMULATION_POINT: 'Lưu tích điểm',
  EDITED_ACCUMULATION_POINT: 'Đã chỉnh sửa tích điểm thành công!',
  CREATE_ACCUMULATION_POINT: 'Tạo tích điểm',
  CREATED_ACCUMULATION_POINT: 'Đã tạo tích điểm thành công!',
  UPDATE_ACCUMULATION_POINT: 'Chỉnh sửa tích điểm',
  UPDATED_ACCUMULATION_POINT: 'Chỉnh sửa tích điểm thành công!',
  ACCUMULATION_POINT_LIST: 'Danh sách tích điểm',
  ACCUMULATION_POINT: 'Tích điểm',
  DELETE_ACCUMULATION_POINT: 'Xoá tích điểm',
  DELETED_ACCUMULATION_POINT: 'Đã xoá tích điểm thành công',
  NO_ACCUMULATION_POINT_FOUND: 'Chưa có tích điểm được tạo',
  'ERRORS.CAN_NOT_CREATE_ACCUMULATION_POINT': 'Đã có lỗi khi tạo tích điểm!',
  SUB_USERS: 'Nhân viên',
  BRANCH_STATUS: 'Trạng thái cửa hàng',
  'BRANCH_STATUS.ACTIVE': 'Hoạt động',
  'BRANCH_STATUS.INACTIVE': 'Tạm ngừng hoạt động',
  IS_REQUIRED: 'là bắt buộc',
  NAME: 'Tên',
  CONTACT_NAME: 'Tên liên hệ',
  CONTACT_EMAIL: 'Email',
  CONTACT_PHONE: 'Số điện thoại',
  BRANCH_NAME: 'Tên cửa hàng',
  CREATE_BRANCH: 'Tạo cửa hàng',
  EDIT_BRANCH: 'Chỉnh sửa cửa hàng',
  INVALID_EMAIL: 'Email không đúng định dạng',
  NO_BRANCH_FOUND: 'Chưa có cửa hàng được tạo',
  PROVINCE: 'Tỉnh/TP',
  DISTRICT: 'Quận/Huyện',
  WARD: 'Phường/Xã',
  LATITUDE: 'Vĩ độ',
  LONGITUDE: 'Kinh độ',
  ADDRESS: 'Địa chỉ',
  CREATED_BRANCH: 'Đã tạo cửa hàng thành công!',
  DELETE_BRANCH: 'Xoá cửa hàng',
  DELETED_BRANCH: 'Đã xoá cửa hàng thành công!',
  UPDATED_BRANCH: 'Đã chỉnh sửa thông tin cửa hàng',
  'ERRORS.CAN_NOT_CREATE_BRANCH': 'Không thể tạo cửa hàng',
  '{merchant.branch.name.duplicated}': 'Tên cửa hàng đã tồn tại',
  SUB_USER_TYPE: 'Loại tài khoản',
  'SUB_USER_TYPE.STAFF': 'Nhân viên',
  'SUB_USER_TYPE.MANAGER_OF_ALL_BRANCHES': 'Quản lý của tất cả cửa hàng',
  'SUB_USER_TYPE.MANAGER_OF_MULTIPLE_BRANCHES': 'Quản lý nhiều cửa hàng',
  WORKING_STATUS: 'Trạng thái làm việc',
  'WORKING_STATUS.IS_WORKING': 'Đang làm việc',
  'WORKING_STATUS.IS_NOT_WORKING': 'Chưa làm việc ',
  PRIORITY: 'Độ ưu tiên',
  EDIT_SUB_USER: 'Chỉnh sửa nhân viên',
  CREATE_SUB_USER: 'Thêm nhân viên',
  SUB_USER_LIST: 'Danh sách nhân viên',
  DELETE_SUB_USER: 'Xoá tài khoản nhân viên',
  QUALIFICATION: 'Trình độ',
  NO_SUB_USER_FOUND: 'Chưa có nhân viên được tạo',
  SUB_USER_NAME: 'Tên nhân viên',
  PHONE: 'Số điện thoại',
  EMAIL: 'Email',
  POSTCODE: 'Mã bưu điện',
  BIRTHDAY: 'Ngày sinh',
  AVATAR: 'Ảnh đại diện',
  LOADING_MESSAGE: 'Đang tải...',
  SUB_USER_INFO: 'Thông tin',
  CREATED_SUB_USER: 'Đã tạo nhân viên',
  UPDATED_SUB_USER: 'Đã thay đổi thông tin nhân viên',
  DELETED_SUB_USER: 'Đã xoá nhân viên',
  PROMOTION_VALUE_TYPE: 'Hình thức khuyến mãi',
  PROMOTION_LIST: 'Danh sách khuyến mãi',
  PROMOTION_GENERAL_INFO: 'Thông tin khuyến mãi',
  PROMOTION_CONDITIONS: 'Điều kiện áp dụng',
  CREATE_PROMOTION: 'Tạo chương trình khuyến mãi',
  EDIT_PROMOTION: 'Chỉnh sửa khuyến mãi',
  SAVE_PROMOTION_BUTTON_LABEL: 'Lưu khuyến mãi',
  CREATED_PROMOTION: 'Đã tạo chương trình khuyến mãi thành công',
  UPDATED_PROMOTION: 'Đã chỉnh sửa khuyến mãi thành công',
  DELETED_PROMOTION: 'Đã xoá khuyến mãi thành công',
  DELETE_PROMOTION: 'Xoá khuyến mãi',
  MIN_EXCHANGE_POINT: 'Điểm tối thiểu có thể quy đổi',
  PROMOTION_TYPE: 'Loại khuyến mãi',
  PROMOTION_TYPE_COLUMN_TITLE: 'Loại',
  PROMOTION_QUANTITY: 'Số lượng',
  PROMOTION_TIME_START: 'Thời gian bắt đầu',
  PROMOTION_TIME_END: 'Thời gian kết thúc',
  DISCOUNT_MONEY: 'Số tiền được giảm',
  MAX_DISCOUNT_VALUE: 'Số tiền giảm tối đa',
  DISCOUNT_PERCENT: 'Phần trăm được giảm (%)',
  PROMOTION_CODE: 'Mã khuyến mãi',
  PROMOTION_HAS_NOT_CODE: 'Không có mã',
  PROMOTION_CODE_COLUMN_TITLE: 'Mã',
  PROMOTION_NAME: 'Tên chương trình khuyến mãi',
  PROMOTION_NAME_COLUMN_TITLE: 'Tên',
  PROMOTION_APPLICABLE_PRODUCTS: 'Sản phẩm áp dụng',
  PROMOTION_PRODUCTS: 'Sản phẩm tặng khuyến mãi',
  NO_PROMOTION_FOUND: 'Chưa có khuyến mãi được tạo',
  APPLICABLE_PROMOTION_BRANDS: 'Thương hiệu được áp dụng',
  APPLICABLE_PROMOTION_CATEGORIES: 'Danh mục được áp dụng',
  APPLICABLE_PROMOTION_PRODUCTS: 'Sản phẩm cụ thể được áp dụng',
  APPLICABLE_PROMOTION_VARIATIONS: 'Biến thể cụ thể được áp dụng',
  SPECIFIC_PROMOTION_BRANDS: 'Tặng bất kỳ sản phẩm thuộc thương hiệu',
  SPECIFIC_PROMOTION_CATEGORIES: 'Tặng bất kỳ sản phẩm thuộc danh mục',
  SPECIFIC_PROMOTION_PRODUCTS: 'Tặng sản phẩm cụ thể',
  SPECIFIC_PROMOTION_VARIATIONS: 'Tặng biến thể cụ thể',
  NUMBER_OF_PROMOTION_PRODUCTS: 'Số sản phẩm được tặng',
  PROFILE: 'Tài khoản',
  GO_TO_DASHBOARD: 'Đi đến trang quản lý',
  PROMOTION_MIN_VALUE_MONEY: 'Tổng giá trị của đơn hàng phải đạt',
  PROMOTION_MIN_VALUE_PRODUCT: 'Số sản phẩm ít nhất mà đơn cần có',
  PROMOTION_MIN_VALUE_TYPE: 'Điều kiện tối thiểu',
  TO_DATE: 'đến',
  'PROMOTION_MIN_VALUE_TYPE.MONEY': 'Theo giá trị tối thiểu của đơn hàng',
  'PROMOTION_MIN_VALUE_TYPE.QUANTITY': 'Theo số lượng sản phẩm tối thiểu trong đơn hàng',
  'PROMOTION_TYPE.COUPON': 'Voucher',
  'PROMOTION_TYPE.DISCOUNT': 'Giảm giá',
  'PROMOTION_VALUE_TYPE.AMOUNT_OF_MONEY': 'Giảm theo số tiền',
  'PROMOTION_VALUE_TYPE.PRODUCT': 'Tặng sản phẩm',
  'PROMOTION_VALUE_TYPE.PERCENT': 'Giảm giá theo phần trăm giá trị đơn hàng',
  PROMOTION_CONDITION_TYPE: 'Loại điều kiện',
  'PROMOTION_CONDITION_TYPE.AND': 'AND các điều kiện',
  'PROMOTION_CONDITION_TYPE.OR': 'OR các điều kiện',
  '{merchant.sub-user.duplicated}': 'Email hoặc số điện thoại đã sửa dụng',
  '{resource.not-found}': 'Đã có lỗi hệ thống, vui lòng thử lại sau!',
  '{promotion.code.duplicated}': 'Mã khuyến mãi đã được sử dụng, vui lòng thử mã khác',
  'Network Error': 'Không thể kết nối với server, vui lòng thử lại sau',
  PRODUCT_VARIATION: 'Biến thể',
  PRODUCT_ATTRIBUTE: 'Thuộc tính',
  GENERAL_SETTINGS: 'Thiết lập chung',
  MERCHANT_PRIMARY_COLOR: 'Màu chính',
  MERCHANT_SECONDARY_COLOR: 'Màu phụ',
  MERCHANT_LOGO: 'Logo cửa hàng',
  MERCHANT_FAVICON: 'Favicon cửa hàng',
  MERCHANT_NAME: 'Tên doanh nghiệp',
  TAX_CODE: 'Mã số thuế',
  BANK_ACCOUNT_ID: 'Số tài khoản ngân hàng',
  MERCHANT_CONTACT_PHONE: 'Số điện thoại liên hệ',
  MERCHANT_CONTACT_NAME: 'Tên người đại diện',
  MERCHANT_CONTACT_POSITION: 'Vị trí người đại diện',
  SAVE_CHANGE: 'Lưu thay đổi',
  BRANCH_CODE: 'Mã cửa hàng',
  BRANCH_LOGO: 'Ảnh đại diện',
  PROMOTION_QUANTITY_USED: 'Đã sử dụng',
  MERCHANT_GENERAL_INFORMATION: 'Thông tin chung',
  CONFIGURATIONS: 'Thiết lập hệ thống',
  BANK_PLACE: 'Chi nhánh ngân hàng',
  BANK_NAME: 'Tên ngân hàng',
  SUB_DOMAIN: 'Subdomain',
  DOMAIN: 'Domain',
  CREATED_AT_DATE: '{{date, DD/MM/YYYY hh:mm A}}',
  PAYMENT: 'Thanh toán',
  PAYMENT_LIST: 'Danh sách phương thức thanh toán',
  NO_PAYMENT_FOUND: 'Chưa có phương thức thanh toán nào được tạo',
  PAYMENT_NAME: 'Tên',
  PAYMENT_LOGO: 'Hình ảnh',
  PAYMENT_DESCRIPTION: 'Mô tả',
  PAYMENT_CODE: 'Mã',
  PAYMENT_PORT: 'Cổng thanh toán',
  HAS_PAYMENT_PORTAL: 'Có cổng thanh toán',
  NO_PAYMENT_PORTAL: 'Không',
  PRICE_INPUT_ALERT:
    // eslint-disable-next-line max-len
    'Trường hợp giá sản phẩm theo lô và cửa hàng được bật, giá dưới đây là giá mặc định được dùng tại các cửa hàng không chọn lô để bán.',
  PRODUCT_IS_NEW: 'Sản phẩm mới',
  PRODUCT_IS_FEATURED: 'Sản phẩm nổi bật',
  IS_PRODUCT_PRICE_BY_LOT: 'Sản phẩm có giá bán theo lô',
  IS_PRODUCT_PRICE_BY_BRANCH: 'Sản phẩm có giá bán theo cửa hàng',
  IS_PRODUCT_QUANTITY_BY_LOT: 'Sản phẩm có số lượng quản lý theo lô',
  VARIATION: 'Biến thể',
  PRODUCT_PRICE_TABLE: 'Bảng giá',
  PRODUCT_PRICE_HISTORY: 'Lịch sử giá',
  PRODUCT_INPUT_FROM_FILE: 'Import',
  SELECT_BRANCH_TO_INPUT_PRICE: 'Chọn cửa hàng',
  SELECT_VARIATION_TO_INPUT_PRICE: 'Chọn biến thể',
  SELECT_PRICE_RETAIL_PRICE: 'Đơn vị tính',
  AMOUNT: 'Số lượng',
  SELECT_PRICE_CURRENCY: 'Đơn vị tiền tệ',
  VND: '₫',
  START_DATE: 'Ngày bắt đầu',
  END_DATE: 'Ngày kết thúc',
  PRODUCT_LINK: 'Liên kết sản phẩm',
  CUSTOMER_NAME: 'Tên',
  // CUSTOMER_SOURCE: 'Nguồn',
  CUSTOMER_PHONE: 'Điện thoại',
  CUSTOMER_TYPE: 'Hình thức',
  CUSTOMER_EMAIL: 'Email',
  CUSTOMER_POINT: 'Điểm',
  NEW: 'Mới',
  CUSTOMER_AVATAR: 'Ảnh đại diện',
  RESET_SUB_USER_PASSWORD: 'Gửi mật khẩu mới cho tài khoản này?',
  RESET_PASSWORD: 'Đặt lại mật khẩu',
  REQUESTED_NEW_PASSWORD: 'Đã tạo lại mật khẩu cho nhân viên',
  OTHER_PARTNERS: 'Đối tác khác',
  PARTNERS: 'Đối tác',
  SUPPLIERS: 'Nhà cung cấp',
  CREATE_SUPPLIER: 'Tạo nhà cung cấp',
  EDIT_SUPPLIER: 'Chỉnh sửa cung cấp',
  CREATED_SUPPLIER: 'Đã tạo nhà cung cấp thành công',
  SUPPLIER_LIST: 'Danh sách nhà cung cấp',
  NO_SUPPLIER_FOUND: 'Chưa có nhà cung cấp nào được tạo',
  SUPPLIER_NAME: 'Tên nhà cung cấp',
  SUPPLIER_INFORMATION: 'Thông tin nhà cung cấp',
  SUPPLIER_AVATAR: 'Ảnh đại diện nhà cung cấp',
  REFRESH: 'Làm mới',
  NO_CUSTOMER_FOUND: 'Chưa có khách hàng',
  DELETE_PARTNER_TYPE: 'Xoá loại đối tác',
  EDIT_PARTNER_TYPE: 'Chỉnh sửa loại đối tác',
  CREATE_PARTNER_TYPE: 'Tạo loại đối tác',
  PARTNER_TYPE: 'Loại đối tác',
  UPDATED_PARTNER_TYPE: 'Đã chỉnh sửa loại đối tác thành công',
  CREATED_PARTNER_TYPE: 'Đã thêm loại đối tác thành công',
  DELETED_PARTNER_TYPE: 'Đã xoá loại đối tác thành công',
  'CUSTOMER_TYPE.INDIVIDUAL': 'Cá nhân',
  'CUSTOMER_TYPE.ENTERPRISE': 'Doanh nghiệp',
  INDIVIDUAL: 'Cá nhân',
  ENTERPRISE: 'Doanh nghiệp',
  SUPPLIER_CONTACT_NAME: 'Người đại diện',
  SUPPLIER_CONTACT_PHONE: 'Số điện thoại người đại diện',
  CONTACT_POSITION: 'Vị trí/Chức vụ',
  DELETE_SUPPLIER: 'Xoá nhà cung cấp',
  DELETED_SUPPLIER: 'Đã xoá nhà cung cấp thành công',
  PARTNER_LIST: 'Danh sách đối tác',
  PARTNER_NAME: 'Tên đối tác',
  CREATE_PARTNER: 'Tạo đối tác',
  DELETE_PARTNER: 'Xoá đối tác',
  EDIT_PARTNER: 'Chỉnh sửa đối tác',
  PARTNER_INFORMATION: 'Thông tin đối tác',
  CREATED_PARTNER: 'Đã tạo đối tác thành công',
  NO_OPTIONS: 'Không có lựa chọn',
  DELETED_PARTNER: 'Đã xoá đối tác thành công',
  NO_PARTNER_FOUND: 'Chưa có đối tác được tạo',
  OTHERS: 'Khác',
  UPDATED_MERCHANT_INFO: 'Đã cập nhật thông tin thành công!',
  MERCHANT_CONTACT_EMAIL: 'Email người đại diện',
  SALE_PRICE_VALUE_TYPE: 'Theo',
  PERCENT: 'Phần trăm',
  VALUE: 'Số tiền',
  IS_NEW: 'Mới',
  IS_FEATURED: 'Nổi bật',
  IS_NEW_ALL: 'Tất cả',
  IS_FEATURED_ALL: 'Tất cả',
  PRODUCT_VARIATION_ATTRIBUTES: 'Thuộc tính biến thể',
  PRODUCT_CONFIG: 'Cấu hình',
  PRODUCT_INFO_DETAILS: 'Thông tin chi tiết',
  VARIATION_NAME_IN_BRANCH_PRICE: 'Tên biến thể',
  UPDATED_PARTNER: 'Đã cập nhật thông tin đối tác thành công!',
  'CONFIRMABLE_SWITCH_DIALOG_MESSAGE.INACTIVE': 'Xác nhận dừng hoạt động chức năng?',
  'CONFIRMABLE_SWITCH_DIALOG_MESSAGE.ACTIVE': 'Xác nhận kích hoạt chức năng?',
  BRANCH_INFORMATION: 'Thông tin cửa hàng',
  BRANCH_RESOURCES: 'Hình ảnh cửa hàng',
  RESOURCE_FOLDER: 'Thư mục hình ảnh',
  CREATE_RESOURCE_FOLDER: 'Tạo thư mục hình ảnh',
  IS_BRANCH_OPEN: 'Đang mở cửa',
  IS_OWNER: 'Sở hữu cửa hàng',
  RENT_PRICE: 'Giá thuê',
  RENT_PRICE_UNIT: 'Đơn vị tính',
  CLOSED_BRANCH_DATE: 'Ngày đóng cửa',
  ESTABLISHED_DATE: 'Ngày thành lập',
  BRANCH_CODE_MAX_6: 'Mã cửa hàng tối đa 6 kí tự',
  OPEN_TIME: 'Giờ mở cửa',
  CLOSE_TIME: 'Giờ đóng cửa',
  AREA_SIZE: 'Diện tích',
  NOTE: 'Ghi chú',
  SELECT_STATUS: 'Chọn trạng thái',
  'PRODUCT.DRAFT': 'Lưu nháp',
  'PRODUCT.ACTIVE': 'Đang kinh doanh',
  'PRODUCT.INACTIVE': 'Ngừng kinh doanh',
  EDIT_PRODUCT: 'Sửa sản phẩm',
  PRODUCT_LIST: 'Danh sách sản phẩm',
  PRODUCT_LOGO: 'Ảnh sản phẩm',
  PROPERTIES: 'Thuộc tính',
  SKU_BY_VARIATIONS: 'Theo biến thể',
  'PRODUCT_LIST.IMAGE': 'Ảnh đại diện',
  'PRODUCT_LIST.STT': 'STT',
  'PRODUCT_LIST.SKU': 'SKU',
  'PRODUCT_LIST.NAME': 'Tên sản phẩm',
  'PRODUCT_LIST.BRAND': 'Thương hiệu',
  'PRODUCT_LIST.CATEGORY': 'Danh mục',
  'PRODUCT_LIST.RETAIL_PRICE': 'Giá bán',
  'PRODUCT_LIST.ORIGINAL_PRICE': 'Giá nhập',
  'PRODUCT_LIST.SALE_PRICE': 'Giá khuyến mãi',
  'PRODUCT_LIST.IS_PRODUCT_PRICE_BY_LOT': 'Giá theo lô',
  'PRODUCT_LIST.IS_PRODUCT_PRICE_BY_BRANCH': 'Giá theo cửa hàng',
  'PRODUCT_LIST.STATUS': 'Tình trạng',
  RETAIL_BY_VARIATIONS: 'Theo biến thể',
  ORIGINAL_BY_VARIATIONS: 'Theo biến thể',
  SALE_BY_VARIATIONS: 'Theo biến thể',
  LOTS: 'Lô hàng',
  CREATE_LOT: 'Nhập lô',
  EDIT_LOT: 'Sửa lô',
  LOT_INFO: 'Thông tin lô',
  LOT_PRODUCT: 'Sản phẩm lô hàng',
  EXPORT_PAYMENT: 'Xuất phiếu chi',
  'LOT.BACK': 'Trở về',
  'LOT.NEXT': 'Tiếp tục',
  'LOT.FINISH': 'Hoàn thành',
  IS_LOT_BY_DEADLINE: 'Quản lý lô theo ngày hết hạn',
  'LOT-DETAIL.STT': 'STT',
  IMPORT_EXCEL: 'Import Excel',
  'LOT-DETAIL.SKU': 'SKU',
  'LOT-DETAIL.SL': 'Số lượng',
  'LOT-DETAIL.RETAIL_PRICE': 'Đơn giá',
  'LOT-DETAIL.AMOUNT': 'Thành tiền',
  'LOT-DETAIL.DISCOUNT': 'Chiết khấu',
  'LOT-DETAIL.PAID': 'Thanh toán',
  'LOT-DETAIL.NOTE': 'Ghi chú',
  LOT_DETAIL_INVALID: 'Sản phẩm lô hàng không hợp lệ',
  LOT_DETAIL_AMOUNT_INVALID: 'Sai thông tin thành tiền',
  NEED_EXPORT_PAYMENT: 'Bạn có muốn xuất phiếu chi không?',
  YES: 'Có',
  NO: 'Không',
  SELECT_BRANCH: 'Chọn cửa hàng',
  LOT_CODE: 'Mã lô',
  LOT_DEADLINE_DATE: 'Ngày hết hạn',
  LOT_IMPORT_DATE: 'Ngày nhập lô',
  LOT_SENDER: 'Nhân viên giao',
  LOT_RECEIVER: 'Nhân viên nhận',
  LOT_PERSON_IN_CHARGE: 'Nhân viên phụ trách',
  LOT_TOTAL_DISCOUNT: 'Chiết khấu tổng',
  LOT_NOTE: 'Ghi chú',
  SELECT_PARTNER: 'Nhà cung cấp',
  LOT_ORDER_NO: 'Mã đơn đặt hàng tham chiếu',
  LOT_INVOICE_NO: 'Mã hóa đơn tham chiếu',
  IS_AUTO_GEN_CODE: 'Tạo mã tự động',
  ATTACHED_FILE: 'File đính kèm',
  LOT_DETAIL_LIST: 'Danh sách lô hàng sản phẩm',
  IMPORT_OTHER_EXCEL: 'Nhập từ Excel khác',
  ACCEPT_REMOVE_LOT_DETAIL: 'Xóa lô hàng sản phẩm',
  REMOVE: 'Xóa',
  PRICE_BY_LOT_REQUIRED_PRICE_BY_BRANCH:
    'Sản phẩm có giá bán theo lô yêu cầu phải có giá bán theo cửa hàng',
  PRICE_BY_LOT_REQUIRED_PRICE_BY_BRANCH_DETAIL:
    'Sản phẩm có giá bán theo lô yêu cầu phải có giá bán theo cửa hàng',
  SUM: 'Tổng',
  LOT_DETAILS_HAS_ERROR: 'Sai thông tin sản phẩm lô hàng',
  'LOT-DETAIL.ORIGINAL_PRICE': 'Giá vốn',
  LOT_LIST: 'Danh sách lô',
  LOT_BRANCH_NAME: 'Cửa hàng',
  VIEW_LOT_ONLY: 'Chỉ xem thông tin lô',
  VIEW_LOT_DETAILS: 'Xem sản phẩm lô hàng',
  CANNOT_FETCHING_PRICE: 'Có lỗi xảy ra khi lấy thông giá',
  LOT_ORIGINAL_PRICE: 'Giá nhập',
  LOT_RETAIL_PRICE: 'Giá bán',
  LOT_SALE_PRICE: 'Giá khuyến mãi',
  'PRICE_HISTORY.STT': 'STT',
  'PRICE_HISTORY.LOT_CODE': 'Mã lô',
  'PRICE_HISTORY.BRANCH': 'Cửa hàng',
  'PRICE_HISTORY.TYPE': 'Loại',
  'PRICE_HISTORY.VALUE': 'Giá',
  'PRICE_HISTORY.START_DATE': 'Ngày bắt đầu',
  'PRICE_HISTORY.END_DATE': 'Ngày kết thúc',
  'PRICE_HISTORY.CREATED_BY': 'Người tạo',
  'PRICE_HISTORY.STATUS': 'Trạng thái',
  EMPTY_MESSAGE: 'Không có dữ liệu',
  PRICE_TABLE_BY_BRANCH: 'Bảng giá theo cửa hàng',
  PRICE_TABLE_BY_BRANCH_AND_LOT: 'Bảng giá theo lô',
  VIEW_PRICE_HISTORY: 'Xem lịch sử giá',
  PRICE_HISTORY: 'Lịch sử giá',
  SELECT_VARIATION_TO_VIEW_PRICE_HISTORY: 'Chọn biến thể',
  CLOSE: 'Đóng',
  SAVE: 'Lưu',
  UPDATE_THIS_PRICE_WILL_ALSO_UPDATE_IN_LOT:
    'Sửa giá sản phẩm cũng sẽ sửa đơn giá của lô hàng sản phẩm tương ứng',
  // Warehouse
  WAREHOUSE: 'Kho',
  CREATE_WAREHOUSE: 'Tạo kho',
  EDIT_WAREHOUSE: 'Chỉnh sửa thông tin kho',
  DELETE_WAREHOUSE: 'Xóa kho',
  SHOW_WAREHOUSE_DETAIL: 'Xem lưu trữ',
  NO_WAREHOUSE_FOUND: 'Chưa có kho được tạo',
  WAREHOUSE_INFORMATION: 'Thông tin chung',
  WAREHOUSE_LIST: 'Danh sách kho',
  WAREHOUSE_NAME: 'Tên',
  WAREHOUSE_CODE: 'Mã',
  WAREHOUSE_TYPE: 'Loại',
  'WAREHOUSE_TYPE.SALE': 'Kho bán hàng',
  'WAREHOUSE_TYPE.WARRANTY': 'Kho bảo hành',
  'WAREHOUSE_TYPE.ERROR': 'Kho lỗi',
  'WAREHOUSE_TYPE.ASSET_OR_EQUIPMENT': 'Tài sản - thiết bị',
  'WAREHOUSE_TYPE.OTHER': 'Kho khác',
  WAREHOUSE_STATUS: 'Trạng thái',
  'WAREHOUSE_STATUS.ACTIVE': 'Đang hoạt động',
  'WAREHOUSE_STATUS.INACTIVE': 'Ngừng hoạt động',
  WAREHOUSE_NOT_DEPEND_ON_BRANCH: 'Không theo cửa hàng',
  WAREHOUSE_COVER: 'Ảnh bìa',
  WAREHOUSE_LOGO: 'Hình ảnh',
  WAREHOUSE_RESOURCES: 'Hình ảnh',
  WAREHOUSE_ESTABLISHED_DATE: 'Ngày thành lập',
  CLOSED_WAREHOUSE_DATE: 'Ngày đóng cửa',
  IS_DEFAULT_WAREHOUSE: 'Mặc định theo cửa hàng',
  IS_DEFAULT_WAREHOUSE_COLUMN_LABEL: 'Mặc định',
  WAREHOUSE_IS_OWNED: 'Kho sở hữu',
  'WAREHOUSE_IS_DEFAULT.true': 'Mặc định',
  'WAREHOUSE_IS_DEFAULT.false': 'Không mặc định',
  CREATED_WAREHOUSE: 'Đã tạo kho thành công',
  UPDATED_WAREHOUSE: 'Đã chỉnh sửa kho thành công',
  DELETED_WAREHOUSE: 'Xóa kho thành công',
  'ERRORS.CAN_NOT_CREATE_WAREHOUSE': 'Tạo kho không thành công',
  'ERRORS.CAN_NOT_UPDATE_WAREHOUSE': 'Chỉnh sửa kho không thành công',
  'ERRORS.CAN_NOT_DELETE_WAREHOUSE': 'Xóa kho không thành công',
  SELECT_SUB_USER_MANAGE_WAREHOUSE: 'Nhân viên quản lí kho',
  WAREHOUSE_DETAIL: 'Chi tiết lưu trữ trong kho ',
  NO_WAREHOUSE_DETAIL_FOUND: 'Chưa có dữ liệu',
  WAREHOUSE_DETAIL_IS_GROUP_BY_VARIATION: 'Hiển thị theo sản phẩm',
  'WAREHOUSE_DETAIL.VARIATION_NAME': 'Sản phẩm',
  'WAREHOUSE_DETAIL.VARIATION_SKU': 'Mã sku',
  'WAREHOUSE_DETAIL.LOT_CODE': 'Mã lô',
  'WAREHOUSE_DETAIL.QUANTITY': 'Số lượng nhập',
  'WAREHOUSE_DETAIL.TOTAL_QUANTITY': 'Tổng lượng nhập',
  'WAREHOUSE_DETAIL.ON_HAND': 'Đang đặt',
  'WAREHOUSE_DETAIL.TOTAL_ON_HAND': 'Tổng đang đặt',
  'WAREHOUSE_DETAIL.SOLD_OUT': 'Đã bán',
  'WAREHOUSE_DETAIL.TOTAL_SOLD_OUT': 'Tổng đã bán',
  'WAREHOUSE_DETAIL.IN_STOCK': 'Còn lại',
  'WAREHOUSE_DETAIL.TOTAL_IN_STOCK': 'Tổng còn lại',
  'WAREHOUSE_DETAIL.HAS_NOT_LOT': 'Không theo lô',
  RETAIL_PRICE_MUST_UPDATE_IN_LOT:
    'Việc chỉnh sửa giá bán theo lô được chỉnh sửa trong lô hàng nhập',
  PROPERTY_LIST: 'Danh sách thuộc tính',
  PROPERTY_NAME: 'Tên thuộc tính',
  PROPERTY_VALUES: 'Tập giá trị',
  EDIT_PROPERTY: 'Sửa thuộc tính',
  CREATE_PROPERTY: 'Tạo thuộc tính',
  DELETE_PROPERTY: 'Xóa thuộc tính',
  NO_PROPERTY_FOUND: 'Không có thuộc tính',
  SELECT_DOMAIN_TYPE: 'Chọn loại giá trị',
  'PROPERTY_DOMAIN_TYPE.INPUT': 'Tùy chỉnh',
  'PROPERTY_DOMAIN_TYPE.SELECT': 'Lựa chọn',
  PROPERTY_DOMAIN_TYPE: 'Loại giá trị',
  HAS_ALL_VARIATION: 'Tất cả',
  HAS_VARIATION: 'Có biến thể này',
  IS_COMBO_ALL: 'Tất cả',
  IS_COMBO: 'Combo',
  SELECT_COMBO_VARIATION: 'Chọn sản phẩm combo',
  SELECT_COMBO: 'Chọn sản phẩm combo',
  SEARCH_PRODUCT_KEYWORD: 'Tìm kiếm theo tên hoặc sku',
  'ERRORS.SEARCH_PRODUCT': 'Có lỗi xảy ra',
  GEN_SKU: 'Sinh SKU',
  NO_PRODUCT_FOUND: 'Không tìm thấy sản phẩm',
  PRODUCT_KEYWORD: 'Tên sản phẩm, sku',
  'WAREHOUSE_DETAIL.COUNT_NUMBER_INPUT_VARIATION': 'Số lần nhập',
  STATUS_IN: 'Trạng thái',
  PRODUCT_PROPERTY: 'Thuộc tính',
  FILTER_VALUES: 'Bộ lọc',
  ADVANCED: 'Nâng cao',
  DELETE_PRODUCT: 'Xóa sản phẩm',
  MINIMUM_VALUE_NUMBER_IS_1: 'Cần tối thiểu 1 giá trị',
  VARIATION_INFO: 'Thông tin biến thể',
  HAS_MULTI_VARIATION: 'Nhiều biến thể',
  SKU: 'SKU',
  RETAIL_PRICE: 'Giá bán',
  'PRODUCT_LIST.IS_QUANTITY_BY_LOT': 'Số lượng theo lô',
  ACCEPT_REMOVE_ATTRIBUTE: 'Xác nhận xóa thuộc tính biến thể',
  REMOVE_ATTRIBUTE: 'Xóa',
  ACCEPT_REMOVE_VALUE: 'Xác nhận xóa giá trị thuộc tính',
  REMOVE_VALUE: 'Xóa',
  MERCHANT_ADMIN: 'Admin',
  RETAIL: 'Giá bán',
  SALE: 'Giá khuyến mãi',
  ORIGINAL: 'Giá nhập',
  FORGET_PASSWORD: 'Quên mật khẩu',
  BACK_TO_LOGIN: 'Về trang đăng nhập',
  SUBMIT: 'Xác nhận',
  FORGET_PASSWORD_SUCCESS: 'Yêu cầu quên mật khẩu thành công. Vui lòng kiểm tra email của bạn',
  FORGET_PASSWORD_ERROR: 'Yêu cầu quên mật khẩu thất bại',
  RESET_PASSWORD_ERROR: 'Cài lại mật khẩu thất bại',
  NEW_PASSWORD: 'Mật khẩu mới',
  NEW_PASSWORD_CONFIRMATION: 'Nhập lại mật khẩu',
  PASSWORD_RENEWAL_SUCCESS: 'Đặt lại mật khẩu thành công',
  "PASSWORD_DON'T_MATCH": 'Mật khẩu không khớp',
  SUCCESS: 'Thành công',
  INPUT_FIELD_INVALID: 'Dữ liệu nhập không hợp lệ',
  LOT_EXCEL_INFO:
    'Nhập thông tin lô hàng sản phẩm từ file Excel. Vui lòng nhập file Excel đúng với format của file dưới đây.',
  DOWNLOAD_LOT_EXCEL_EXAMPLE: 'Tải file Excel mẫu',
  EXCEL_FILE: 'Nhập file Excel',
  LOT_DETAILS_EMPTY: 'Sản phẩm lô hàng rỗng',
  PARTNER: 'Đối tác',
  PARTNER_CATEGORY: 'Danh mục',
  PARTNER_CATEGORY_TYPE_LIST: 'Danh sách loại mục đối tác',
  PARTNER_CATEGORY_TYPE: 'Loại danh mục',
  PARTNER_CATEGORY_TYPE_NAME: 'Tên',
  PARTNER_CATEGORY_TYPE_DOMAIN_TYPE: '',
  NO_PARTNER_CATEGORY_TYPE_FOUND: 'Không tìm thấy loại đối tác',
  PARTNER_CATEGORY_LIST: 'Danh sách danh mục đối tác',
  PARTNER_CATEGORY_NAME: 'Tên',
  CREATE_PARTNER_CATEGORY: 'Tạo danh mục đối tác',
  PARTNER_CATEGORY_CODE: 'Mã',
  PARTNER_CATEGORY_DESCRIPTION: 'Mô tả',
  'PARTNER_CATEGORY_TYPE.SYSTEM': 'Hệ thống',
  'PARTNER_CATEGORY_TYPE.CUSTOM': 'Tùy chỉnh',
  CATEGORY_DESCRIPTION: 'Mô tả',
  EDIT_PARTNER_CATEGORY: 'Sửa danh mục đối tác',
  DELETE_PARTNER_CATEGORY: 'Xóa danh mục đối tác',
  NO_PARTNER_CATEGORY_FOUND: 'Không tìm thấy danh mục đối tác',
  CUSTOMER_SOURCE: 'Nguồn khách hàng',
  CUSTOMER_SOURCE_NAME: 'Tên',
  CUSTOMER_SOURCE_DESCRIPTION: 'Mô tả',
  CUSTOMER_HAS_NO_CUSTOMER_SOURCE: 'Không có nguồn khách hàng',
  EDIT_CUSTOMER_SOURCE: 'Chỉnh sửa nguồn khách hàng',
  DELETE_CUSTOMER_SOURCE: 'Xoá nguồn khách hàng',
  CREATE_CUSTOMER_SOURCE: 'Tạo mới nguồn khách hàng',
  NO_CUSTOMER_SOURCE_FOUND: 'Chưa có nguồn khách hàng được tạo',
  CUSTOMER_SOURCE_LIST: 'Danh sách nguồn khách hàng',
  CUSTOMER_SOURCE_INFORMATION: 'Thông tin',
  'CUSTOMER_SOURCE_STATUS.ACTIVE': 'Kích hoạt',
  'CUSTOMER_SOURCE_STATUS.INACTIVE': 'Dừng hoạt động',
  CREATED_CUSTOMER_SOURCE: 'Tạo nguồn khách hàng thành công',
  UPDATED_CUSTOMER_SOURCE: 'Chỉnh sửa nguồn khách hàng thành công',
  DELETED_CUSTOMER_SOURCE: 'Xoá nguồn khách hàng thành công',
  'ERRORS.COULD_NOT_CREATE_CUSTOMER_SOURCE': 'Tạo nguồn khách hàng thất bại',
  'ERRORS.COULD_NOT_UPDATE_CUSTOMER_SOURCE': 'Chỉnh sửa nguồn khách hàng thất bại',
  'ERRORS.COULD_NOT_DELETE_CUSTOMER_SOURCE': 'Xoá nguồn khách hàng thất bại',
  // PARTNER_CATEGORIES: 'Danh mục',
  SELECT_PARTNER_CATEGORIES: 'Chọn danh mục',
  BUSINESS: 'Doanh nghiệp',
  EXCHANGED: 'Đã giao dịch',
  STRATEGIC_PARTNER: 'Đối tác chiến lược',
  IMPORTANT_STRATEGIC_PARTNER: 'Đối tác chiến lược quan trọng',
  STOP_CONTACT: 'Dừng liên hệ',
  PARTNER_RELATIONSHIP: 'Quan hệ với đối tác',
  COOPERATE_DATE: 'Ngày hợp tác',
  ESTABLISH_DATE: 'Ngày thành lập',
  INFO_HAS_ERROR: 'Thông tin có lỗi',
  REPRESENTATIVE: 'Người đại diện',
  REPRESENTATIVE_NAME: 'Họ tên',
  REPRESENTATIVE_POSITION: 'Chức vụ',
  ID_CARD_NAME: 'Tên trong CMND',
  ID_CARD_NUMBER: 'Số CMND, căn cước, hộ chiếu',
  ID_CARD_PLACE: 'Nơi cấp',
  ID_CARD_ISSUE_DATE: 'Ngày cấp',
  BANK_ACCOUNT_NAME: 'Tên tài khoản',
  BANK_CODE: 'Mã',
  BANK_BRANCH_NAME: 'Chi nhánh',
  BANK_ACCOUNT_NUMBER: 'Số tài khoản',
  BANK_INFO: 'Thông tin ngân hàng',
  LOCATION_INFO: 'Địa chỉ',
  CODE: 'Mã',
  PARTNER_LIST_MENU: 'Danh sách đối tác',
  PARTNER_AVATAR: 'Ảnh đại diện',
  NO_LOT_FOUND: 'Không tìm thấy lô',
  'PARTNER.CUSTOMER': 'Khách hàng',
  'PARTNER.DELIVERY': 'Đơn vị vận chuyển',
  'PARTNER.SUPPLIER': 'Đơn vị cung cấp',
  'PARTNER.WARRANTY': 'Đơn vị bảo hành',
  'PARTNER.MAINTENANCE': 'Đơn vị bảo trì',
  'PARTNER.OTHERS': 'Khác',
  'PARTNER.COOPERATIVE': 'Đơn vị hợp tác',
  'PARTNER.FRANCHISE': 'Đơn vị nhượng quyền',
  'PARTNER.DISTRIBUTION': 'Đơn vị phân phối',
  'PARTNER.PRODUCTION': 'Đơn vị sản xuất',
  'PARTNER.MARKETING': 'Đơn vị tiếp thị',
  'PARTNER.ADVERTISEMENT': 'Đơn vị quảng cáo',
  'PARTNER.MEDIA': 'Đơn vị truyền thông',
  'PARTNER.FINANCE_BANK': 'Tài chính - Ngân hàng',
  'PARTNER.INVESTMENT': 'Nhà đầu tư',
  'PARTNER.SELLER': 'Đại lý bán hàng',
  DASHBOARD: 'Dashboard',
  DASHBOARD_OVERVIEW: 'Báo cáo tổng quan',
  GOODS_MANAGEMENT: 'Quản lý hàng hóa',
  PRODUCT: 'Sản phẩm',
  PRODUCT_COLLECTIONS: 'Bộ sưu tập sản phẩm',
  PRODUCT_CATEGORIES: 'Danh mục sản phẩm',
  PRODUCT_PROPERTIES: 'Thuộc tính sản phẩm',
  PRODUCT_BRANDS: 'Thương hiệu sản phẩm',
  PRODUCT_SUPPLIERS: 'Nhà cung cấp',
  SALE_MANAGEMENT: 'Quản lý bán hàng',
  SALE_ORDERS: 'Đơn hàng',
  SALE_PROMOTIONS: 'Khuyến mãi',
  WAREHOUSE_MANAGEMENT: 'Quản lý kho hàng',
  WAREHOUSE_LOTS: 'Lô hàng',
  WAREHOUSE_WAREHOUSES: 'Kho hàng',
  CUSTOMER_MANAGEMENT: 'Quản lý khách hàng',
  CUSTOMER_CUSTOMERS: 'Khách hàng',
  CUSTOMER_SOURCES: 'Nguồn khách hàng',
  CUSTOMER_CLASSIFICATIONS: 'Phân loại khách hàng',
  CUSTOMER_ACCUMULATION_POINTS: 'Điểm tích lũy',
  STORE_MANAGEMENT: 'Quản lý cửa hàng',
  STORE_BRANCHES: 'Cửa hàng',
  STORE_SUB_USERS: 'Nhân viên',
  PARTNER_MANAGEMENT: 'Quản lý đối tác',
  PARTNER_PARTNERS: 'Đối tác',
  PARTNER_CATEGORIES: 'Danh mục đối tác',
  DOCUMENT_MANAGEMENT: 'Quản lý tài liệu',
  ASSET_MANAGEMENT: 'Quản lý tài sản',
  APP_STORE: 'Kho ứng dụng',
  APP_ASSET_MANAGEMENT: 'Ứng dụng quản lý tài sản',
  APP_SALE: 'Ứng dụng bán hàng',
  SETTING_MANAGEMENT: 'Quản trị',
  SETTING_GENERAL: 'Thông tin chung',
  SETTING_PAYMENT_METHODS: 'Phương thức thanh toán',
  SETTING_SHIPMENT_METHODS: 'Phương thức giao hàng',
  SETTING_ACCOUNT_AND_PRIVILEGE: 'Tài khoản và phân quyền',
  SETTING_ROLE_AND_RIGHTS: 'Vai trò và quyền hạn',
  SETTING_OTHER: 'Cài đặt khác',
  SUPPORT: 'Hỗ trợ',
  SUPPORT_FAQ: 'Câu hỏi thường gặp',
  SUPPORT_GUIDE: 'Hướng dẫn',
  BUSINESS_MANAGEMENT: 'Quản lý kinh doanh',
  INCOME_EXPENSE_MANAGEMENT: 'Quản lý thu chi',
  NO_PRICE_FOUND: 'Không tìm thấy giá',
  BASIC_INFO: 'Thông tin cơ bản',
  REPRESENTATIVE_INFO: 'Thông tin người đại diện',
  ADDRESS_INFO: 'Địa chỉ',
  SETTING_CONFIGURATION: 'Cấu hình giao diện',
  CONFIGURATION_IMAGES: 'Hình ảnh',
  CONFIGURATION_COLORS: 'Màu sắc',
  CREATE_CUSTOMER: 'Tạo khách hàng',
  EDIT_CUSTOMER: 'Chỉnh sửa thông tin khách hàng',
  SAVE_CUSTOMER_BUTTON: 'Lưu khách hàng',
  DELETE_CUSTOMER: 'Xoá khách hàng',
  CUSTOMER_BIRTHDAY: 'Ngày sinh',
  CREATED_CUSTOMER: 'Tạo khách hàng thành công',
  UPDATED_CUSTOMER: 'Chỉnh sửa khách hàng thành công',
  DELETED_CUSTOMER: 'Xoá khách hàng thành công',
  IS_NEW_CUSTOMER: 'Khách hàng mới',
  'CUSTOMER_STATUS.ACTIVE': 'Hoạt động',
  'CUSTOMER_STATUS.INACTIVE': 'Dừng hoạt động',
  'ERRORS.COULD_NOT_CREATE_CUSTOMER': 'Tạo khách hàng thất bại',
  'ERRORS.COULD_NOT_UPDATE_CUSTOMER': 'Chỉnh sửa khách hàng thất bại',
  'ERRORS.COULD_NOT_DELETE_CUSTOMER': 'Xoá khách hàng thất bại',
  CUSTOMER_INFORMATION: 'Thông tin chung',
  EDIT_CUSTOMER_ADDRESS: 'Chỉnh sửa',
  EDIT_CUSTOMER_ADDRESS_DIALOG_TITLE: 'Chỉnh sửa địa chỉ khách hàng',
  ADD_CUSTOMER_ADDRESS: 'Thêm địa chỉ',
  ADD_CUSTOMER_ADDRESS_DIALOG_TITLE: 'Thêm địa chỉ khách hàng',
  SAVE_ADDRESS_BUTTON: 'Lưu địa chỉ',
  DELETE_CUSTOMER_ADDRESS: 'Xoá',
  IS_DEFAULT_ADDRESS: 'Mặc định',
  SET_DEFAULT_ADDRESS: 'Đặt mặc định',
  CREATED_CUSTOMER_ADDRESS: 'Thêm địa chỉ thành công',
  UPDATED_CUSTOMER_ADDRESS: 'Chỉnh sửa địa chỉ thành công',
  DELETED_CUSTOMER_ADDRESS: 'Xoá địa chỉ thành công',
  COULD_NOT_CREATE_CUSTOMER_ADDRESS: 'Thêm địa chỉ thất bại',
  COULD_NOT_UPDATE_CUSTOMER_ADDRESS: 'Chỉnh sửa địa chỉ thất bại',
  COULD_NOT_DELETE_CUSTOMER_ADDRESS: 'Xoá địa chỉ thất bại',
  CUSTOMER_HAS_NOT_ADDRESS: 'Không có địa chỉ nào',
  CONFIGURATION_SETTINGS: 'Cấu hình giao diện',
  ADD_PROPERTY_VALUE: 'Thêm giá trị',
  PROPERTY_VALUE_NAME: 'Giá trị thuộc tính',
  LIST_PROPERTY_VALUE: 'Danh sách giá trị',
  QUANTITY: 'Chiếc',
  PRODUCT_DETAIL_INFO: 'Thông tin chi tiết',
  CANNOT_UPDATE_PARTNER_CATEGORY_SYSTEM: 'Không thể sửa danh mục hệ thống',
  CANNOT_DELETE_PARTNER_CATEGORY_SYSTEM: 'Không thể xóa danh mục hệ thống',
  MINIMUM_CATEGORY_NUMBER_IS_1: 'Cần tối thiểu 1 danh mục',
  SUPPLIER_TYPE: 'Loại nhà cung cấp',
  SUPPLIER_RELATIONSHIP: 'Quan hệ với nhà cung cấp',
  FINSIH_AND_CONTINUE: 'Hoàn thành và tiếp tục',
  GO_TO_MANAGE_PAGE: 'Đi đến trang quản trị',
  PASSWORD_CONFIRMATION: 'Nhập lại mật khẩu',
  HAVE_AN_ACCOUNT: 'Đã có tài khoản?',
  MERCHANT_SUB_DOMAIN: 'Tên miền',
  REGISTER_SUCCESS: 'Đăng ký thành công',
  INVALID_SUB_DOMAIN: 'Tên miền không hợp lệ',
  POS_DOMAIN: 'Tên miền POS',
  WARRANTY_DOMAIN: 'Tên miền tìm kiếm bảo hành',
  ECOMMERCE_WEBSITE: 'Website bán hàng',
  'ECOMMERCE_WEBSITE.ADD': 'Thêm tên miền',
  'ECOMMERCE_WEBSITE.DELETE': 'Xóa tên miền',
  'ECOMMERCE_WEBSITE.DOMAIN': 'Tên miền',
};
