import { put, call, takeLatest } from 'redux-saga/effects';
import { brandActions } from 'store/ducks/BrandDuck';
import BrandServices from 'services/BrandServices';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';

export function* fetchBrandListSaga({ payload }) {
  yield put(brandActions.setLoading(true));
  try {
    const res = yield call(BrandServices.get, payload);
    yield put(brandActions.setBrands(res));
  } catch (error) {
    yield put(brandActions.setError(error));
  }
  yield put(brandActions.setLoading(false));
}

export function* createBrandSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(BrandServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_BRAND' }));
    yield put(brandActions.fetchBrandList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'ERRORS.CAN_NOT_CREATE_BRAND',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateBrandSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(BrandServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_BRAND' }));
    yield put(brandActions.fetchBrandList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_BRAND',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteBrandsSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(BrandServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_BRAND' }));
    yield put(brandActions.fetchBrandList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_BRAND',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchBrandAsync() {
  yield takeLatest(brandActions.fetchBrandList, fetchBrandListSaga);
  yield takeLatest(brandActions.createBrand, createBrandSaga);
  yield takeLatest(brandActions.updateBrand, updateBrandSaga);
  yield takeLatest(brandActions.deleteBrands, deleteBrandsSaga);
}
