import { getSaleInstance } from './apiService';

const PropertyServices = {
  get: ({ page, size, sort } = {}) => {
    return getSaleInstance().get(
      `property?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getSaleInstance().get(`property/${id}`);
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`property/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('property', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(`property/bulk?ids=${ids.join(',')}`);
  },
};

export default PropertyServices;
