import { getCommonInstance } from './apiService';

const countryId = '84';

const LocationServices = {
  getCountries: () => {
    return getCommonInstance().get('country?page=0&size=10&sortBy=name:ASC');
  },
  // getStates: countryId => {
  //   return api
  //     .getInstance({ baseURL })
  //     .get(`/v1/utils/states?country_id=${countryId}`);
  // },
  getProvinces: () => {
    return getCommonInstance().get(
      `province?page=0&size=1000&countryId=${countryId}&sortBy=name:ASC`,
    );
  },
  getDistricts: provinceId => {
    return getCommonInstance().get(
      `district?page=0&size=1000&provinceId=${provinceId}&sortBy=name:ASC`,
    );
  },
  getWards: districtId => {
    return getCommonInstance().get(
      `ward?page=0&size=1000&districtId=${districtId}&sortBy=name:ASC`,
    );
  },
};

export default LocationServices;
