import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  propertyDetail: null,
  filter: '',
};

const actions = {
  setPropertyList: createAction('property/set-property'),
  setLoading: createAction('property/set-loading'),
  setError: createAction('property/set-error'),
  setSort: createAction('property/set-sort'),
  setPageSize: createAction('property/set-page-size'),
  setPage: createAction('property/set-page'),
  fetchPropertyList: createAction('property/fetch-property-list'),
  updateProperty: createAction('property/update-property'),
  createProperty: createAction('property/create-property'),
  deletePropertyList: createAction('property/delete-property-list'),
  fetchPropertyDetail: createAction('property/fetch-property-detail'),
  setPropertyDetail: createAction('property/set-property-detail'),
  setFilter: createAction('product/set-filter'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPropertyList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setPropertyDetail]: (state, action) => {
    state.propertyDetail = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PROPERTY],
    state => state,
  ),
  getPropertyDetail: createSelector(
    state => state[reduxResources.PROPERTY],
    state => state.propertyDetail,
  ),
};

// all actions

export { reducer as propertyReducer, actions as propertyActions, selectors as propertySelectors };
