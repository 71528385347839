import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';
import CustomerAddressServices from 'services/CustomerAddressServices';
import { customerAddressActions } from 'store/ducks/CustomerAddressDuck';
import actionUtils from '../../utils/actionUtils';

// export function* fetchCustomerAddressListSaga({ payload }) {
//   try {
//     const res = yield call(CustomerAddressServices.get, payload);
//     yield put(customerAddressActions.setCustomerAddressList(res));
//   } catch ({ message }) {
//     yield put(
//       alertActions.show({
//         translateId: message ?? 'ERRORS.DEFAULT_ERROR',
//         alertType: alertTypes.ERROR,
//       }),
//     );
//     yield put(customerAddressActions.setLoading(false));
//   }
// }

export function* createCustomerAddressSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    const res = yield call(CustomerAddressServices.create, data);
    actionUtils.doCallback(cb, res);
    yield put(
      alertActions.show({
        translateId: 'CREATED_CUSTOMER_ADDRESS',
        alertType: alertTypes.SUCCESS,
      }),
    );
    // yield put(customerAddressActions.fetchCustomerAddressList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.COULD_NOT_CREATE_CUSTOMER_ADDRESS',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateCustomerAddressSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    const res = yield call(CustomerAddressServices.update, data);
    actionUtils.doCallback(cb, res);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_CUSTOMER_ADDRESS',
        alertType: alertTypes.SUCCESS,
      }),
    );
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_UPDATE_CUSTOMER_ADDRESS',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateDefaultAddress({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerAddressServices.updateDefaultAddress, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_CUSTOMER_ADDRESS',
        alertType: alertTypes.SUCCESS,
      }),
    );
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_UPDATE_CUSTOMER_ADDRESS',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteCustomerAddressSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(CustomerAddressServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_CUSTOMER_ADDRESS',
        alertType: alertTypes.SUCCESS,
      }),
    );
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_DELETE_CUSTOMER_ADDRESS',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchCustomerAddressAsync() {
  yield takeLatest(customerAddressActions.createCustomerAddress, createCustomerAddressSaga);
  yield takeLatest(customerAddressActions.updateCustomerAddress, updateCustomerAddressSaga);
  yield takeLatest(customerAddressActions.updateDefaultAddress, updateDefaultAddress);
  yield takeLatest(customerAddressActions.deleteCustomerAddress, deleteCustomerAddressSaga);
}
