import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  partnerCustomerDetail: null,
  filter: null,
};

const actions = {
  setPartnerCustomerList: createAction('partner-customer/set-partner-customer'),
  setLoading: createAction('partner-customer/set-loading'),
  setError: createAction('partner-customer/set-error'),
  setSort: createAction('partner-customer/set-sort'),
  setPageSize: createAction('partner-customer/set-page-size'),
  setFilter: createAction('partner-customer/set-filter'),
  setPage: createAction('partner-customer/set-page'),
  fetchPartnerCustomerList: createAction('partner-customer/fetch-partner-customer-list'),
  updatePartnerCustomer: createAction('partner-customer/update-partner-customer'),
  createPartnerCustomer: createAction('partner-customer/create-partner-customer'),
  deletePartnerCustomerList: createAction('partner-customer/delete-partner-customer-list'),
  fetchPartnerCustomerDetail: createAction('partner-customer/fetch-partner-customer-detail'),
  setPartnerCustomerDetail: createAction('partner-customer/set-partner-customer-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPartnerCustomerList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setPartnerCustomerDetail]: (state, action) => {
    state.partnerCustomerDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PARTNER_CUSTOMER],
    state => state,
  ),
  getPartnerCustomerDetail: createSelector(
    state => state[reduxResources.PARTNER_CUSTOMER],
    state => state.partnerCustomerDetail,
  ),
};

// all actions

export {
  reducer as partnerCustomerReducer,
  actions as partnerCustomerActions,
  selectors as partnerCustomerSelectors,
};
