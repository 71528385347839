import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  filter: '',
};

const actions = {
  setPartnerCategoryList: createAction('partnerCategory/set-partnerCategory'),
  setLoading: createAction('partnerCategory/set-loading'),
  setError: createAction('partnerCategory/set-error'),
  setSort: createAction('partnerCategory/set-sort'),
  setPageSize: createAction('partnerCategory/set-page-size'),
  setPage: createAction('partnerCategory/set-page'),
  fetchPartnerCategoryList: createAction('partnerCategory/fetch-partnerCategory-list'),
  updatePartnerCategory: createAction('partnerCategory/update-partnerCategory'),
  createPartnerCategory: createAction('partnerCategory/create-partnerCategory'),
  deletePartnerCategoryList: createAction('partnerCategory/delete-partnerCategory-list'),
  setFilter: createAction('product/set-filter'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPartnerCategoryList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PARTNER_CATEGORY],
    state => state,
  ),
};

// all actions

export {
  reducer as partnerCategoryReducer,
  actions as partnerCategoryActions,
  selectors as partnerCategorySelectors,
};
