import { put, call, takeLatest } from 'redux-saga/effects';
import { partnerActions } from 'store/ducks/PartnerDuck';
import { alertTypes } from 'constants/constants';
import { alertActions } from 'store/ducks/AlertDuck';
import PartnerServices from 'services/PartnerServices';
import actionUtils from 'utils/actionUtils';
import { supplierActions } from '../ducks/SupplierDuck';

export function* fetchPartnerListSaga({ payload }) {
  yield put(partnerActions.setLoading(true));
  try {
    const res = yield call(PartnerServices.get, payload);
    yield put(partnerActions.setPartnerList(res));
  } catch (error) {
    yield put(
      alertActions.show({
        translateId: error.message,
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(partnerActions.setLoading(false));
}

export function* createPartnerSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PartnerServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_PARTNER' }));
    yield put(partnerActions.fetchPartnerList({ page: 0 }));
    // TODO: check if categories is supplier
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true, message: error });
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_CREATE_PARTNER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updatePartnerSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PartnerServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_PARTNER' }));
    yield put(partnerActions.fetchPartnerList({ page: 0 }));
    // TODO: check if categories is supplier
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true, message: error });
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PARTNER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deletePartnersSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(PartnerServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_PARTNER' }));
    yield put(partnerActions.fetchPartnerList({ page: 0 }));
    // TODO: check if categories is supplier
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_PARTNER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPartnerAsync() {
  yield takeLatest(partnerActions.fetchPartnerList, fetchPartnerListSaga);
  yield takeLatest(partnerActions.createPartner, createPartnerSaga);
  yield takeLatest(partnerActions.updatePartner, updatePartnerSaga);
  yield takeLatest(partnerActions.deletePartnerList, deletePartnersSaga);
}
