import { filterValueTypes } from '../constants/constants';

export const mapObjectToFilterString = filter => {
  if (!filter) {
    return '';
  }
  const keys = Object.keys(filter);

  return keys.reduce((pr, cur) => {
    if (filter[cur]) {
      return `${pr}&${cur}=${filter[cur]}`;
    }
    return pr;
  }, '');
};

export const mapObjectToFilterStringAcceptFalsy = filter => {
  if (!filter) {
    return '';
  }
  const keys = Object.keys(filter);

  return keys.reduce((pr, cur) => {
    return `${pr}&${cur}=${filter[cur]}`;
  }, '');
};

export const generateFilterStringFromFilterComponent = filter => {
  let filterString = '';

  const mapValueItem = (valueItem, filterItem) => {
    return valueItem instanceof Object
      ? filterItem.dataField
        ? valueItem[filterItem.dataField]
        : valueItem.id
      : valueItem;
  };

  if (filter) {
    filter.forEach(filterItem => {
      let filterItemsString = '';

      if (filterItem.valueType === filterValueTypes.ARRAY) {
        filterItemsString = filterItem.value
          .map(valueItem => mapValueItem(valueItem, filterItem))
          .join(',');
        if (filterItemsString) {
          filterString = `${filterString}&${filterItem.field}=${filterItemsString}`;
        }
      } else if (filterItem.valueType === filterValueTypes.OBJECT) {
        // case property. objectKey is propertyId
        Object.keys(filterItem.value).forEach(objectId => {
          const filterObject = filterItem.value[objectId];
          let filterObjectsString = '';
          if (filterObject.value instanceof Array) {
            filterObjectsString = filterObject.value
              .map(valueItem => mapValueItem(valueItem, filterObject))
              .join('~');
          }
          if (filterObjectsString) {
            filterItemsString = `${filterItemsString}&${filterItem.field}=${objectId}:${filterObjectsString}`;
          }
        });
        if (filterItemsString) {
          filterString = `${filterString}${filterItemsString}`;
        }
      }
    });
  }

  return filterString;
};
