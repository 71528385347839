import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  currentCountryId: 84,
  currentProvinceId: null,
  currentDistrictId: null,
  countries: [],
  states: [],
  provinces: [],
  districts: [],
  wards: [],
};

const actions = {
  fetchCountries: createAction('location/fetchCountries'),
  fetchStates: createAction('location/fetchStates'),
  fetchProvinces: createAction('location/fetchProvinces'),
  fetchDistricts: createAction('location/fetchDistricts'),
  fetchWards: createAction('location/fetchWards'),
  setCountries: createAction('location/setCountries'),
  setStates: createAction('location/setStates'),
  setProvinces: createAction('location/setProvinces'),
  setDistricts: createAction('location/setDistricts'),
  setWards: createAction('location/setWards'),
  // resetStates: createAction('location/resetStates'),
  resetProvinces: createAction('location/resetProvinces'),
  resetDistricts: createAction('location/resetDistricts'),
  resetWards: createAction('location/resetWards'),
};

const locationReducer = createReducer(initialState, {
  [actions.setCountries]: (state, action) => ({
    ...state,
    countries: action.payload,
  }),
  [actions.setStates]: (state, action) => ({
    ...state,
    states: action.payload,
  }),
  [actions.setProvinces]: (state, action) => ({
    ...state,
    provinces: action.payload,
  }),
  [actions.setDistricts]: (state, action) => ({
    ...state,
    districts: action.payload,
  }),
  [actions.setWards]: (state, action) => ({
    ...state,
    wards: action.payload,
  }),
  [actions.resetStates]: state => ({
    ...state,
    states: [],
  }),
  [actions.resetProvinces]: state => ({
    ...state,
    provinces: [],
    districts: [],
    wards: [],
  }),
  [actions.resetDistricts]: state => ({
    ...state,
    districts: [],
    wards: [],
  }),
  [actions.resetWards]: state => ({
    ...state,
    wards: [],
  }),
});

const getLocationState = state => state[reduxResources.LOCATION];

const selectors = {
  getState: createSelector(getLocationState, state => state),
  getCountries: createSelector(getLocationState, state => state.countries),
  getStates: createSelector(getLocationState, state => state.states),
  getDistricts: createSelector(getLocationState, state => state.districts),
  getProvinces: createSelector(getLocationState, state => state.provinces),
  getWards: createSelector(getLocationState, state => state.wards),
  getLoadedIds: createSelector(
    getLocationState,
    ({ currentCountryId, currentProvinceId, currentDistrictId }) => ({
      currentCountryId,
      currentProvinceId,
      currentDistrictId,
    }),
  ),
};

export { actions as locationActions, locationReducer, selectors as locationSelectors };
