import { createAction, createSelector, createReducer } from '@reduxjs/toolkit';
import { reduxResources } from '../../constants/constants';

const initialState = {
  decodedToken: null,
};

const actions = {
  login: createAction('auth/login'),
  register: createAction('auth/register'),
  requestPassword: createAction('auth/requestPassword'),
  renewPassword: createAction('auth/renewPassword'),
  logout: createAction('auth/logout'),
  forgetPassword: createAction('auth/forgetPassword'),
  setDecodedToken: createAction('auth/setDecodedToken'),
};

const reducer = createReducer(initialState, {
  [actions.setDecodedToken]: (state, action) => {
    state.decodedToken = action.payload;
  },
  [actions.logout]: state => {
    state.decodedToken = null;
    localStorage.removeItem('ACCESS_TOKEN');
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.AUTH],
    state => state,
  ),
};

export { actions as authActions, selectors as authSelectors, reducer as authReducer };
