import React from 'react';

const Spacing = ({ vertical = 1, horizontal = 1, custom, children }) => {
  return (
    <div
      style={
        custom ?? {
          height: vertical * 8,
          width: horizontal * 8,
        }
      }
    >
      {children}
    </div>
  );
};

export default Spacing;
