import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources, themeTypes } from 'constants/constants';
import blue from '@material-ui/core/colors/blue';

const initialState = { type: themeTypes.LIGHT, primaryColor: blue, secondaryColor: null };

const actions = {
  toggleTheme: createAction('theme/toggle-theme-type'),
  setPrimaryColor: createAction('theme/set-primary-color'),
  setSecondaryColor: createAction('theme/set-secondary-color'),
};

const themeReducer = createReducer(initialState, {
  [actions.toggleTheme]: state => {
    if (state.type === themeTypes.LIGHT) {
      localStorage.setItem('themeType', themeTypes.DARK);
      state.type = themeTypes.DARK;
    } else {
      localStorage.setItem('themeType', themeTypes.LIGHT);
      state.type = themeTypes.LIGHT;
    }
  },
  [actions.setPrimaryColor]: (state, { payload }) => {
    state.primaryColor = payload;
    localStorage.setItem('primaryColor', JSON.stringify(payload));
  },
  [actions.setSecondaryColor]: (state, { payload }) => {
    state.secondaryColor = payload;
    localStorage.setItem('secondaryColor', JSON.stringify(payload));
  },
});

const selectors = {
  getTheme: createSelector(
    state => state[reduxResources.THEME],
    theme => theme,
  ),
};

export { actions as themeActions, themeReducer, selectors as themeSelectors };
