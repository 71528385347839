import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BackIcon from '@material-ui/icons/KeyboardBackspace';

import Spacing from 'components/common/Spacing';
import { authActions } from 'store/ducks/AuthDuck';
import { makeStyles, useTheme } from '@material-ui/styles';
import LanguageSelector from 'components/common/LanguageSelector';

// import logo from 'assets/images/logo.png';
import { push, replace } from 'connected-react-router';
import { alertTypes, RouteStrings } from 'constants/constants';
import { useLocation } from 'react-router-dom';
import Page from 'components/common/Page';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { getParamFromSearch } from '../../../utils/urlUtil';
import { alertActions } from '../../../store/ducks/AlertDuck';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
    },
    position: 'relative',
  },
  paperContainer: {
    padding: theme.spacing(6, 4),
    maxWidth: 400,
    minWidth: 400,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none !important',
    },
  },
  languageContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    // backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },
  logoContainer: {
    height: 56,
    objectFit: 'contain',
  },
}));

const PasswordRenewalPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { search } = useLocation();
  const code = getParamFromSearch(search, 'code');

  // const logoUrl = logo;

  const handleResetPassword = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      authActions.renewPassword({
        data: { ...values, code },
        callback: ({ hasError } = {}) => {
          setSubmitting(false);
          if (!hasError) {
            dispatch(
              alertActions.show({
                translateId: 'PASSWORD_RENEWAL_SUCCESS',
                alertType: alertTypes.SUCCESS,
              }),
            );
            dispatch(replace(RouteStrings.login));
          }
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .ensure()
        .required('THIS_FIELD_IS_REQUIRED'),
      newPasswordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t("PASSWORD_DON'T_MATCH"))
        .required(t('THIS_FIELD_IS_REQUIRED')),
    }),
    onSubmit: handleResetPassword,
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = formik;

  const handleBackToLogin = () => {
    dispatch(push(RouteStrings.login));
  };

  const textFieldCommonProps = (field, translationName) => {
    return {
      name: field,
      value: values[field],
      variant: 'outlined',
      fullWidth: true,
      label: t(translationName),
      onChange: handleChange,
      error: touched[field] && errors[field],
      helperText: errors[field] && touched[field] ? t(errors[field]) : '',
      size: 'small',
      onBlur: handleBlur,
    };
  };

  const [visiblePassword, setVisiblePassword] = useState(false);

  return (
    <Page className={classes.root} title={`${t('RESET_PASSWORD')}`}>
      <div className={classes.languageContainer}>
        <LanguageSelector color={theme.palette.primary} />
      </div>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paperContainer}>
          <Spacing vertical={2} />
          <Typography align="center" variant="h5">
            {t('RESET_PASSWORD')}
          </Typography>
          <Spacing vertical={4} />
          <TextField
            {...textFieldCommonProps('newPassword', 'NEW_PASSWORD')}
            type={visiblePassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  tabIndex={-1}
                  disableRipple
                  className="outline-none"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  size="small"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
          <Spacing vertical={4} />
          <TextField
            {...textFieldCommonProps('newPasswordConfirmation', 'NEW_PASSWORD_CONFIRMATION')}
            type="password"
            required
          />
          <Spacing vertical={4} />
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            {isSubmitting && (
              <>
                <CircularProgress size={16} />
                <Spacing horizontal={2} />
              </>
            )}
            {t('SUBMIT')}
          </Button>
          <Spacing vertical={3} />
          <Button
            variant="text"
            color="primary"
            onClick={handleBackToLogin}
            startIcon={<BackIcon />}
          >
            {t('BACK_TO_LOGIN')}
          </Button>
        </Paper>
      </form>
    </Page>
  );
};

export default PasswordRenewalPage;
