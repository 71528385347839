import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from '../../constants/constants';

const initialState = {
  data: null,
  page: 0,
  size: 10,
  filter: {},
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
  CustomerInfo: null,
};

const actions = {
  setCustomerList: createAction('customer/set-customer'),
  setLoading: createAction('customer/set-loading'),
  setError: createAction('customer/set-error'),
  setPageSize: createAction('customer/set-page-size'),
  setPage: createAction('customer/set-page'),
  setSort: createAction('customer/set-sort'),
  setFilter: createAction('customer/set-filter'),
  updateCustomer: createAction('customer/update-customer'),
  createCustomer: createAction('customer/create-customer'),
  deleteCustomerList: createAction('customer/delete-customer-list'),
  fetchCustomerList: createAction('customer/fetch-customer-list'),
  fetchCustomerInfo: createAction('customer/fetch-customer-info'),
  setCustomerInfo: createAction('customer/set-customer-info'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setCustomerList]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.data = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setCustomerInfo]: (state, action) => {
    state.CustomerInfo = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.CUSTOMER],
    state => state,
  ),
  getCustomerInfo: createSelector(
    state => state[reduxResources.CUSTOMER],
    state => state.CustomerInfo,
  ),
};

// all actions

export { reducer as customerReducer, actions as customerActions, selectors as customerSelectors };
