import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import { CustomerSourceServices } from 'services/CustomerSourceServices';
import { customerSourceActions } from 'store/ducks/CustomerSourceDuck';

export function* fetchCustomerSourceListSaga({ payload }) {
  try {
    const res = yield call(CustomerSourceServices.get, payload);
    yield put(customerSourceActions.setCustomerSourceList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    yield put(customerSourceActions.setLoading(false));
  }
}

export function* fetchCustomerSourceInfoSaga({ payload }) {
  try {
    const res = yield call(CustomerSourceServices.getOne, payload);

    const { ...rest } = res;
    yield put(
      customerSourceActions.setCustomerSourceInfo({
        ...rest,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createCustomerSourceSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerSourceServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_CUSTOMER_SOURCE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerSourceActions.fetchCustomerSourceList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.COULD_NOT_CREATE_CUSTOMER_SOURCE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateCustomerSourceSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(CustomerSourceServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_CUSTOMER_SOURCE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerSourceActions.fetchCustomerSourceList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_UPDATE_CUSTOMER_SOURCE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteCustomerSourceListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(CustomerSourceServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_CUSTOMER_SOURCE',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(customerSourceActions.fetchCustomerSourceList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.COULD_NOT_DELETE_CUSTOMER_SOURCE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchCustomerSourceAsync() {
  yield takeLatest(customerSourceActions.fetchCustomerSourceList, fetchCustomerSourceListSaga);
  yield takeLatest(customerSourceActions.fetchCustomerSourceInfo, fetchCustomerSourceInfoSaga);
  yield takeLatest(customerSourceActions.createCustomerSource, createCustomerSourceSaga);
  yield takeLatest(customerSourceActions.updateCustomerSource, updateCustomerSourceSaga);
  yield takeLatest(customerSourceActions.deleteCustomerSourceList, deleteCustomerSourceListSaga);
}
