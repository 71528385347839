export default {
  // Landing page
  LOGIN: 'Login',
  REGISTER: 'Sign up',
  FREE_REGISTER: 'Sign up free',
  BUILD_APP: 'Build app',
  PUBLISH_APP: 'Publish app',
  BUILD_PROGRESS: 'Build progress',
  VI: 'Tiếng Việt',
  EN: 'English',
  LANDING_PAGE_FEATURES: 'FEATURES',
  PASSWORD: 'Password',
  'PASSWORD.NOT_EMPTY': 'Password is required',
  'EMAIL.NOT_EMPTY': 'Email is required',
  DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
  CONTACT_US: 'Contact us',
  //
  CREATE_BRAND: 'Add new brand',
  EDIT_BRAND: 'Edit brand',
  BRAND_LIST: 'Brand list',
  DESCRIPTION: 'Description',
  BRAND_NAME: 'Brand name',
  BRAND_LOGO: 'Logo',
  BRAND_COVER: 'Brand cover',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  THIS_FIELD_IS_REQUIRED: 'This field is required',
  ERROR_UPLOAD_IMAGE: 'Error when upload image',
  DELETE_BRAND: 'Delete brand',
  DELETE: 'Delete',
  NO_BRAND_FOUND: 'No brand was created',
  OK: 'OK',
  //
  APP: 'Application',
  MERCHANT: 'Merchant',
  CUSTOMER_LIST: 'Customer list',
  ORDERS: 'Orders',
  MERCHANT_INFORMATION: 'Merchant information',
  BRAND: 'Brand',
  BRANDS: 'Brands',
  CATEGORY: 'Category',
  PRODUCTS: 'Product',
  NEWS: 'News',
  PROMOTION: 'Promotion',
  BANNER: 'Banner',
  SLIDER: 'Slider',
  ACC_POINT: 'Accumulation point',
  BRANCHES: 'Branches',
  STAFF: 'Staff',
  APP_INFORMATION: 'App information',
  DESIGN: 'Design',
  CONFIGURATION: 'Configuration',
  HOME: 'Home',
  MESSAGES: 'Messages',
  CART: 'Cart',
  ADDRESS_BOOK: 'Address book',
  PROMOTIONS: 'Promotions',
  CATEGORIES: 'Categories',
  CONTACT: 'Contact',
  RATINGS: 'Ratings',
  TRY_APP: 'Try app',
  ACCOUNT: 'Account',
  'ERRORS.CAN_NOT_CREATE_BRAND': 'Không thể tạo thương hiệu',
  'ERRORS.CAN_NOT_UPDATE_BRAND': 'Không thể chỉnh sửa thương hiệu',
  'ERRORS.CAN_NOT_DELETE_BRAND': 'Không thể xoá thương hiệu',
  'ERRORS.CAN_NOT_UPDATE_CATEGORY': 'Không thể chỉnh sửa danh mục',
  'ERRORS.CAN_NOT_DELETE_CATEGORY': 'Không thể xoá danh mục',
  UPDATED_BRAND: 'Đã chỉnh sửa thương hiệu!',
  CREATED_BRAND: 'Đã thương hiệu thành công!',
  DELETED_BRAND: 'Đã xoá thương hiệu thành công!',
  CREATED_AT: 'Created at',
  NO_CATEGORY_FOUND: 'No category found!',
  CREATE_CATEGORY: 'Tạo danh mục mới',
  EDIT_CATEGORY: 'Chỉnh sửa danh mục',
  DELETE_CATEGORY: 'Xoá danh mục',
  CATEGORY_LOGO: 'Hình ảnh',
  CATEGORY_NAME: 'Category name',
  STATUS: 'Status',
  CATEGORY_PARENT: "Category's parent",
  NULL: 'NULL',
  UPDATED_CATEGORY: 'Đã chỉnh sửa danh mục',
  LOGIN_SUCCESSFUL: 'Đăng nhập thành công',
  CREATED_CATEGORY: 'Đã tạo danh mục thành công',
  DELETED_CATEGORY: 'Đã xoá danh mục thành công',
  NEXT_PAGE: 'Trang tiếp theo',
  PREVIOUS_PAGE: 'Trang trước',
  OF: 'của',
  ROWS_PER_PAGE: 'Số hàng trên 1 trang',
  SEARCH: 'Tìm kiếm',
  PRINT: 'In',
  VIEW_COLUMNS: 'Xem cột',
  FILTER_TABLE: 'Lọc bảng',
  ALL: 'Tất cả',
  FILTERS: 'Bộ lọc',
  RESET: 'Đặt lại',
  SHOW_COLUMNS: 'Hiển thị cột',
  SHOW_HIDE_TABLE_COLUMNS: 'Show/hide table columns',
  ROWS_TO_DELETE: 'Rows to delete',
  DELETE_SELECTED_ROWS: 'Delete selected rows',
  CATEGORY_LIST: 'Category list',
  BRANCH_LIST: 'Branch list',
  LOGOUT: 'Sign out',
  AMOUNT_OF_MONEY_TYPE: 'Theo giá trị đơn hàng',
  'AMOUNT_OF_MONEY_TYPE.DESCRIPTION':
    'Tích điểm theo giá trị đơn hàng. Ví dụ: Giá trị nhập là 20.000 thì số điểm tích được của khách hàng là (Giá trị đơn hàng / 20000)',
  PERCENT_TYPE: 'Theo phần trăm',
  'PERCENT_TYPE.DESCRIPTION':
    'Tính điểm theo phần trăm giá trị đơn hàng. Ví dụ: Giá trị nhập là 50 thì số điểm tích được của khách hàng là (50 % x Giá trị đơn hàng)',
  QUANTITY_TYPE: 'Theo số lượng sản phẩm',
  'QUANTITY_TYPE.DESCRIPTION':
    'Tích điểm theo số lượng sản phẩm có trong đơn hàng. Ví dụ: Giá trị nhập là 3 thì số điểm tích được của khách hàng là (Số lượng sản phẩm trong đơn hàng / 3)',
  ACCUMULATION_POINT_VALUE: 'Giá trị',
  ACCUMULATION_STATUS: 'Trạng thái',
  ACCUMULATION_TYPE: 'Loại tích điểm',
  'ACCUMULATION_STATUS.ACTIVE': 'Kích hoạt',
  'ACCUMULATION_STATUS.INACTIVE': 'Dừng hoạt động',
  THIS_FIELD_MUST_BE_GREATER_THAN: 'Trường này phải lớn hơn',
  EDIT_ACCUMULATION_POINT: 'Chỉnh tích điểm',
  EDITED_ACCUMULATION_POINT: 'Đã chỉnh sửa tích điểm thành công!',
  CREATE_ACCUMULATION_POINT: 'Tạo tích điểm',
  CREATED_ACCUMULATION_POINT: 'Đã tạo tích điểm thành công!',
  UPDATE_ACCUMULATION_POINT: 'Chỉnh sửa tích điểm',
  UPDATED_ACCUMULATION_POINT: 'Chỉnh sửa tích điểm thành công!',
  ACCUMULATION_POINT_LIST: 'Danh sách tích điểm',
  ACCUMULATION_POINT: 'Tích điểm',
  DELETE_ACCUMULATION_POINT: 'Xoá tích điểm',
  DELETED_ACCUMULATION_POINT: 'Đã xoá tích điểm thành công',
  NO_ACCUMULATION_POINT_FOUND: 'Chưa có tích điểm được tạo',
  'ERRORS.CAN_NOT_CREATE_ACCUMULATION_POINT': 'Đã có lỗi khi tạo tích điểm!',
  SUB_USERS: 'Employee',
  BRANCH_STATUS: 'Trạng thái chi nhánh',
  'STATUS.ACTIVE': 'Active',
  'STATUS.INACTIVE': 'Inactive',
  IS_REQUIRED: 'is required',
  NAME: 'Name',
  CONTACT_NAME: 'Contact name',
  CONTACT_EMAIL: 'Contact email',
  CONTACT_PHONE: 'Contact phone',
  BRANCH_NAME: 'Branch name',
  CREATE_BRANCH: 'Add new branch',
  EDIT_BRANCH: 'Edit branch',
  INVALID_EMAIL: 'Invalid email format',
  NO_BRANCH_FOUND: 'No branch found',
  PROVINCE: 'Province/City',
  DISTRICT: 'District',
  WARD: 'Ward',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  ADDRESS: 'Address',
  CREATED_BRANCH: 'Added new brand successfully!',
  DELETE_BRANCH: 'Delete branch',
  DELETED_BRANCH: 'Branch(es) deleted!',
  'ERRORS.CAN_NOT_CREATE_BRANCH': 'Error when creating branch',
  '{merchant.branch.name.duplicated}': 'Branch name is duplicated',
  WAREHOUSE: 'Warehouse',
};
