/* eslint-disable react/no-multi-comp */
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';

import { NavigationListItem } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  list: {
    padding: 0,
  },
}));

const NavigationList = props => {
  const { pages, classes, ...rest } = props;
  const { pathname } = useLocation();

  return (
    <List className={classes.list}>
      {pages.reduce(
        // eslint-disable-next-line no-use-before-define
        (items, page) => reduceChildRoutes({ items, classes, page, ...rest, pathname }),
        [],
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = props => {
  const { router, items, classes, page, depth, pathname, miniNavBar, onEnlargerNavBar } = props;

  if (page.children) {
    const open = matchPath(pathname, {
      path: page.href,
      exact: false,
    });

    let className = null;
    if (depth === 0) {
      className = classes.item;
    } else if (depth === 1) {
      className = classes.subItem;
    } else if (depth === 2) {
      className = classes.subSubItem;
    }

    items.push(
      <NavigationListItem
        className={className}
        depth={depth}
        icon={page.icon}
        href={page.href}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        childrenRoute={page.children}
        miniNavBar={miniNavBar}
        onEnlargerNavBar={onEnlargerNavBar}
      >
        <NavigationList classes={classes} depth={depth + 1} pages={page.children} router={router} />
      </NavigationListItem>,
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
        miniNavBar={miniNavBar}
        onEnlargerNavBar={onEnlargerNavBar}
      />,
    );
  }

  return items;
};

const Navigation = props => {
  // const { t } = useTranslation();

  const {
    title,
    pages,
    className,
    component: Component,
    miniNavBar,
    onEnlargerNavBar,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {/* {title && (
        <Typography variant="overline" style={{ color: '#fff' }}>
          {t(title)}
        </Typography>
      )} */}
      <NavigationList
        classes={classes}
        depth={0}
        pages={pages}
        miniNavBar={miniNavBar}
        onEnlargerNavBar={onEnlargerNavBar}
      />
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

Navigation.defaultProps = {
  component: 'nav',
};

export default Navigation;
