const RouteStrings = {
  home: '/',
  login: '/login',
  register: '/register',
  registerForDev: '/register-for-dev-xyz-789',
  logout: '/',
  tenant: '/u',
  forget_password: '/forget-password',
  password_renewal: '/password-renewal',
  dashboard: '/dashboard',
  dashboard_overview: '/dashboard/overview',
  goods_management: '/goods',
  goods_products: '/goods/products',
  goods_product_collections: '/goods/product-collections',
  goods_categories: '/goods/categories',
  goods_properties: '/goods/properties',
  goods_brands: '/goods/brands',
  goods_suppliers: '/goods/suppliers',
  sale_management: '/sale',
  sale_orders: '/sale/orders',
  sale_promotions: '/sale/promotions',
  warehouse_management: '/warehouse',
  warehouse_lots: '/warehouse/lots',
  warehouse_warehouses: '/warehouse/warehouses',
  customer_management: '/customer',
  customer_customers: '/customer/customers',
  customer_sources: '/customer/sources',
  customer_classifications: '/customer/classifications',
  customer_accumulation_points: '/customer/accumulation-points',
  store_management: '/store',
  store_branches: '/store/branches',
  store_sub_users: '/store/sub-users',
  partner_management: '/partner',
  partner_partners: '/partner/partners',
  partner_categories: '/partner/categories',
  document_management: '/document',
  asset_management: '/asset',
  app_store: '/app',
  app_asset_management: '/app/asset',
  app_sale: '/app/sale',
  setting_management: '/setting',
  setting_general: '/setting/general',
  setting_configuration: '/setting/configuration',
  setting_payment_methods: '/setting/payment-methods',
  setting_shipment_methods: '/setting/shipment-methods',
  setting_account_and_privilege: '/setting/account-and-privilege',
  setting_role_and_rights: '/setting/role-and-rights',
  setting_other: '/setting/other',
  support: '/support',
  support_faq: '/support/faq',
  support_guide: '/support/guide',
  business_management: '/business',
  income_expense_management: '/income-expense',
};

export default RouteStrings;
