import { mapObjectToFilterStringAcceptFalsy } from 'utils/filterUtils';
import { getSaleInstance } from './apiService';

const PriceServices = {
  fetchPriceList: ({ page, size, sort, filter }) => {
    const filterString = mapObjectToFilterStringAcceptFalsy(filter);

    return getSaleInstance().get(
      `price?sortBy=${sort ?? 'startDate:DESC'}&page=${page ?? 0}&size=${size ??
        10}${filterString ?? ''}`,
    );
  },
  updatePrices: data => {
    return getSaleInstance().put(`price/bulk`, data);
  },
};

export default PriceServices;
