import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  CustomerSourceInfo: null,
  filter: null,
};

const actions = {
  setCustomerSourceList: createAction('customer-source/set-customer-source'),
  setLoading: createAction('customer-source/set-loading'),
  setError: createAction('customer-source/set-error'),
  setSort: createAction('customer-source/set-sort'),
  setPageSize: createAction('customer-source/set-page-size'),
  setFilter: createAction('customer-source/set-filter'),
  setPage: createAction('customer-source/set-page'),
  fetchCustomerSourceList: createAction('customer-source/fetch-customer-source-list'),
  updateCustomerSource: createAction('customer-source/update-customer-source'),
  createCustomerSource: createAction('customer-source/create-customer-source'),
  deleteCustomerSourceList: createAction('customer-source/delete-customer-source-list'),
  fetchCustomerSourceInfo: createAction('customer-source/fetch-customer-source-info'),
  fetchCustomerSourceDetail: createAction('customer-source/fetch-customer-source-detail'),
  setCustomerSourceInfo: createAction('customer-source/set-customer-source-info'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setCustomerSourceList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setCustomerSourceInfo]: (state, action) => {
    state.CustomerSourceInfo = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.CUSTOMER_SOURCE],
    state => state,
  ),
  getCustomerSourceInfo: createSelector(
    state => state[reduxResources.CUSTOMER_SOURCE],
    state => state.CustomerSourceInfo,
  ),
};

// all actions

export {
  reducer as customerSourceReducer,
  actions as customerSourceActions,
  selectors as customerSourceSelectors,
};
