import axios from 'axios';
import { configConstants } from 'constants/constants';
import { v4 as uuidv4 } from 'uuid';

const requestIdHeader = 'X-Request-Id';

const setInterceptorResponse = instance => {
  instance.interceptors.response.use(
    res => {
      // eslint-disable-next-line no-console
      if (!res.data.success) {
        const error = new Error();
        error.code = res.data.code;
        error.message = res.data.message;
        throw error;
      }
      return res.data.payload;
    },
    err => {
      // eslint-disable-next-line no-console
      console.log(err.response?.status, err.response?.data);
      if (err.response?.data) {
        const error = new Error();
        error.code = err.response?.data.code;
        error.message = err.response?.data.message;
        throw error;
      }
      throw err;
    },
  );
};

export const api = {
  getInstance: (options = {}) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      ...(options?.headers ?? {}),
      [requestIdHeader]: uuidv4(),
    };

    const instance = axios.create({
      baseURL: options?.baseURL ?? configConstants.SALE_SERVICE_URL,
      headers,
      timeout: 10000,
    });
    setInterceptorResponse(instance);
    return instance;
  },
  getUnAuthInstance: (options = {}) => {
    const headers = {
      ...(options?.headers ?? {}),
      [requestIdHeader]: uuidv4(),
    };
    const instance = axios.create({
      baseURL: options?.baseURL ?? configConstants.SALE_SERVICE_URL,
      headers,
      timeout: 10000,
    });
    setInterceptorResponse(instance);
    return instance;
  },
};

export const getSaleInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }
  return instance({
    baseURL: configConstants.SALE_SERVICE_URL,
    headers,
  });
};

export const getCommonInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }
  return instance({
    baseURL: configConstants.COMMON_SERVICE_URL,
    headers,
  });
};

export const getAccountInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }
  return instance({
    baseURL: configConstants.ACCOUNT_SERVICE_URL,
    headers,
  });
};

export const getMerchantInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }

  return instance({
    baseURL: configConstants.MERCHANT_SERVICE_URL,
    headers,
  });
};

export const getWarehouseInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }

  return instance({
    baseURL: configConstants.WAREHOUSE_SERVICE_URL,
    headers,
  });
};

export const getAppInstance = ({ headers, usePublicApi = false } = {}) => {
  let instance = api.getInstance;
  if (usePublicApi) {
    instance = api.getUnAuthInstance;
  }

  return instance({
    baseURL: configConstants.APP_SERVICE_URL,
    headers,
  });
};
