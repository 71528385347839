import { put, takeLatest, call } from 'redux-saga/effects';
import { authActions } from 'store/ducks/AuthDuck';
import { alertTypes } from 'constants/constants';
import { alertActions } from 'store/ducks/AlertDuck';
import AuthServices from 'services/AuthServices';
import actionUtils from 'utils/actionUtils';
import { themeActions } from 'store/ducks/ThemeDuck';
import { blue } from '@material-ui/core/colors';
import { merchantActions } from 'store/ducks/MerchantDuck';
import { decodeTokenToObject } from '../../utils/tokenUtils';

export function* loginAsync({ payload }) {
  const { data, callback } = payload;

  try {
    const { token } = yield call(AuthServices.login, data);
    localStorage.setItem('ACCESS_TOKEN', token);
    yield put(authActions.setDecodedToken(decodeTokenToObject()));
    actionUtils.doCallback(callback);
  } catch ({ message }) {
    actionUtils.doCallback(callback, { hasError: true, message });
    yield put(
      alertActions.show({
        translateId: message ?? 'LOGIN_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* registerAsync({ payload }) {
  const { data, callback } = payload;

  try {
    yield call(AuthServices.register, data);
    actionUtils.doCallback(callback);
  } catch ({ message }) {
    actionUtils.doCallback(callback, { hasError: true, message });
    yield put(
      alertActions.show({
        translateId: message ?? 'REGISTER_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* forgetPasswordAsync({ payload }) {
  const { data, callback } = payload;
  try {
    yield call(AuthServices.forgetPassword, data);
    actionUtils.doCallback(callback);
  } catch ({ message }) {
    actionUtils.doCallback(callback, { hasError: true, message });
    yield put(
      alertActions.show({
        translateId: message ?? 'FORGET_PASSWORD_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* renewPasswordAsync({ payload }) {
  const { data, callback } = payload;
  try {
    yield call(AuthServices.renewPassword, data);
    actionUtils.doCallback(callback);
  } catch ({ message }) {
    actionUtils.doCallback(callback, { hasError: true, message });
    yield put(
      alertActions.show({
        translateId: message ?? 'RESET_PASSWORD_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* logoutAsync() {
  yield put(themeActions.setPrimaryColor(blue));
  yield put(themeActions.setSecondaryColor(null));
  yield put(merchantActions.setPublicMerchantDetail(null));
}

export default function* watchAuthenAsync() {
  yield takeLatest(authActions.login, loginAsync);
  yield takeLatest(authActions.register, registerAsync);
  yield takeLatest(authActions.logout, logoutAsync);
  yield takeLatest(authActions.forgetPassword, forgetPasswordAsync);
  yield takeLatest(authActions.renewPassword, renewPasswordAsync);
}
