import Moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import PropertyServices from 'services/PropertyServices';
import { propertyActions } from 'store/ducks/PropertyDuck';

export function* fetchPropertyListSaga({ payload }) {
  yield put(propertyActions.setLoading(true));
  try {
    const res = yield call(PropertyServices.get, payload);
    yield put(propertyActions.setPropertyList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(propertyActions.setLoading(false));
}

export function* fetchPropertyDetailSaga({ payload }) {
  try {
    const res = yield call(PropertyServices.getOne, payload);

    const {
      brandsApplied,
      categoriesApplied,
      productsApplied,
      variationsApplied,
      giftBrands,
      giftCategories,
      giftProducts,
      giftVariations,
      startDate,
      endDate,
      ...rest
    } = res;
    yield put(
      propertyActions.setPropertyDetail({
        ...rest,
        startDate: startDate ? new Moment(startDate) : null,
        endDate: endDate ? new Moment(endDate) : null,
        brandsApplied:
          brandsApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        categoriesApplied:
          categoriesApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        productsApplied:
          productsApplied?.map(({ id, name }) => ({
            id,
            name,
          })) ?? [],
        variationsApplied,
        giftBrands:
          giftBrands?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftCategories:
          giftCategories?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftProducts:
          giftProducts?.map(({ id, name }) => ({
            id,
            name,
          })) ?? [],
        giftVariations,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createPropertySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PropertyServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_PROPERTY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(propertyActions.fetchPropertyList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_PROPERTY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updatePropertySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PropertyServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_PROPERTY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(propertyActions.fetchPropertyList());
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PROPERTY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deletePropertyListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(PropertyServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_PROPERTY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(propertyActions.fetchPropertyList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_PROPERTY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPropertyAsync() {
  yield takeLatest(propertyActions.fetchPropertyList, fetchPropertyListSaga);

  yield takeLatest(propertyActions.fetchPropertyDetail, fetchPropertyDetailSaga);

  yield takeLatest(propertyActions.createProperty, createPropertySaga);
  yield takeLatest(propertyActions.updateProperty, updatePropertySaga);
  yield takeLatest(propertyActions.deletePropertyList, deletePropertyListSaga);
}
