import { getMerchantInstance } from './apiService';
import { mapObjectToFilterString } from '../utils/filterUtils';

const BranchServices = {
  get: ({ page, size, sort, filter }) => {
    if (typeof filter !== 'string') {
      filter = mapObjectToFilterString(filter);
    }
    return getMerchantInstance().get(
      `branch?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}${filter ??
        ''}`,
    );
  },
  getOne: ({ id }) => {
    return getMerchantInstance().get(`branch/${id}`);
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`branch/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('branch', payload);
  },
  delete: ids => {
    return getMerchantInstance().delete(`branch/bulk?ids=${ids.join(',')}`);
  },
};

export default BranchServices;
