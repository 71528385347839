import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';

// import logo from 'assets/images/logo.png';
import LanguageSelector from 'components/common/LanguageSelector';
import Navigation from 'components/common/Navigation';
import navigationConfig from './navigationConfig';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: '#212121',
    borderRadius: 0,
  },
  content: {},
  name: {
    marginTop: theme.spacing(1),
  },
  navigation: {
    marginTop: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, miniNavBar, onEnlargerNavBar, className, ...rest } = props;

  const classes = useStyles();

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.logoContainer}>
        <Logo showMerchantName={!miniNavBar} />
      </div>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
            miniNavBar={miniNavBar && !openMobile}
            onEnlargerNavBar={onEnlargerNavBar}
          />
        ))}
        <Hidden mdUp>
          <LanguageSelector />
        </Hidden>
      </nav>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
