import { RouteStrings } from 'constants/constants';
import { mapRouteIcon, mapRouteTranslateId } from '../../../../../utils/uiUtils';

export default [
  {
    title: 'PAGES',
    pages: [
      // {
      //   title: mapRouteTranslateId(RouteStrings.dashboard),
      //   href: RouteStrings.dashboard,
      //   icon: mapRouteIcon(RouteStrings.dashboard),
      //   // children: [
      //   //   {
      //   //     title: mapRouteTranslateId(RouteStrings.dashboard_overview),
      //   //     href: RouteStrings.dashboard_overview,
      //   //     icon: mapRouteIcon(RouteStrings.dashboard_overview),
      //   //   },
      //   // ],
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.business_management),
      //   href: RouteStrings.business_management,
      //   icon: mapRouteIcon(RouteStrings.business_management),
      //   children: [
      //     {
      //       title: mapRouteTranslateId(RouteStrings.goods_management),
      //       href: RouteStrings.goods_management,
      //       children: [
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_products),
      //           href: RouteStrings.goods_products,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_product_collections),
      //           href: RouteStrings.goods_product_collections,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_categories),
      //           href: RouteStrings.goods_categories,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_properties),
      //           href: RouteStrings.goods_properties,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_brands),
      //           href: RouteStrings.goods_brands,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.goods_suppliers),
      //           href: RouteStrings.goods_suppliers,
      //         },
      //       ],
      //     },
      //     // {
      //     //   title: 'APP',
      //     //   href: '/app',
      //     //   icon: AppIcon,
      //     //   children: [
      //     //     {
      //     //       title: 'APP_INFORMATION',
      //     //       href: '/app/information',
      //     //     },
      //     //     {
      //     //       title: 'DESIGN',
      //     //       href: '/app/design',
      //     //     },
      //     //     {
      //     //       title: 'CONFIGURATION',
      //     //       href: '/app/config',
      //     //       children: [
      //     //         { title: 'HOME', href: '/app/config/home' },
      //     //         { title: 'PRODUCTS', href: '/app/config/products' },
      //     //         { title: 'MESSAGES', href: '/app/config/messages' },
      //     //         { title: 'CART', href: '/app/config/cart' },
      //     //         { title: 'ACCOUNT', href: '/app/config/account' },
      //     //         { title: 'NEWS', href: '/app/config/news' },
      //     //         { title: 'ADDRESS_BOOK', href: '/app/config/address-book' },
      //     //         { title: 'ORDERS', href: '/app/config/orders' },
      //     //         { title: 'PROMOTIONS', href: '/app/config/promotions' },
      //     //         { title: 'CATEGORIES', href: '/app/config/categories' },
      //     //         { title: 'CONTACT', href: '/app/config/contact' },
      //     //         { title: 'RATINGS', href: '/app/config/ratings' },
      //     //       ],
      //     //     },
      //     //     {
      //     //       title: 'TRY_APP',
      //     //       href: '/app/try-app',
      //     //     },
      //     //     {
      //     //       title: 'PUBLISH_APP',
      //     //       href: '/app/publish-app',
      //     //     },
      //     //   ],
      //     // },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.sale_management),
      //       href: RouteStrings.sale_management,
      //       children: [
      //         {
      //           title: mapRouteTranslateId(RouteStrings.sale_orders),
      //           href: RouteStrings.sale_orders,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.sale_promotions),
      //           href: RouteStrings.sale_promotions,
      //         },
      //       ],
      //     },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.warehouse_management),
      //       href: RouteStrings.warehouse_management,
      //       children: [
      //         {
      //           title: mapRouteTranslateId(RouteStrings.warehouse_lots),
      //           href: RouteStrings.warehouse_lots,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.warehouse_warehouses),
      //           href: RouteStrings.warehouse_warehouses,
      //         },
      //       ],
      //     },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.customer_management),
      //       href: RouteStrings.customer_management,
      //       children: [
      //         {
      //           title: mapRouteTranslateId(RouteStrings.customer_customers),
      //           href: RouteStrings.customer_customers,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.customer_sources),
      //           href: RouteStrings.customer_sources,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.customer_classifications),
      //           href: RouteStrings.customer_classifications,
      //         },
      //         {
      //           title: mapRouteTranslateId(RouteStrings.customer_accumulation_points),
      //           href: RouteStrings.customer_accumulation_points,
      //         },
      //       ],
      //     },
      //   ],
      //   // children: [
      //   //   {
      //   //     title: mapRouteTranslateId(RouteStrings.dashboard_overview),
      //   //     href: RouteStrings.dashboard_overview,
      //   //     icon: mapRouteIcon(RouteStrings.dashboard_overview),
      //   //   },
      //   // ],
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.partner_management),
      //   href: RouteStrings.partner_management,
      //   icon: mapRouteIcon(RouteStrings.partner_management),
      //   children: [
      //     {
      //       title: mapRouteTranslateId(RouteStrings.partner_partners),
      //       href: RouteStrings.partner_partners,
      //     },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.partner_categories),
      //       href: RouteStrings.partner_categories,
      //     },
      //   ],
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.store_management),
      //   href: RouteStrings.store_management,
      //   icon: mapRouteIcon(RouteStrings.store_management),
      //   children: [
      //     {
      //       title: mapRouteTranslateId(RouteStrings.store_branches),
      //       href: RouteStrings.store_branches,
      //     },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.store_sub_users),
      //       href: RouteStrings.store_sub_users,
      //     },
      //   ],
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.asset_management),
      //   href: RouteStrings.asset_management,
      //   icon: mapRouteIcon(RouteStrings.asset_management),
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.income_expense_management),
      //   href: RouteStrings.income_expense_management,
      //   icon: mapRouteIcon(RouteStrings.income_expense_management),
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.document_management),
      //   href: RouteStrings.document_management,
      //   icon: mapRouteIcon(RouteStrings.document_management),
      // },
      // {
      //   title: mapRouteTranslateId(RouteStrings.app_store),
      //   href: RouteStrings.app_store,
      //   icon: mapRouteIcon(RouteStrings.app_store),
      //   children: [
      //     {
      //       title: mapRouteTranslateId(RouteStrings.app_asset_management),
      //       href: RouteStrings.app_asset_management,
      //     },
      //     {
      //       title: mapRouteTranslateId(RouteStrings.app_sale),
      //       href: RouteStrings.app_sale,
      //     },
      //   ],
      // },
      {
        title: mapRouteTranslateId(RouteStrings.setting_management),
        href: RouteStrings.setting_management,
        icon: mapRouteIcon(RouteStrings.setting_management),
        children: [
          {
            title: mapRouteTranslateId(RouteStrings.setting_general),
            href: RouteStrings.setting_general,
          },
          // {
          //   title: mapRouteTranslateId(RouteStrings.setting_configuration),
          //   href: RouteStrings.setting_configuration,
          // },
          // {
          //   title: mapRouteTranslateId(RouteStrings.setting_payment_methods),
          //   href: RouteStrings.setting_payment_methods,
          // },
          // {
          //   title: mapRouteTranslateId(RouteStrings.setting_shipment_methods),
          //   href: RouteStrings.setting_shipment_methods,
          // },
          // {
          //   title: mapRouteTranslateId(RouteStrings.setting_account_and_privilege),
          //   href: RouteStrings.setting_account_and_privilege,
          // },
          // {
          //   title: mapRouteTranslateId(RouteStrings.setting_role_and_rights),
          //   href: RouteStrings.setting_role_and_rights,
          // },
          {
            title: mapRouteTranslateId(RouteStrings.setting_other),
            href: RouteStrings.setting_other,
          },
        ],
      },
      {
        title: mapRouteTranslateId(RouteStrings.support),
        href: RouteStrings.support,
        icon: mapRouteIcon(RouteStrings.support),
        children: [
          {
            title: mapRouteTranslateId(RouteStrings.support_faq),
            href: RouteStrings.support_faq,
          },
          {
            title: mapRouteTranslateId(RouteStrings.support_guide),
            href: RouteStrings.support_guide,
          },
        ],
      },
      // {
      //   title: 'Social Feed',
      //   href: '/social-feed',
      //   icon: PeopleIcon,
      // },
    ],
  },
  // {
  //   title: 'Support',
  //   pages: [
  //     {
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon,
  //     },
  //     {
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon,
  //     },
  //     {
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ViewModuleIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.2.0</Label>,
  //     },
  //   ],
  // },
];
