import { put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { productActions } from 'store/ducks/ProductDuck';
import ProductServices from 'services/ProductServices';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';

export function* fetchProductListSaga({ payload }) {
  yield put(productActions.setLoading(true));
  try {
    const res = yield call(ProductServices.fetchProductList, payload);
    yield put(productActions.setProducts(res));
  } catch (error) {
    yield put(productActions.setError(error));
  }
  yield put(productActions.setLoading(false));
}

export function* fetchProductDetailSaga({ payload }) {
  yield put(productActions.setLoadingDetail(true));
  try {
    const res = yield call(ProductServices.getOne, payload);
    yield put(productActions.setProductDetail(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(productActions.setLoadingDetail(false));
}

export function* createProductSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(ProductServices.createProduct, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_PRODUCT' }));
    yield put(productActions.fetchProductList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, error);
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_PRODUCT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateProductSaga({ payload }) {
  const { cb, ...data } = payload;
  console.log('updaloadd', payload, data);
  try {
    yield call(ProductServices.updateProduct, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_PRODUCT' }));
    yield put(productActions.fetchProductList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, error);
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_UPDATE_PRODUCT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteProductsSaga({ payload }) {
  const { cb, data } = payload;
  console.log(data);
  try {
    yield call(ProductServices.deleteProducts, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_PRODUCT' }));
    yield put(productActions.fetchProductList({ page: 0 }));
  } catch (error) {
    const { message } = error;
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_DELETE_PRODUCT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchProductAsync() {
  yield takeLatest(productActions.fetchProductList, fetchProductListSaga);
  yield takeLatest(productActions.fetchProductDetail, fetchProductDetailSaga);
  yield takeEvery(productActions.createProduct, createProductSaga);
  yield takeEvery(productActions.updateProduct, updateProductSaga);
  yield takeEvery(productActions.deleteProducts, deleteProductsSaga);
}
