import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  partnerDetail: null,
  filter: null,
};

const actions = {
  setPartnerList: createAction('partner/set-partner'),
  setLoading: createAction('partner/set-loading'),
  setError: createAction('partner/set-error'),
  setSort: createAction('partner/set-sort'),
  setPageSize: createAction('partner/set-page-size'),
  setFilter: createAction('partner/set-filter'),
  setPage: createAction('partner/set-page'),
  fetchPartnerList: createAction('partner/fetch-partner-list'),
  updatePartner: createAction('partner/update-partner'),
  createPartner: createAction('partner/create-partner'),
  deletePartnerList: createAction('partner/delete-partner-list'),
  fetchPartnerDetail: createAction('partner/fetch-partner-detail'),
  setPartnerDetail: createAction('partner/set-partner-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPartnerList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setPartnerDetail]: (state, action) => {
    state.partnerDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PARTNER],
    state => state,
  ),
  getPartnerDetail: createSelector(
    state => state[reduxResources.PARTNER],
    state => state.partnerDetail,
  ),
};

// all actions

export {
  reducer as partnerReducer,
  actions as partnerActions,
  selectors as partnerSelectors,
};
