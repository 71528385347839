import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { alertSelectors, alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';

const AlertStack = ({ alerts, removeAlert }) => {
  const notistack = useSnackbar();
  const { t } = useTranslation();
  const [displayed, setDisplayed] = useState([]);

  useEffect(() => {
    alerts.forEach(alert => {
      setTimeout(() => {
        // If notification already displayed, abort
        if (displayed.includes(alert.key)) return;
        // Display notification using notistack
        notistack.enqueueSnackbar(alert.translateMsg ? alert.translateMsg : t(alert.translateId), {
          variant: alert.alertType || alertTypes.INFO,
          autoHideDuration: 3000,
          action: key => (
            <IconButton
              size="small"
              onClick={() => {
                notistack.closeSnackbar(key);
              }}
              color="inherit"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
        // Add notification's key to the local state
        setDisplayed([...displayed, alert.key]);
        // Dispatch action to remove the notification from the redux store
        removeAlert(alert.key);
      }, 1);
    });
  }, [alerts, displayed, notistack, removeAlert, t]);

  return null;
};

const mapStateToProps = state => ({
  alerts: alertSelectors.getAlerts(state),
});

const mapDispatchToProps = {
  removeAlert: key => alertActions.remove(key),
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertStack);
