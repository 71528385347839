import { mapObjectToFilterString } from 'utils/filterUtils';
import { getWarehouseInstance } from './apiService';

export const WarehouseServices = {
  get: ({ page, size, sort, filter } = {}) => {
    if (typeof filter !== 'string') {
      filter = mapObjectToFilterString(filter);
    }

    return getWarehouseInstance().get(
      `warehouse?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}${filter ??
        ''}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getWarehouseInstance().get(`warehouse/${id}`);
  },
  update: ({ id, data }) => {
    return getWarehouseInstance().put(`warehouse/${id}`, data);
  },
  create: payload => {
    return getWarehouseInstance().post('warehouse', payload);
  },
  delete: ids => {
    return getWarehouseInstance().delete(`warehouse/bulk?ids=${ids.join(',')}`);
  },
};

export const WarehouseDetailService = {
  get: ({ page, size, sort, filter } = {}) => {
    let defaultSort = 'quantity:DESC';
    if (filter && filter.groupByVariation) {
      defaultSort = 'totalQuantity:DESC';
    }
    const filterString = mapObjectToFilterString(filter);
    return getWarehouseInstance().get(
      `warehouse-detail?sortBy=${sort ?? defaultSort}&page=${page ?? 0}&size=${size ??
        10}${filterString ?? ''}`,
    );
  },
};
