import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useMerchantDetail } from 'hooks/merchantHooks';

const Logo = ({ showMerchantName }) => {
  const merchantDetail = useMerchantDetail();
  return (
    <>
      {merchantDetail?.logo && (
        <img
          src={merchantDetail?.logo}
          alt="logo"
          style={{
            height: 56,
            objectFit: 'cover',
          }}
        />
      )}
      {showMerchantName && (
        <Typography
          style={{
            color: '#fff',
            marginTop: 8,
          }}
        >
          {merchantDetail?.name}
        </Typography>
      )}
    </>
  );
};

export default Logo;
