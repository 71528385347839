export const getMerchantIdentifyFromUrl = ({ pathname, search }) => {
  const paths = pathname.split('/');
  if (paths.length === 3) {
    const domain = paths[paths.length - 1];
    // domain's priority: 1
    if (domain) {
      return { domain };
    }
  }

  const searchParams = new URLSearchParams(search);
  // subDomain's priority: 2
  // TODO: get subDomain
  const subDomain = searchParams.get('subDomain');
  if (subDomain) {
    return { subDomain };
  }

  // merchantId's priority: 3
  const merchantId = searchParams.get('merchantId');
  if (merchantId) {
    return { merchantId };
  }
  return {};
};

export const mapMerchantIdentifyToAccountService = merchantIdentify => {
  const data = {};

  if (merchantIdentify.domain) {
    data.merchantDomain = merchantIdentify.domain;
  }
  if (merchantIdentify.subDomain) {
    data.merchantSubDomain = merchantIdentify.subDomain;
  }
  if (merchantIdentify.merchantId) {
    data.merchantId = merchantIdentify.merchantId;
  }

  return data;
};
