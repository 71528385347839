import { getMerchantInstance } from './apiService';

const CustomerServices = {
  get: ({ sort, page, size }) => {
    return getMerchantInstance().get(
      `customer?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getMerchantInstance().get(`customer/admin/${id}`);
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`customer/admin/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('customer/admin', payload);
  },
  delete: ids => {
    return getMerchantInstance().delete(`customer/admin/bulk?ids=${ids.join(',')}`);
  },
};

export default CustomerServices;
