import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from '../../constants/constants';

const initialState = {
  data: null,
  page: 0,
  size: 10,
  filter: {},
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
};

const actions = {
  setCustomerAddressList: createAction('customer-address/set-customer-address-list'),
  setLoading: createAction('customer-address/set-loading'),
  setError: createAction('customer-address/set-error'),
  setPageSize: createAction('customer-address/set-page-size'),
  setPage: createAction('customer-address/set-page'),
  setSort: createAction('customer-address/set-sort'),
  setFilter: createAction('customer-address/set-filter'),
  updateCustomerAddress: createAction('customer-address/update-customer-address'),
  updateDefaultAddress: createAction('customer-address/update-default-address'),
  createCustomerAddress: createAction('customer-address/create-customer-address'),
  deleteCustomerAddress: createAction('customer-address/delete-customer-address'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setCustomerAddressList]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.data = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.CUSTOMER_ADDRESS],
    state => state,
  ),
};

// all actions

export {
  reducer as customerAddressReducer,
  actions as customerAddressActions,
  selectors as customerAddressSelectors,
};
