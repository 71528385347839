import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants.js';

const initialState = {
  alerts: [],
};

const actions = {
  show: createAction('alert/show'),
  remove: createAction('alert/remove'),
};

const alertReducer = createReducer(initialState, {
  [actions.show]: (state, action) => {
    state.alerts = [
      ...state.alerts,
      {
        ...action.payload,
        key: Date.now().toString() + Math.random(),
      },
    ];
  },
  [actions.remove]: (state, action) => {
    state.alerts = state.alerts.filter(a => a.key !== action.payload.key);
  },
});

const getAlertState = state => state[reduxResources.ALERT];

const selectors = {
  getAlerts: createSelector(getAlertState, state => state.alerts),
};

export { actions as alertActions, alertReducer, selectors as alertSelectors };
