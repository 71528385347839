import { put, call, takeLatest } from 'redux-saga/effects';
import { supplierActions } from 'store/ducks/SupplierDuck';
import { alertTypes } from 'constants/constants';
import { alertActions } from 'store/ducks/AlertDuck';
import SupplierServices from 'services/SupplierServices';
import actionUtils from 'utils/actionUtils';

export function* fetchSupplierListSaga({ payload }) {
  yield put(supplierActions.setLoading(true));
  try {
    const res = yield call(SupplierServices.get, payload);
    yield put(supplierActions.setSupplierList(res));
  } catch (error) {
    yield put(
      alertActions.show({
        translateId: error.message,
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(supplierActions.setLoading(false));
}

export function* createSupplierSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(SupplierServices.create, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'CREATED_SUPPLIER' }));
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_CREATE_SUPPLIER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateSupplierSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(SupplierServices.update, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_SUPPLIER' }));
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_SUPPLIER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteSuppliersSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(SupplierServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'DELETED_SUPPLIER' }));
    yield put(supplierActions.fetchSupplierList({ page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_SUPPLIER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchSupplierAsync() {
  yield takeLatest(supplierActions.fetchSupplierList, fetchSupplierListSaga);
  yield takeLatest(supplierActions.createSupplier, createSupplierSaga);
  yield takeLatest(supplierActions.updateSupplier, updateSupplierSaga);
  yield takeLatest(supplierActions.deleteSupplierList, deleteSuppliersSaga);
}
