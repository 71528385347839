import { getSaleInstance } from './apiService';

const ProductServices = {
  fetchProductList: ({ page, size, sort, filter }) => {
    return getSaleInstance().get(
      `product?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}${filter ??
        ''}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getSaleInstance().get(`product/${id}`);
  },
  updateProduct: ({ id, data }) => {
    return getSaleInstance().put(`product/${id}`, data);
  },
  createProduct: payload => {
    return getSaleInstance().post('product', payload);
  },
  deleteProducts: productIds => {
    return getSaleInstance().delete(`product/bulk?ids=${productIds.join(',')}`);
  },
};

export default ProductServices;
