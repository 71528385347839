import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { RouteStrings } from '../../../constants/constants';

const TenantPage = () => {
  /**
   * /u/subDomain
   */
  const { pathname } = useLocation();

  const token = localStorage.getItem('ACCESS_TOKEN');

  if (token) {
    const pathnameSplit = pathname.split('/');
    if (pathnameSplit.length > 3 && pathnameSplit[3] !== '') {
      // all after /u/subDomain
      const targetPathname = `/${pathnameSplit.slice(3).join('/')}`;
      return <Redirect to={targetPathname} />;
    }
    return <Redirect to={RouteStrings.dashboard} />;
  }
  console.log('pathname', pathname, token);

  return <Redirect to={RouteStrings.login + pathname} />;
};

export default TenantPage;
