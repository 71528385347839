import React from 'react';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';

const LandingPage = () => {
  return (
    <>
      <LandingHeader />
      <div className="landing-page">Landing page</div>
      <LandingFooter />
    </>
  );
};

export default LandingPage;
