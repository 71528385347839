import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  supplierDetail: null,
  filter: null,
};

const actions = {
  setSupplierList: createAction('supplier/set-supplier'),
  setLoading: createAction('supplier/set-loading'),
  setError: createAction('supplier/set-error'),
  setSort: createAction('supplier/set-sort'),
  setPageSize: createAction('supplier/set-page-size'),
  setFilter: createAction('supplier/set-filter'),
  setPage: createAction('supplier/set-page'),
  fetchSupplierList: createAction('supplier/fetch-supplier-list'),
  updateSupplier: createAction('supplier/update-supplier'),
  createSupplier: createAction('supplier/create-supplier'),
  deleteSupplierList: createAction('supplier/delete-supplier-list'),
  fetchSupplierDetail: createAction('supplier/fetch-supplier-detail'),
  setSupplierDetail: createAction('supplier/set-supplier-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setSupplierList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setSupplierDetail]: (state, action) => {
    state.supplierDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.SUPPLIER],
    state => state,
  ),
  getSupplierDetail: createSelector(
    state => state[reduxResources.SUPPLIER],
    state => state.supplierDetail,
  ),
};

// all actions

export {
  reducer as supplierReducer,
  actions as supplierActions,
  selectors as supplierSelectors,
};
