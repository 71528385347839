import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  page: 0,
  size: 10,
  sort: null,
  branchDetail: null,
};

const actions = {
  setBranchList: createAction('branch/set-branch'),
  setLoading: createAction('branch/set-loading'),
  setError: createAction('branch/set-error'),
  setSort: createAction('branch/set-sort'),
  setPage: createAction('branch/set-page'),
  setPageSize: createAction('branch/set-page-size'),
  fetchBranchList: createAction('branch/fetch-branch-list'),
  updateBranch: createAction('branch/update-branch'),
  createBranch: createAction('branch/create-branch'),
  deleteBranchList: createAction('branch/delete-branch-list'),
  fetchBranchDetail: createAction('branch/fetch-branch-detail'),
  setBranchDetail: createAction('branch/set-branch-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setBranchList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setBranchDetail]: (state, action) => {
    state.branchDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.BRANCH],
    state => state,
  ),
  getBranchDetail: createSelector(
    state => state[reduxResources.BRANCH],
    state => state.branchDetail,
  ),
};

// all actions

export { reducer as branchReducer, actions as branchActions, selectors as branchSelectors };
