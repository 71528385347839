export default {
  AUTH: 'auth',
  ACCUMULATION_POINT: 'accumulation_point',
  ALERT: 'alert',
  BRAND: 'brand',
  PRODUCT: 'product',
  CATEGORY: 'category',
  CUSTOMER_CLASSIFICATION: 'customer_classification',
  BRANCH: 'branch',
  SUB_USER: 'sub_user',
  LOCATION: 'location',
  THEME: 'theme',
  PROMOTION: 'promotion',
  PROPERTY: 'property',
  PAYMENT: 'payment',
  VARIATION: 'variation',
  CUSTOMER: 'customer',
  CUSTOMER_ADDRESS: 'custome_address',
  SUPPLIER: 'supplier',
  PARTNER: 'partner',
  MERCHANT: 'merchant',
  RESOURCE_FOLDER: 'resource_folder',
  LOT: 'lot',
  PRICE: 'price',
  WAREHOUSE: 'warehouse',
  WAREHOUSE_DETAIL: 'warehouse-detail',
  PARTNER_CATEGORY: 'partner_category',
  PARTNER_CUSTOMER: 'partner_customer',
  CUSTOMER_SOURCE: 'customer-source',
};
