import { put, call, takeLatest } from 'redux-saga/effects';
import { partnerCustomerActions } from 'store/ducks/PartnerCustomerDuck';
import { alertTypes } from 'constants/constants';
import { alertActions } from 'store/ducks/AlertDuck';
import PartnerCustomerServices from 'services/PartnerCustomerServices';

export function* fetchPartnerCustomerListSaga({ payload }) {
  try {
    const res = yield call(PartnerCustomerServices.get, payload);
    yield put(partnerCustomerActions.setPartnerCustomerList(res));
  } catch (error) {
    yield put(partnerCustomerActions.setLoading(false));
    yield put(
      alertActions.show({
        translateId: error.message,
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPartnerCustomerAsync() {
  yield takeLatest(partnerCustomerActions.fetchPartnerCustomerList, fetchPartnerCustomerListSaga);
}
