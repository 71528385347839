import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { merchantSelectors, merchantActions } from 'store/ducks/MerchantDuck';

export const useMerchantDetail = () => {
  const dispatch = useDispatch();
  const merchantDetail = useSelector(merchantSelectors.getMerchantDetail);

  // fetch partnerTypes
  useEffect(() => {
    if (!merchantDetail) dispatch(merchantActions.fetchMerchantDetail());
  }, [dispatch, merchantDetail]);

  const data = useMemo(() => {
    return merchantDetail;
  }, [merchantDetail]);

  return data;
};

export const usePublicMerchantDetail = ({ domain, subDomain }) => {
  const dispatch = useDispatch();
  const merchantDetail = useSelector(merchantSelectors.getPublicMerchantDetail);

  // fetch partnerTypes
  useEffect(() => {
    if ((domain || subDomain) && !merchantDetail)
      dispatch(merchantActions.fetchMerchantPublicDetail({ domain, subDomain }));
  }, [dispatch, merchantDetail, domain, subDomain]);

  const data = useMemo(() => {
    return merchantDetail;
  }, [merchantDetail]);

  return data;
};
