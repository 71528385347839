import { generalStatus } from './constants';

const colors = {
  PRIMARY: {
    main: '#3699ff',
    lighter: '#e1f0ff',
  }, // primary for custom color, no primary config
  SECONDARY: {
    main: '#3f4254',
    lighter: '#e4e6ef',
  },
  INFO: {
    main: '#8950fc',
    lighter: '#eee5ff',
  },
  SUCCESS: {
    main: '#15a8a2',
    lighter: '#d5f6f5',
  },
  WARNING: {
    main: '#ffa800',
    lighter: '#fff4de',
  },
  ERROR: {
    main: '#f64e60',
    lighter: '#ffe2e5',
  },
  DARK: {
    main: '#181c32',
    lighter: '#d1d3e0',
  },
  LINK: {
    main: '#3699ff',
    darker: '#0073e9',
  },
  WHITE: {
    main: '#ffffff',
  },
};

const generalStatusColor = {
  [generalStatus.ACTIVE]: {
    textColor: colors.WHITE.main,
    backgroundColor: colors.SUCCESS.main,
    borderColor: colors.SUCCESS.main,
  },
  [generalStatus.NEW]: {
    textColor: colors.PRIMARY.main,
    backgroundColor: colors.PRIMARY.lighter,
    borderColor: colors.PRIMARY.lighter,
  },
  [generalStatus.INACTIVE]: {
    textColor: colors.WHITE.main,
    backgroundColor: colors.WARNING.main,
    borderColor: colors.WARNING.main,
  },
  [generalStatus.DELETED]: {
    textColor: colors.ERROR.main,
    backgroundColor: colors.ERROR.lighter,
    borderColor: colors.ERROR.lighter,
  },
};

const defaultStatusColor = {
  textColor: colors.PRIMARY.main,
  backgroundColor: colors.PRIMARY.lighter,
  borderColor: colors.PRIMARY.lighter,
};

export { colors, generalStatusColor, defaultStatusColor };
