import { all } from 'redux-saga/effects';
import watchAuthenAsync from './sagas/AuthSaga';
import watchBrandAsync from './sagas/BrandSaga';
import watchProductAsync from './sagas/ProductSaga';
import watchCategoryAsync from './sagas/CategorySaga';
import watchCustomerClassificationAsync from './sagas/CustomerClassificationSaga';
import watchBranchAsync from './sagas/BranchSaga';
import watchAccumulationPointAsync from './sagas/AccumulationPointSaga';
import watchLocationAsync from './sagas/LocationSaga';
import watchSubUserAsync from './sagas/SubUserSaga';
import watchPromotionAsync from './sagas/PromotionSaga';
import watchPropertyAsync from './sagas/PropertySaga';
import watchPaymentAsync from './sagas/PaymentSaga';
import watchVariationAsync from './sagas/VariationSaga';
import watchMerchantAsync from './sagas/MerchantSaga';
import watchCustomerAsync from './sagas/CustomerSaga';
import watchSupplierAsync from './sagas/SupplierSaga';
import watchPartnerAsync from './sagas/PartnerSaga';
import watchResourceFolderAsync from './sagas/ResourceFolderSaga';
import watchLotAsync from './sagas/LotSaga';
import watchPriceAsync from './sagas/PriceSaga';
import watchWarehouseAsync from './sagas/WarehouseSaga';
import watchPartnerCategoryAsync from './sagas/PartnerCategorySaga';
import watchPartnerCustomerAsync from './sagas/PartnerCustomerSaga';
import watchCustomerSourceAsync from './sagas/CustomerSourceSaga';
import watchCustomerAddressAsync from './sagas/CustomerAddressSaga';

export default function* rootSaga() {
  yield all([
    watchAuthenAsync(),
    watchBrandAsync(),
    watchProductAsync(),
    watchCategoryAsync(),
    watchCustomerClassificationAsync(),
    watchBranchAsync(),
    watchAccumulationPointAsync(),
    watchLocationAsync(),
    watchSubUserAsync(),
    watchPromotionAsync(),
    watchPropertyAsync(),
    watchPaymentAsync(),
    watchVariationAsync(),
    watchMerchantAsync(),
    watchCustomerAsync(),
    watchSupplierAsync(),
    watchPartnerAsync(),
    watchResourceFolderAsync(),
    watchLotAsync(),
    watchWarehouseAsync(),
    watchPriceAsync(),
    watchPartnerCategoryAsync(),
    watchPartnerCustomerAsync(),
    watchCustomerSourceAsync(),
    watchCustomerAddressAsync(),
  ]);
  // code after all-effect
}
