import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  paymentDetail: null,
};

const actions = {
  setPaymentList: createAction('payment/set-payment'),
  setLoading: createAction('payment/set-loading'),
  setError: createAction('payment/set-error'),
  setSort: createAction('payment/set-sort'),
  setPageSize: createAction('payment/set-page-size'),
  setPage: createAction('payment/set-page'),
  fetchPaymentList: createAction('payment/fetch-payment-list'),
  updatePayment: createAction('payment/update-payment'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPaymentList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setPaymentDetail]: (state, action) => {
    state.paymentDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PAYMENT],
    state => state,
  ),
  getPaymentDetail: createSelector(
    state => state[reduxResources.PAYMENT],
    state => state.paymentDetail,
  ),
};

// all actions

export {
  reducer as paymentReducer,
  actions as paymentActions,
  selectors as paymentSelectors,
};
