import { getMerchantInstance } from './apiService';

const ResourceFolderServices = {
  get: ({ page, size, sort }) => {
    return getMerchantInstance().get(
      `resource-folder?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ?? 0}&size=${size ?? 10}`,
    );
  },
  update: ({ id, data }) => {
    return getMerchantInstance().put(`resource-folder/${id}`, data);
  },
  create: payload => {
    return getMerchantInstance().post('resource-folder', payload);
  },
  deleteList: ids => {
    return getMerchantInstance().delete(`resource-folder/bulk?ids=${ids.join(',')}`);
  },
};

export default ResourceFolderServices;
