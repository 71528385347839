import { mapObjectToFilterString } from 'utils/filterUtils';
import { getSaleInstance } from './apiService';

const PromotionServices = {
  get: ({ page, size, sort, filter } = {}) => {
    const filterString = mapObjectToFilterString(filter);
    return getSaleInstance().get(
      `promotion?sortBy=${sort ?? 'createdAt:DESC'}&page=${page ??
        0}&size=${size ?? 10}${filterString ?? ''}`,
    );
  },
  getOne: ({ id } = {}) => {
    return getSaleInstance().get(`promotion/${id}`);
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`promotion/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('promotion', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(`promotion/bulk?ids=${ids.join(',')}`);
  },
};

export default PromotionServices;
