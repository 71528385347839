import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BackIcon from '@material-ui/icons/KeyboardBackspace';

import Spacing from 'components/common/Spacing';
import { authActions } from 'store/ducks/AuthDuck';
import { makeStyles, useTheme } from '@material-ui/styles';
import LanguageSelector from 'components/common/LanguageSelector';

import { push, replace } from 'connected-react-router';
import { alertTypes, RouteStrings } from 'constants/constants';
import Page from 'components/common/Page';
import { alertActions } from '../../../store/ducks/AlertDuck';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
    },
    position: 'relative',
  },
  paperContainer: {
    padding: theme.spacing(6, 4),
    maxWidth: 400,
    minWidth: 400,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none !important',
    },
  },
  languageContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    // backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },
  logoContainer: {
    height: 56,
    objectFit: 'contain',
  },
}));

const ForgetPasswordPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleResetPassword = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      authActions.forgetPassword({
        data: values,
        callback: ({ hasError } = {}) => {
          setSubmitting(false);
          if (!hasError) {
            dispatch(
              alertActions.show({
                translateId: 'FORGET_PASSWORD_SUCCESS',
                alertType: alertTypes.SUCCESS,
              }),
            );
            dispatch(replace(RouteStrings.login));
          }
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .ensure()
        .required('THIS_FIELD_IS_REQUIRED'),
    }),
    onSubmit: handleResetPassword,
  });

  const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting } = formik;

  const handleBackToLogin = () => {
    dispatch(push(RouteStrings.login));
  };

  return (
    <Page className={classes.root} title={`${t('FORGET_PASSWORD')}`}>
      <div className={classes.languageContainer}>
        <LanguageSelector color={theme.palette.primary} />
      </div>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paperContainer}>
          {/* <img className="login-logo" alt="" src={merchantLogo ?? logoUrl} /> */}
          <Spacing vertical={2} />
          <Typography align="center" variant="h5">
            {t('FORGET_PASSWORD')}
          </Typography>
          <Spacing vertical={4} />
          <TextField
            name="email"
            value={values.email}
            variant="outlined"
            fullWidth
            label={t('EMAIL')}
            onChange={handleChange}
            error={touched.email && errors.email}
            helperText={errors.email && touched.email ? t(errors.email) : ''}
            required
            onBlur={handleBlur}
            size="small"
          />
          <Spacing vertical={4} />
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            {isSubmitting && (
              <>
                <CircularProgress size={16} />
                <Spacing horizontal={2} />
              </>
            )}
            {t('SUBMIT')}
          </Button>
          <Spacing vertical={3} />
          <Button
            variant="text"
            color="primary"
            onClick={handleBackToLogin}
            startIcon={<BackIcon />}
          >
            {t('BACK_TO_LOGIN')}
          </Button>
        </Paper>
      </form>
    </Page>
  );
};

export default ForgetPasswordPage;
