/* eslint-disable no-console */
import { put, call, takeLatest } from 'redux-saga/effects';
// import { alertTypes } from 'constants/constants';
// import { alertActions } from 'store/ducks/AlertDuck';
import { locationActions } from 'store/ducks/LocationDuck';
import LocationServices from 'services/LocationServices';

const getLocationResource = (locationKey, id) => {
  try {
    const resource = localStorage.getItem(`${locationKey}_${id}`);
    const resourceJson = JSON.parse(resource);
    return resourceJson;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const saveLocationResource = (locationKey, id, data) => {
  try {
    localStorage.setItem(`${locationKey}_${id}`, JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
};

export function* fetchCountriesSaga({ payload }) {
  try {
    let countries = getLocationResource('COUNTRIES_');

    if (!countries) {
      const res = yield call(LocationServices.getCountries, payload);
      countries = res.data;
      saveLocationResource('COUNTRIES', '', countries);
    }
    yield put(locationActions.setCountries(countries));
  } catch (error) {
    console.log(error);
    yield put(locationActions.setCountries([]));
  }
}

export function* fetchProvincesSaga({ payload }) {
  try {
    let provinces = getLocationResource('PROVINCES', payload);

    if (!provinces) {
      const res = yield call(LocationServices.getProvinces, payload);
      provinces = res.data;
      saveLocationResource('PROVINCES', payload, provinces);
    }

    yield put(locationActions.setProvinces(provinces));
  } catch (error) {
    yield put(locationActions.setProvinces([]));
    console.log(error);
  }
}

export function* fetchDistrictsSaga({ payload }) {
  try {
    let districts = getLocationResource('DISTRICTS', payload);

    if (!districts) {
      const res = yield call(LocationServices.getDistricts, payload);
      districts = res.data;
      saveLocationResource('DISTRICTS', payload, districts);
    }

    yield put(locationActions.setDistricts(districts));
  } catch (error) {
    yield put(locationActions.setDistricts([]));
    console.log(error);
  }
}
export function* fetchWardsSaga({ payload }) {
  try {
    let wards = getLocationResource('WARDS', payload);
    if (!wards) {
      const res = yield call(LocationServices.getWards, payload);
      wards = res.data;
      saveLocationResource('WARDS', payload, wards);
    }
    yield put(locationActions.setWards(wards));
  } catch (error) {
    yield put(locationActions.setWards([]));
    console.log(error);
  }
}

export default function* watchLocationAsync() {
  yield takeLatest(locationActions.fetchCountries, fetchCountriesSaga);
  yield takeLatest(locationActions.fetchProvinces, fetchProvincesSaga);
  yield takeLatest(locationActions.fetchDistricts, fetchDistrictsSaga);
  yield takeLatest(locationActions.fetchWards, fetchWardsSaga);
}
