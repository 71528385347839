import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { priceActions } from 'store/ducks/PriceDuck';
import PriceService from 'services/PriceService';
import actionUtils from 'utils/actionUtils';
import { alertActions } from 'store/ducks/AlertDuck';
import { alertTypes } from 'constants/constants';

export function* fetchPriceListSaga({ payload }) {
  yield put(priceActions.setLoading(true));
  try {
    const res = yield call(PriceService.fetchPriceList, payload);
    yield put(priceActions.setPrices(res));
  } catch (error) {
    yield put(priceActions.setError(error));
  }
  yield put(priceActions.setLoading(false));
}

export function* updatePricesSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(PriceService.updatePrices, data);
    actionUtils.doCallback(cb);
    yield put(alertActions.show({ translateId: 'UPDATED_PRICE' }));
    const state = yield select();

    console.log('SELECT', state);
    yield put(priceActions.fetchPriceList({ ...state.price, page: 0 }));
  } catch (error) {
    actionUtils.doCallback(cb, error);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PRICE',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPriceAsync() {
  yield takeLatest(priceActions.fetchPriceList, fetchPriceListSaga);
  yield takeEvery(priceActions.updatePrices, updatePricesSaga);
}
