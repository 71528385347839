import Moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import LotServices from 'services/LotServices';
import { lotActions } from 'store/ducks/LotDuck';

export function* fetchLotListSaga({ payload }) {
  yield put(lotActions.setLoading(true));
  try {
    const res = yield call(LotServices.get, payload);
    yield put(lotActions.setLotList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
    yield put(lotActions.setLoading(false));
  }
}

export function* fetchLotDetailSaga({ payload }) {
  try {
    const res = yield call(LotServices.getOne, payload);

    const {
      brandsApplied,
      categoriesApplied,
      productsApplied,
      variationsApplied,
      giftBrands,
      giftCategories,
      giftProducts,
      giftVariations,
      startDate,
      endDate,
      ...rest
    } = res;
    yield put(
      lotActions.setLotDetail({
        ...rest,
        startDate: startDate ? new Moment(startDate) : null,
        endDate: endDate ? new Moment(endDate) : null,
        brandsApplied:
          brandsApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        categoriesApplied:
          categoriesApplied?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        productsApplied:
          productsApplied?.map(({ id, name, images }) => ({
            id,
            name,
            images,
          })) ?? [],
        variationsApplied,
        giftBrands:
          giftBrands?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftCategories:
          giftCategories?.map(({ id, iconUrl, name }) => ({
            id,
            iconUrl,
            name,
          })) ?? [],
        giftProducts:
          giftProducts?.map(({ id, name, images }) => ({
            id,
            name,
            images,
          })) ?? [],
        giftVariations,
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createLotSaga({ payload }) {
  console.log('lotsaga', payload);
  const { cb, ...data } = payload;
  try {
    yield call(LotServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_LOT',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(lotActions.fetchLotList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_LOT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateLotSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(LotServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_LOT',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(lotActions.fetchLotList());
  } catch (error) {
    actionUtils.doCallback(cb, error);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_LOT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteLotListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(LotServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_LOT',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(lotActions.fetchLotList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_LOT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchLotAsync() {
  yield takeLatest(lotActions.fetchLotList, fetchLotListSaga);

  yield takeLatest(lotActions.fetchLotDetail, fetchLotDetailSaga);

  yield takeLatest(lotActions.createLot, createLotSaga);
  yield takeLatest(lotActions.updateLot, updateLotSaga);
  yield takeLatest(lotActions.deleteLotList, deleteLotListSaga);
}
