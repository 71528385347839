import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
};

const actions = {
  setAccumulationPointList: createAction(
    'accumulation-point/set-accumulation-point',
  ),
  setLoading: createAction('accumulation-point/set-loading'),
  setError: createAction('accumulation-point/set-error'),
  setSort: createAction('accumulation-point/set-sort'),
  setPageSize: createAction('accumulation-point/set-page-size'),
  setPage: createAction('accumulation-point/set-page'),
  fetchAccumulationPointList: createAction(
    'accumulation-point/fetch-accumulation-point-list',
  ),
  updateAccumulationPoint: createAction(
    'accumulation-point/update-accumulation-point',
  ),
  createAccumulationPoint: createAction(
    'accumulation-point/create-accumulation-point',
  ),
  deleteAccumulationPointList: createAction(
    'accumulation-point/delete-accumulation-point-list',
  ),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setAccumulationPointList]: (state, action) => {
    const { data, total } = action.payload;

    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.ACCUMULATION_POINT],
    state => state,
  ),
};

// all actions

export {
  reducer as accumulationPointReducer,
  actions as accumulationPointActions,
  selectors as accumulationPointSelectors,
};
