import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOverviewIcon from '@material-ui/icons/Equalizer';
import ProductIcon from '@material-ui/icons/CardGiftcard';
import ProductCollectionIcon from '@material-ui/icons/CollectionsBookmark';
import CategoryIcon from '@material-ui/icons/Category';
import PropertyIcon from '@material-ui/icons/ListAlt';
import BrandIcon from '@material-ui/icons/FilterVintage';
import SupplierIcon from '@material-ui/icons/Input';
import OrderIcon from '@material-ui/icons/ShoppingCart';
import PromotionIcon from '@material-ui/icons/LocalOffer';
import WarehouseIcon from '@material-ui/icons/House';
import LotIcon from '@material-ui/icons/Widgets';
import CustomerIcon from '@material-ui/icons/People';
import CustomerSourceIcon from '@material-ui/icons/Loyalty';
import ClassificationIcon from '@material-ui/icons/Stars';
import AccumulationPointIcon from '@material-ui/icons/PlusOne';
import StoreIcon from '@material-ui/icons/Store';
import SubUserIcon from '@material-ui/icons/SupervisorAccount';
import PartnerIcon from '@material-ui/icons/Business';
import PartnerCategoryIcon from '@material-ui/icons/Apartment';
import DocumentIcon from '@material-ui/icons/MenuBook';
import AssetIcon from '@material-ui/icons/AccountBalance';
import AppIcon from '@material-ui/icons/Apps';
import SettingIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import ShipmentIcon from '@material-ui/icons/LocalShipping';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SupportIcon from '@material-ui/icons/Help';
import FaqIcon from '@material-ui/icons/ContactSupport';
import OtherSettingIcon from '@material-ui/icons/PermDataSetting';
import PrivilegeIcon from '@material-ui/icons/SupervisedUserCircle';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

import { RouteStrings } from '../constants/constants';
import { colors } from '../constants/colorConstants';

export const mapRouteIcon = routeString => {
  return {
    [RouteStrings.dashboard]: DashboardIcon,
    [RouteStrings.dashboard_overview]: DashboardOverviewIcon,
    [RouteStrings.goods_management]: ProductIcon,
    [RouteStrings.goods_products]: ProductIcon,
    [RouteStrings.goods_product_collections]: ProductCollectionIcon,
    [RouteStrings.goods_categories]: CategoryIcon,
    [RouteStrings.goods_properties]: PropertyIcon,
    [RouteStrings.goods_brands]: BrandIcon,
    [RouteStrings.goods_suppliers]: SupplierIcon,
    [RouteStrings.sale_management]: OrderIcon,
    [RouteStrings.sale_orders]: OrderIcon,
    [RouteStrings.sale_promotions]: PromotionIcon,
    [RouteStrings.warehouse_management]: WarehouseIcon,
    [RouteStrings.warehouse_lots]: LotIcon,
    [RouteStrings.warehouse_warehouses]: WarehouseIcon,
    [RouteStrings.customer_management]: CustomerIcon,
    [RouteStrings.customer_customers]: CustomerIcon,
    [RouteStrings.customer_sources]: CustomerSourceIcon,
    [RouteStrings.customer_classifications]: ClassificationIcon,
    [RouteStrings.customer_accumulation_points]: AccumulationPointIcon,
    [RouteStrings.store_management]: StoreIcon,
    [RouteStrings.store_branches]: StoreIcon,
    [RouteStrings.store_sub_users]: SubUserIcon,
    [RouteStrings.partner_management]: PartnerIcon,
    [RouteStrings.partner_partners]: PartnerIcon,
    [RouteStrings.partner_categories]: PartnerCategoryIcon,
    [RouteStrings.document_management]: DocumentIcon,
    [RouteStrings.asset_management]: AssetIcon,
    [RouteStrings.app_store]: AppIcon,
    [RouteStrings.app_asset_management]: AssetIcon,
    [RouteStrings.app_sale]: OrderIcon,
    [RouteStrings.setting_management]: SettingIcon,
    [RouteStrings.setting_general]: SettingIcon,
    [RouteStrings.setting_payment_methods]: PaymentIcon,
    [RouteStrings.setting_shipment_methods]: ShipmentIcon,
    [RouteStrings.setting_account_and_privilege]: AccountIcon,
    [RouteStrings.setting_role_and_rights]: PrivilegeIcon,
    [RouteStrings.setting_other]: OtherSettingIcon,
    [RouteStrings.support]: SupportIcon,
    [RouteStrings.support_faq]: FaqIcon,
    [RouteStrings.support_guide]: SupportIcon,
    [RouteStrings.business_management]: OrderIcon,
    [RouteStrings.income_expense_management]: MoneyIcon,
  }[routeString];
};

export const mapRouteTranslateId = routeString => {
  return {
    [RouteStrings.dashboard]: 'DASHBOARD',
    [RouteStrings.dashboard_overview]: 'DASHBOARD_OVERVIEW',
    [RouteStrings.goods_management]: 'GOODS_MANAGEMENT',
    [RouteStrings.goods_products]: 'PRODUCT',
    [RouteStrings.goods_product_collections]: 'PRODUCT_COLLECTIONS',
    [RouteStrings.goods_categories]: 'PRODUCT_CATEGORIES',
    [RouteStrings.goods_properties]: 'PRODUCT_PROPERTIES',
    [RouteStrings.goods_brands]: 'PRODUCT_BRANDS',
    [RouteStrings.goods_suppliers]: 'PRODUCT_SUPPLIERS',
    [RouteStrings.sale_management]: 'SALE_MANAGEMENT',
    [RouteStrings.sale_orders]: 'SALE_ORDERS',
    [RouteStrings.sale_promotions]: 'SALE_PROMOTIONS',
    [RouteStrings.warehouse_management]: 'WAREHOUSE_MANAGEMENT',
    [RouteStrings.warehouse_lots]: 'WAREHOUSE_LOTS',
    [RouteStrings.warehouse_warehouses]: 'WAREHOUSE_WAREHOUSES',
    [RouteStrings.customer_management]: 'CUSTOMER_MANAGEMENT',
    [RouteStrings.customer_customers]: 'CUSTOMER_CUSTOMERS',
    [RouteStrings.customer_sources]: 'CUSTOMER_SOURCES',
    [RouteStrings.customer_classifications]: 'CUSTOMER_CLASSIFICATIONS',
    [RouteStrings.customer_accumulation_points]: 'CUSTOMER_ACCUMULATION_POINTS',
    [RouteStrings.store_management]: 'STORE_MANAGEMENT',
    [RouteStrings.store_branches]: 'STORE_BRANCHES',
    [RouteStrings.store_sub_users]: 'STORE_SUB_USERS',
    [RouteStrings.partner_management]: 'PARTNER_MANAGEMENT',
    [RouteStrings.partner_partners]: 'PARTNER_PARTNERS',
    [RouteStrings.partner_categories]: 'PARTNER_CATEGORIES',
    [RouteStrings.document_management]: 'DOCUMENT_MANAGEMENT',
    [RouteStrings.asset_management]: 'ASSET_MANAGEMENT',
    [RouteStrings.app_store]: 'APP_STORE',
    [RouteStrings.app_asset_management]: 'APP_ASSET_MANAGEMENT',
    [RouteStrings.app_sale]: 'APP_SALE',
    [RouteStrings.setting_management]: 'SETTING_MANAGEMENT',
    [RouteStrings.setting_general]: 'SETTING_GENERAL',
    [RouteStrings.setting_configuration]: 'SETTING_CONFIGURATION',
    [RouteStrings.setting_payment_methods]: 'SETTING_PAYMENT_METHODS',
    [RouteStrings.setting_shipment_methods]: 'SETTING_SHIPMENT_METHODS',
    [RouteStrings.setting_account_and_privilege]: 'SETTING_ACCOUNT_AND_PRIVILEGE',
    [RouteStrings.setting_role_and_rights]: 'SETTING_ROLE_AND_RIGHTS',
    [RouteStrings.setting_other]: 'SETTING_OTHER',
    [RouteStrings.support]: 'SUPPORT',
    [RouteStrings.support_faq]: 'SUPPORT_FAQ',
    [RouteStrings.support_guide]: 'SUPPORT_GUIDE',
    [RouteStrings.business_management]: 'BUSINESS_MANAGEMENT',
    [RouteStrings.income_expense_management]: 'INCOME_EXPENSE_MANAGEMENT',
  }[routeString];
};

export const mapBreadcrumbs = (pathname = '') => {
  // custom
  const customBreadcrumbs = {};

  const breadcrumb = customBreadcrumbs[pathname];
  if (breadcrumb) {
    return breadcrumb;
  }

  // auto map breadcrumbs
  const routeArr = pathname.split('/');
  if (routeArr.length < 2 || routeArr.length > 3) {
    // not support yet;
    return null;
  }
  /**
   * /pathname
   */
  if (routeArr.length === 2) {
    return [mapRouteTranslateId(pathname)];
  }
  /**
   * /path/sub-path
   */
  return [mapRouteTranslateId(`/${routeArr[1]}`), mapRouteTranslateId(pathname)];
};

export const getAllChildrenRoute = (children, result = []) => {
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    result.push(child.href);
    if (child.children) {
      getAllChildrenRoute(child.children, result);
    }
  }
  return result;
};

/**
 * Login Page, Register Page, ...
 */
export const boxPageStyle = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 0),
    },
    position: 'relative',
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(8, 3),
    },
  },
  paperContainer: {
    padding: theme.spacing(6, 4),
    maxWidth: 400,
    minWidth: 400,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none !important',
      minWidth: 'unset !important',
      padding: theme.spacing(0, 0),
    },
  },
  languageContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    // backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },
  logoContainer: {
    height: 56,
    objectFit: 'contain',
  },
  forgetPasswordButton: {
    textAlign: 'right',
    marginTop: 5,
    marginBottom: 5,
    textDecoration: 'none',
    fontWeight: 700,
    cursor: 'pointer',
    color: colors.LINK.main,
    '&:hover': {
      color: colors.LINK.darker,
    },
  },
});
