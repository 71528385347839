import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteStrings } from 'constants/constants';
import { useTheme } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//
import ThemeSwitcher from 'components/common/ThemeSwitcher';
import Spacing from 'components/common/Spacing';
import LanguageSelector from 'components/common/LanguageSelector';
//
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import LoginIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IconButton from '@material-ui/core/IconButton';

const LandingHeader = () => {
  const { t } = useTranslation();

  const hasToken = localStorage.getItem('ACCESS_TOKEN');
  const theme = useTheme();

  return (
    <AppBar color={theme.palette.type === 'light' ? 'primary' : 'default'}>
      <Toolbar position="static">
        <Box display="flex" alignItems="center" justifyContent="space-between" width>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <img src={logo} alt="logo" style={{ height: 32, width: 38 }} />
            <Spacing horizontal={2} />
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              App Retail
            </Typography>
            <Spacing horizontal={1} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {!hasToken ? (
              <>
                <Hidden xsDown>
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={Link}
                    to={RouteStrings.login}
                  >
                    {t('LOGIN')}
                  </Button>
                </Hidden>
                <Hidden smUp>
                  <IconButton component={Link} to={RouteStrings.login} color="inherit">
                    <LoginIcon />
                  </IconButton>
                </Hidden>
              </>
            ) : (
              <>
                <Hidden xsDown>
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={Link}
                    to={RouteStrings.dashboard}
                  >
                    {t('GO_TO_DASHBOARD')}
                  </Button>
                </Hidden>
                <Hidden smUp>
                  <IconButton component={Link} to={RouteStrings.dashboard} color="inherit">
                    <DashboardIcon />
                  </IconButton>
                </Hidden>
              </>
            )}
            <Hidden xsDown>
              <Spacing horizontal={1} />
              <LanguageSelector />
              <Spacing horizontal={1} />
              <ThemeSwitcher />
            </Hidden>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
