const actionUtils = {
  registerCallback: (action, callback) => {
    const hasMeta = !!action.meta;
    let meta = {
      callback,
    };
    if (hasMeta) {
      meta = {
        ...action.meta,
        ...meta,
      };
    }
    return {
      ...action,
      meta,
    };
  },
  doCallback: (cb, params) => {
    if (cb) {
      if (params) {
        cb(params);
      } else {
        cb();
      }
    }
  },
};

export default actionUtils;
