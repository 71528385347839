import jwtDecode from 'jwt-decode';

export const decodeTokenToObject = () => {
  const token = localStorage.getItem('ACCESS_TOKEN');

  let decodedToken = null;

  try {
    decodedToken = jwtDecode(token);
    const { exp } = decodedToken;
    if (exp * 1000 < new Date().getTime()) {
      localStorage.removeItem('ACCESS_TOKEN');
      decodedToken = null;
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return decodedToken;
};
