import { accountLoginTypes } from 'constants/constants';
import { getCommonInstance } from './apiService';
import { mapMerchantIdentifyToAccountService } from '../utils/merchantUtil';

const AuthServices = {
  login: ({ email, password }) => {
    return getCommonInstance({ usePublicApi: true }).post('common-account/sign-in', {
      email,
      password,
      type: accountLoginTypes.MERCHANT_ROOT,
    });
  },
  register: data => {
    return getCommonInstance({ usePublicApi: true }).post('merchant/sign-up', data);
  },
  forgetPassword: ({ email, merchantIdentify = {} }) => {
    const merchantData = mapMerchantIdentifyToAccountService(merchantIdentify);
    return getCommonInstance({ usePublicApi: true }).post(
      'common-account/forget-password-request',
      {
        email,
        ...merchantData,
        type: accountLoginTypes.MERCHANT_ROOT,
      },
    );
  },
  renewPassword: ({ code, newPassword, newPasswordConfirmation, merchantIdentify = {} }) => {
    const merchantData = mapMerchantIdentifyToAccountService(merchantIdentify);
    return getCommonInstance({ usePublicApi: true }).post('common-account/password-renewal', {
      code,
      newPassword,
      newPasswordConfirmation,
      ...merchantData,
    });
  },
};

export default AuthServices;
