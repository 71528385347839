import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Spacing from 'components/common/Spacing';
import { authActions } from 'store/ducks/AuthDuck';
import { makeStyles, useTheme } from '@material-ui/styles';
import LanguageSelector from 'components/common/LanguageSelector';

import logo from 'assets/images/logo.png';
import { replace } from 'connected-react-router';
import { merchantRootFirstPage, RouteStrings } from 'constants/constants';
import Page from 'components/common/Page';
import { boxPageStyle } from '../../../utils/uiUtils';

const useStyles = makeStyles(boxPageStyle);

const LoginPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleLogin = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      authActions.login({
        data: {
          email: values.email,
          password: values.password,
        },
        callback: ({ hasError } = {}) => {
          setSubmitting(false);
          if (!hasError) {
            dispatch(replace(merchantRootFirstPage));
          }
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .ensure()
        .required('EMAIL.NOT_EMPTY'),
      password: Yup.string()
        .ensure()
        .required('PASSWORD.NOT_EMPTY'),
    }),
    onSubmit: handleLogin,
  });

  const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting } = formik;

  const handleForgetPassword = () => {
    dispatch(replace(RouteStrings.forget_password));
  };

  const handleRegister = () => {
    dispatch(replace(RouteStrings.register));
  };

  return (
    <Page className={classes.root} title={`${t('LOGIN')}`}>
      <div className={classes.languageContainer}>
        <LanguageSelector color={theme.palette.primary} />
      </div>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Paper className={classes.paperContainer}>
          <img className="login-logo" alt="" src={logo} />
          <Spacing vertical={2} />
          <Typography align="center" variant="h5">
            {t('LOGIN')}
          </Typography>
          <Spacing vertical={4} />
          <TextField
            name="email"
            value={values.email}
            variant="outlined"
            fullWidth
            label={t('EMAIL')}
            onChange={handleChange}
            error={touched.email && errors.email}
            helperText={errors.email && touched.email ? t(errors.email) : ''}
            required
            size="small"
          />
          <Spacing vertical={1} />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={classes.forgetPasswordButton} onClick={handleForgetPassword}>
            {t('FORGET_PASSWORD')}?
          </a>
          <Spacing vertical={1} />
          <TextField
            name="password"
            type={visiblePassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            fullWidth
            label={t('PASSWORD')}
            error={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.password && touched.password ? t(errors.password) : ''}
            InputProps={{
              endAdornment: (
                <IconButton
                  disableRipple
                  className="outline-none"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  size="small"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
            size="small"
          />
          <Spacing vertical={4} />
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            {isSubmitting && (
              <>
                <CircularProgress size={16} color="inherit" />
                <Spacing horizontal={2} />
              </>
            )}
            {t('LOGIN')}
          </Button>
          <Spacing vertical={3} />
          <Typography align="center">{t('DONT_HAVE_AN_ACCOUNT')}</Typography>
          <Spacing vertical={1} />
          <Button variant="text" color="primary" onClick={handleRegister}>
            {t('FREE_REGISTER')}
          </Button>
        </Paper>
      </form>
    </Page>
  );
};

export default LoginPage;
