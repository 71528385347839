import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';

const initialState = {
  brands: null,
  page: 0,
  size: 10,
  filter: {},
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
};

const actions = {
  setBrands: createAction('brand/set-brand'),
  setLoading: createAction('brand/set-loading'),
  setError: createAction('brand/set-error'),
  setPageSize: createAction('brand/set-page-size'),
  setPage: createAction('brand/set-page'),
  setSort: createAction('brand/set-sort'),
  fetchBrandList: createAction('brand/fetch-brand-list'),
  updateBrand: createAction('brand/update-brand'),
  createBrand: createAction('brand/create-brand'),
  deleteBrands: createAction('brand/delete-brands'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setBrands]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.brands = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state.brand,
    state => state,
  ),
};

// all actions

export {
  reducer as brandReducer,
  actions as brandActions,
  selectors as brandSelectors,
};
