import { getSaleInstance } from './apiService';

const CategoryServices = {
  get: () => {
    return getSaleInstance().get(`category`);
  },
  update: ({ id, data }) => {
    return getSaleInstance().put(`category/${id}`, data);
  },
  create: payload => {
    return getSaleInstance().post('category', payload);
  },
  delete: ids => {
    return getSaleInstance().delete(`category/bulk?ids=${ids.join(',')}`);
  },
};

export default CategoryServices;
