import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from '../../constants/constants';

const initialState = {
  products: null,
  page: 0,
  size: 10,
  filter: '',
  isLoading: false,
  hasError: false,
  total: 0,
  sort: null,
  productDetail: null,
  isLoadingDetail: false,
};

const actions = {
  setProducts: createAction('product/set-product'),
  setLoading: createAction('product/set-loading'),
  setError: createAction('product/set-error'),
  setPageSize: createAction('product/set-page-size'),
  setPage: createAction('product/set-page'),
  setSort: createAction('product/set-sort'),
  fetchProductList: createAction('product/fetch-list'),
  updateProduct: createAction('product/update'),
  createProduct: createAction('product/create'),
  deleteProducts: createAction('product/delete-list'),
  setFilter: createAction('product/set-filter'),
  fetchProductDetail: createAction('product/fetch-product-detail'),
  setProductDetail: createAction('product/set-product-detail'),
  setLoadingDetail: createAction('product/set-loading-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setProducts]: (state, action) => {
    const { data, page, size, total } = action.payload;
    state.products = data;
    state.page = page;
    state.total = total;
    state.size = size;
    state.hasError = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setProductDetail]: (state, action) => {
    state.productDetail = action.payload;
  },
  [actions.setLoadingDetail]: (state, action) => {
    state.isLoadingDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PRODUCT],
    state => state,
  ),
  getProductDetail: createSelector(
    state => state[reduxResources.PRODUCT],
    state => state.productDetail,
  ),
};

// all actions

export { reducer as productReducer, actions as productActions, selectors as productSelectors };
