import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import SubUserServices from 'services/SubUserServices';
import { subUserActions } from 'store/ducks/SubUserDuck';

export function* fetchSubUserListSaga({ payload }) {
  yield put(subUserActions.setLoading(true));
  try {
    const res = yield call(SubUserServices.get, payload);
    yield put(subUserActions.setSubUserList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(subUserActions.setLoading(false));
}

export function* fetchUserDetailSaga({ payload }) {
  try {
    const res = yield call(SubUserServices.getOne, payload);
    yield put(
      subUserActions.setUserDetail({
        ...res,
        branches: res?.branches?.map(({ branch, ...rest }) => ({
          ...rest,
          id: branch.id,
          name: branch.name,
        })),
      }),
    );
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* createSubUserSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(SubUserServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_SUB_USER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(subUserActions.fetchSubUserList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_SUB_USER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updateSubUserSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(SubUserServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_SUB_USER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(subUserActions.fetchSubUserList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_SUB_USER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* requestNewPasswordForSubUserSaga({ payload }) {
  try {
    yield call(SubUserServices.requestUpdatePassword, payload);
    yield put(
      alertActions.show({
        translateId: 'REQUESTED_NEW_PASSWORD',
        alertType: alertTypes.SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_REQUEST_NEW_PASSWORD',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deleteSubUserListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(SubUserServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_SUB_USER',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(subUserActions.fetchSubUserList({}));
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_SUB_USER',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchSubUserAsync() {
  yield takeLatest(subUserActions.fetchSubUserList, fetchSubUserListSaga);
  yield takeLatest(subUserActions.fetchUserDetail, fetchUserDetailSaga);
  yield takeLatest(subUserActions.createSubUser, createSubUserSaga);
  yield takeLatest(subUserActions.updateSubUser, updateSubUserSaga);
  yield takeLatest(subUserActions.requestNewPassword, requestNewPasswordForSubUserSaga);
  yield takeLatest(subUserActions.deleteSubUserList, deleteSubUserListSaga);
}
