import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import PartnerCategoryServices from 'services/PartnerCategoryServices';
import { partnerCategoryActions } from 'store/ducks/PartnerCategoryDuck';

export function* fetchPartnerCategoryListSaga({ payload, cb }) {
  yield put(partnerCategoryActions.setLoading(true));
  try {
    const res = yield call(PartnerCategoryServices.get, payload);
    yield put(partnerCategoryActions.setPartnerCategoryList(res));
    actionUtils.doCallback(cb);
  } catch ({ message }) {
    actionUtils.doCallback(cb, { hasError: true, message });
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(partnerCategoryActions.setLoading(false));
}

export function* createPartnerCategorySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PartnerCategoryServices.create, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'CREATED_PARTNER_CATEGORY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(partnerCategoryActions.fetchPartnerCategoryList({}));
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    const { message } = error;
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.CAN_NOT_CREATE_PARTNER_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* updatePartnerCategorySaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PartnerCategoryServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_PARTNER_CATEGORY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(partnerCategoryActions.fetchPartnerCategoryList());
  } catch (error) {
    actionUtils.doCallback(cb, { hasError: true });
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PARTNER_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export function* deletePartnerCategoryListSaga({ payload }) {
  const { cb, data } = payload;
  try {
    yield call(PartnerCategoryServices.delete, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'DELETED_PARTNER_CATEGORY',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(partnerCategoryActions.fetchPartnerCategoryList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_DELETE_PARTNER_CATEGORY',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPartnerCategoryAsync() {
  yield takeLatest(partnerCategoryActions.fetchPartnerCategoryList, fetchPartnerCategoryListSaga);
  yield takeLatest(partnerCategoryActions.createPartnerCategory, createPartnerCategorySaga);
  yield takeLatest(partnerCategoryActions.updatePartnerCategory, updatePartnerCategorySaga);
  yield takeLatest(partnerCategoryActions.deletePartnerCategoryList, deletePartnerCategoryListSaga);
}
