import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  userDetail: null,
};

const actions = {
  setSubUserList: createAction('sub-user/set-sub-user'),
  setLoading: createAction('sub-user/set-loading'),
  setError: createAction('sub-user/set-error'),
  setSort: createAction('sub-user/set-sort'),
  setPageSize: createAction('sub-user/set-page-size'),
  setPage: createAction('sub-user/set-page'),
  fetchSubUserList: createAction('sub-user/fetch-sub-user-list'),
  updateSubUser: createAction('sub-user/update-sub-user'),
  createSubUser: createAction('sub-user/create-sub-user'),
  deleteSubUserList: createAction('sub-user/delete-sub-user-list'),
  fetchUserDetail: createAction('sub-user/fetch-user-detail'),
  setUserDetail: createAction('sub-user/set-user-detail'),
  requestNewPassword: createAction('sub-user/request-new-password'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setSubUserList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setUserDetail]: (state, action) => {
    state.userDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.SUB_USER],
    state => state,
  ),
  getUserDetail: createSelector(
    state => state[reduxResources.SUB_USER],
    state => state.userDetail,
  ),
};

// all actions

export {
  reducer as subUserReducer,
  actions as subUserActions,
  selectors as subUserSelectors,
};
