import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  filter: null,
};

const actions = {
  setLoading: createAction('warehouse-detail/set-loading'),
  setError: createAction('warehouse-detail/set-error'),
  setSort: createAction('warehouse-detail/set-sort'),
  setPageSize: createAction('warehouse-detail/set-page-size'),
  setFilter: createAction('warehouse-detail/set-filter'),
  setPage: createAction('warehouse-detail/set-page'),
  fetchWarehouseDetailList: createAction('warehouse-detail/fetch-warehouse-detail-list'),
  setWarehouseDetailList: createAction('warehouse-detail/set-warehouse-list-detail'),
  resetState: createAction('warehouse-detail/reset-state'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setWarehouseDetailList]: (state, action) => {
    if (!action.payload) {
      state.data = null;
      state.total = 0;
    } else {
      const { data, total } = action.payload;
      state.data = data;
      state.total = total;
    }
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.resetState]: () => {
    return {...initialState};
  }
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.WAREHOUSE_DETAIL],
    state => state,
  ),
};

// all actions

export {
  reducer as warehouseDetailReducer,
  actions as warehouseDetailActions,
  selectors as warehouseDetailSelectors,
};
