import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { reduxResources, themeTypes } from 'constants/constants';
import { routerMiddleware } from 'connected-react-router';
import * as colors from '@material-ui/core/colors';
import { history } from 'router/router';
import { decodeTokenToObject } from 'utils/tokenUtils';
import createReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

let themeType = localStorage.getItem('themeType') || themeTypes.LIGHT;
if (!Object.values(themeTypes).includes(themeType)) {
  themeType = themeTypes.LIGHT;
}

let primaryColor = localStorage.getItem('primaryColor');
let secondaryColor = localStorage.getItem('secondaryColor');

if (primaryColor) {
  try {
    primaryColor = JSON.parse(primaryColor);
  } catch (error) {
    primaryColor = colors.blue;
  }
} else {
  primaryColor = colors.blue;
}

if (secondaryColor) {
  try {
    secondaryColor = JSON.parse(secondaryColor);
  } catch (error) {
    secondaryColor = null;
  }
}

const store = configureStore({
  reducer: createReducer(history),
  middleware: [routerMiddleware(history), sagaMiddleware],
  devTools: {
    shouldHotReload: false,
  },
  preloadedState: {
    // [reduxResources.LANGUAGE]: language,
    [reduxResources.THEME]: {
      type: themeType,
      primaryColor,
      secondaryColor: secondaryColor ?? null,
    },
    [reduxResources.AUTH]: {
      decodedToken: decodeTokenToObject(),
    },
  },
});

sagaMiddleware.run(rootSaga);

export default store;
