import { put, call, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'store/ducks/AlertDuck';
import actionUtils from 'utils/actionUtils';
import { alertTypes } from 'constants/constants';
import PaymentServices from 'services/PaymentServices';
import { paymentActions } from 'store/ducks/PaymentDuck';

export function* fetchPaymentListSaga({ payload }) {
  yield put(paymentActions.setLoading(true));
  try {
    const res = yield call(PaymentServices.get, payload);
    yield put(paymentActions.setPaymentList(res));
  } catch ({ message }) {
    yield put(
      alertActions.show({
        translateId: message ?? 'ERRORS.DEFAULT_ERROR',
        alertType: alertTypes.ERROR,
      }),
    );
  }
  yield put(paymentActions.setLoading(false));
}

export function* updatePaymentSaga({ payload }) {
  const { cb, ...data } = payload;
  try {
    yield call(PaymentServices.update, data);
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: 'UPDATED_PAYMENT',
        alertType: alertTypes.SUCCESS,
      }),
    );
    yield put(paymentActions.fetchPaymentList());
  } catch (error) {
    actionUtils.doCallback(cb);
    yield put(
      alertActions.show({
        translateId: error.message ?? 'ERRORS.CAN_NOT_UPDATE_PAYMENT',
        alertType: alertTypes.ERROR,
      }),
    );
  }
}

export default function* watchPaymentAsync() {
  yield takeLatest(paymentActions.fetchPaymentList, fetchPaymentListSaga);
  yield takeLatest(paymentActions.updatePayment, updatePaymentSaga);
}
