import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { reduxResources } from 'constants/constants';

const initialState = {
  data: null,
  isLoading: true,
  hasError: false,
  total: 0,
  promotionDetail: null,
  filter: null,
};

const actions = {
  setPromotionList: createAction('promotion/set-promotion'),
  setLoading: createAction('promotion/set-loading'),
  setError: createAction('promotion/set-error'),
  setSort: createAction('promotion/set-sort'),
  setPageSize: createAction('promotion/set-page-size'),
  setFilter: createAction('promotion/set-filter'),
  setPage: createAction('promotion/set-page'),
  fetchPromotionList: createAction('promotion/fetch-promotion-list'),
  updatePromotion: createAction('promotion/update-promotion'),
  createPromotion: createAction('promotion/create-promotion'),
  deletePromotionList: createAction('promotion/delete-promotion-list'),
  fetchPromotionDetail: createAction('promotion/fetch-promotion-detail'),
  setPromotionDetail: createAction('promotion/set-promotion-detail'),
};

// reducer

const reducer = createReducer(initialState, {
  [actions.setPromotionList]: (state, action) => {
    const { data, total } = action.payload;
    state.data = data;
    state.total = total;
    state.hasError = false;
    state.isLoading = false;
  },
  [actions.setLoading]: (state, action) => {
    state.isLoading = action.payload;
  },
  [actions.setError]: (state, action) => {
    state.hasError = action.payload;
    state.isLoading = false;
  },
  [actions.setPage]: (state, action) => {
    state.page = action.payload;
  },
  [actions.setPageSize]: (state, action) => {
    state.size = action.payload;
  },
  [actions.setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [actions.setFilter]: (state, action) => {
    state.filter = action.payload;
    if (state.page !== 0) {
      state.page = 0;
    }
  },
  [actions.setPromotionDetail]: (state, action) => {
    state.promotionDetail = action.payload;
  },
});

const selectors = {
  getState: createSelector(
    state => state[reduxResources.PROMOTION],
    state => state,
  ),
  getPromotionDetail: createSelector(
    state => state[reduxResources.PROMOTION],
    state => state.promotionDetail,
  ),
};

// all actions

export {
  reducer as promotionReducer,
  actions as promotionActions,
  selectors as promotionSelectors,
};
