import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Colors from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import LightIcon from '@material-ui/icons/Brightness4Rounded';
import DarkIcon from '@material-ui/icons/Brightness7Rounded';
import CheckIcon from '@material-ui/icons/CheckRounded';

import { themeSelectors, themeActions } from 'store/ducks/ThemeDuck';
import { themeTypes } from 'constants/constants';

export const colorOptions = [
  Colors.red,
  Colors.pink,
  Colors.purple,
  Colors.deepPurple,
  Colors.indigo,
  Colors.blue,
  Colors.lightBlue,
  Colors.cyan,
  Colors.deepOrange,
  Colors.orange,
  Colors.amber,
  Colors.yellow,
  Colors.lime,
  Colors.lightGreen,
  Colors.green,
  Colors.teal,
];

const ThemeSwitcher = () => {
  const dispatch = useDispatch();
  const { type: themeType, primaryColor } = useSelector(themeSelectors.getTheme);

  const handleSwitchTheme = () => {
    dispatch(themeActions.toggleTheme());
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleChoosePrimaryColor = color => e => {
    handleClose(e);
    dispatch(themeActions.setPrimaryColor(color));
  };

  return (
    <>
      <IconButton onClick={handleSwitchTheme} color="inherit">
        {themeType === themeTypes.LIGHT ? <LightIcon /> : <DarkIcon />}
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="theme-color-list"
                  onKeyDown={handleListKeyDown}
                  style={{
                    maxWidth: 126,
                  }}
                >
                  <Grid container>
                    {colorOptions.map((color, index) => (
                      <Grid item sm={4} key={index}>
                        <MenuItem onClick={handleChoosePrimaryColor(color)} style={{ padding: 5 }}>
                          <div
                            style={{
                              height: 32,
                              width: 32,
                              backgroundColor: color[500],
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '50%',
                            }}
                          >
                            {primaryColor && primaryColor[500] === color[500] && <CheckIcon />}
                          </div>
                        </MenuItem>
                      </Grid>
                    ))}
                  </Grid>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ThemeSwitcher;
