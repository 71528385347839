import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Spacing from 'components/common/Spacing';
import { authActions } from 'store/ducks/AuthDuck';
import { makeStyles, useTheme } from '@material-ui/styles';
import LanguageSelector from 'components/common/LanguageSelector';

import logo from 'assets/images/logo.png';
import { push, replace } from 'connected-react-router';
import { accountLoginTypes, alertTypes, RouteStrings } from 'constants/constants';
import Page from 'components/common/Page';
import InputAdornment from '@material-ui/core/InputAdornment';
import defaultUrl from '../../../constants/defaultUrl';
import { alertActions } from '../../../store/ducks/AlertDuck';
import { boxPageStyle } from '../../../utils/uiUtils';
import { isValidSubDomain } from '../../../utils/domainUtils';

const useStyles = makeStyles(boxPageStyle);

const RegisterPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleRegister = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      authActions.register({
        data: {
          ...values,
          account: {
            email: values.email,
            password: values.password,
            type: accountLoginTypes.MERCHANT_ROOT,
          },
        },
        callback: ({ hasError } = {}) => {
          setSubmitting(false);
          if (!hasError) {
            dispatch(
              alertActions.show({
                translateId: 'REGISTER_SUCCESS',
                alertType: alertTypes.SUCCESS,
              }),
            );
            dispatch(push(RouteStrings.login));
          }
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      subDomain: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .ensure()
        .required('THIS_FIELD_IS_REQUIRED'),
      subDomain: Yup.string()
        .ensure()
        .required('THIS_FIELD_IS_REQUIRED')
        .test('valid subDomain', 'INVALID_SUB_DOMAIN', value => {
          return isValidSubDomain(value);
        }),
      email: Yup.string()
        .ensure()
        .required('EMAIL.NOT_EMPTY'),
      password: Yup.string()
        .ensure()
        .required('PASSWORD.NOT_EMPTY'),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t("PASSWORD_DON'T_MATCH"))
        .required(t('THIS_FIELD_IS_REQUIRED')),
    }),
    onSubmit: handleRegister,
  });

  const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting } = formik;

  const textFieldCommonProps = (field, translationName) => {
    return {
      name: field,
      value: values[field],
      variant: 'outlined',
      fullWidth: true,
      label: t(translationName),
      onChange: handleChange,
      error: touched[field] && errors[field],
      helperText: errors[field] && touched[field] ? t(errors[field]) : '',
      size: 'small',
      onBlur: handleBlur,
    };
  };

  const handleLogin = () => {
    dispatch(replace(RouteStrings.login));
  };

  return (
    <Page className={classes.root} title={`${t('REGISTER')}`}>
      <div className={classes.languageContainer}>
        <LanguageSelector color={theme.palette.primary} />
      </div>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Paper className={classes.paperContainer}>
          <img className="login-logo" alt="" src={logo} />
          <Spacing vertical={2} />
          <Typography align="center" variant="h5">
            {t('REGISTER')}
          </Typography>
          <Spacing vertical={3} />
          <TextField {...textFieldCommonProps('name', 'MERCHANT_NAME')} required />
          <Spacing vertical={3} />
          <TextField
            {...textFieldCommonProps('subDomain', 'MERCHANT_SUB_DOMAIN')}
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">{`.${defaultUrl}`}</InputAdornment>,
            }}
          />
          <Spacing vertical={3} />
          <TextField {...textFieldCommonProps('email', 'EMAIL')} required />
          <Spacing vertical={3} />
          <TextField
            {...textFieldCommonProps('password', 'PASSWORD')}
            type={visiblePassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  tabIndex={-1}
                  disableRipple
                  className="outline-none"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  size="small"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
          <Spacing vertical={3} />
          <TextField
            {...textFieldCommonProps('passwordConfirmation', 'PASSWORD_CONFIRMATION')}
            type={visiblePassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  tabIndex={-1}
                  disableRipple
                  className="outline-none"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  size="small"
                >
                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
          <Spacing vertical={3} />
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            {isSubmitting && (
              <>
                <CircularProgress size={16} color="inherit" />
                <Spacing horizontal={2} />
              </>
            )}
            {t('REGISTER')}
          </Button>
          <Spacing vertical={3} />
          <Typography align="center">{t('HAVE_AN_ACCOUNT')}</Typography>
          <Spacing vertical={1} />
          <Button variant="text" color="primary" onClick={handleLogin}>
            {t('LOGIN')}
          </Button>
        </Paper>
      </form>
    </Page>
  );
};

export default RegisterPage;
